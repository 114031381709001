import Guid from "common/values/guid/guid";
import moment from "moment";
import Date from "common/values/date/date";
import DetailedWorkTeamAPIResponse from "work/values/team/api/response-contracts/detailed-work-team-api-response";
import EntityRepresentativeAPIResponse from "work/entities/entity-representative/api/response-contracts/entity-representative-api-response";
import SimpleWorkTeamAPIResponse from "work/values/team/api/response-contracts/simple-work-team-api-response";
import WorkCreatorInfoAPIResponse from "work/entities/proposal/api/response-contracts/work-creator-info-api-response";
import WorkFeeScheduleCategoryAPIResponse from "work/values/fee-schedule-category/api/response-contracts/work-fee-schedule-category-api-response";
import WorkVendorRepresentativeAPIResponse from "work/entities/entity-vendor-representative/api/response-contracts/work-vendor-representative-api-response";
import EntityClientRepresentative from "work/entities/entity-client-representative/entity-client-representative";
import Proposal, { CompleteProposalSpec, IProposalAPIService, ProposalMetaInfo } from "work/entities/proposal/proposal";
import WorkAgreement from "work/entities/work-agreement/work-agreement";
import ProjectDescription from "work/values/project-description/project-description";
import ProjectName from "work/values/project-name/project-name";
import { ProposalAction, ProposalStatus } from "work/values/constants";
import Team from "work/values/team/team";
import Name from "common/values/name/name";
import ProposalReviewerAPIResponse from "work/entities/proposal/api/response-contracts/proposal-reviewer-api-response";
import ReplaceableDocumentAPIResponse from "work/entities/proposal/api/response-contracts/replaceable-document-api-response";
import { WorkDocumentType } from "work/values/work-document/work-document";
import Percent from "common/values/percent/percent";
import AHBoolean from "common/values/boolean/boolean";

export default class WorkProposalAPIResponse {
  id?: string;
  RFPId?: string;
  creator?: WorkCreatorInfoAPIResponse;
  createdDate?: string;
  lastUpdated?: string;
  name?: string;
  description?: string;

  client?: EntityRepresentativeAPIResponse;
  vendors: WorkVendorRepresentativeAPIResponse[] = [];
  team?: SimpleWorkTeamAPIResponse | DetailedWorkTeamAPIResponse;
  feeSchedule?: WorkFeeScheduleCategoryAPIResponse[];
  startDate?: string;
  endDate?: string;
  discount?: number;

  clientTeamTemplateIds?: string[];
  vendorTeamTemplateIds?: string[];
  clientFeeScheduleTemplateIds?: string[];
  vendorFeeScheduleTemplateIds?: string[];

  clientPolicyDocuments?: ReplaceableDocumentAPIResponse[];
  vendorPolicyDocuments?: ReplaceableDocumentAPIResponse[];
  conflictsDocuments?: ReplaceableDocumentAPIResponse[];  

  conflictsCheckWaived?: boolean;
  teamRestricted?: boolean;

  hireDate?: string;
  negotiable: boolean = true;
  responseDueBy?: string;

  supersedes?: WorkProposalAPIResponse;
  supersededById?: string;

  status?:
    | "AwaitingSubmission"
    | "AwaitingApprovalByClient"
    | "AwaitingApprovalByTeamLeader"
    | "AwaitingApprovalByVendors"
    | "AwaitingApprovalByTeam"
    | "AwaitingHire"
    | "RevisionRequested"
    | "Archived";
  availableActions?: { [key: string]: string[] };
  clientReviewers?: ProposalReviewerAPIResponse[];
  vendorReviewers?: ProposalReviewerAPIResponse[];
  redlining?: string;


  deserialize(apiService: IProposalAPIService, team?: Team): Proposal {
    const spec: CompleteProposalSpec = this.constructProposalSpec(team);
    const metaInfo: ProposalMetaInfo = this.constructProposalMetaInfo(apiService);
    return new Proposal(apiService, spec, metaInfo);
  }

  private constructProposalMetaInfo(apiService: IProposalAPIService): ProposalMetaInfo {
    if (!this.id)
      throw new InvalidProposalAPIResponse("Proposal id not returned by api.");
    if (!this.creator?.userId)
      throw new InvalidProposalAPIResponse(
        "Proposal creator not returned by api."
      );
    if (!this.creator?.entityId)
      throw new InvalidProposalAPIResponse(
        "Proposal creator entity id not returned by api."
      );
    if (!this.client?.userId)
      throw new InvalidProposalAPIResponse("Client not returned by api.");
    if (!this.client?.entityId)
      throw new InvalidProposalAPIResponse(
        "Client entity id not returned by api."
      );
    if (!this.availableActions)
      throw new InvalidProposalAPIResponse(
        "Available actions not returned by api."
      );

    const metaInfo: ProposalMetaInfo = {
      RFPId: this.RFPId ? new Guid(this.RFPId) : undefined,
      id: new Guid(this.id),
      creator: new EntityClientRepresentative(
        new Guid(this.creator.userId),
        new Guid(this.creator.entityId),
        new Name(this.creator.firstName, this.creator.lastName)
      ),
      creatorInfo: Object.assign(new WorkCreatorInfoAPIResponse(), this.creator).deserialize(),
      status: ProposalStatus[this.status as keyof typeof ProposalStatus],
      createdDate: new Date(moment(this.createdDate)),
      lastUpdated: new Date(moment(this.lastUpdated)),
      availableActions: {
        [ProposalAction.Submit]: [],
        [ProposalAction.Approve]: [],
        [ProposalAction.Reject]: [],
        [ProposalAction.Revise]: [],
        [ProposalAction.Hire]: [],
        [ProposalAction.Delete]: [],
        [ProposalAction.Cancel]: [],
        [ProposalAction.Edit]: [],
        [ProposalAction.Manage]: [],
        [ProposalAction.Review]: []
      },
      supersededById: this.supersededById ? new Guid(this.supersededById) : undefined
    };

    for (const [action, userIds] of Object.entries(this.availableActions)) {
      metaInfo.availableActions[
        ProposalAction[action as keyof typeof ProposalAction]
      ] = userIds.map((userId: string) => new Guid(userId));
    }

    return metaInfo
  }

  protected constructProposalSpec(team?: Team): CompleteProposalSpec {
    if (!this.client?.userId)
      throw new InvalidProposalAPIResponse("Client not returned by api.");
    if (!this.client?.entityId)
      throw new InvalidProposalAPIResponse(
        "Client entity id not returned by api."
      );
    let specTeam = team;
    if(!specTeam && this.team){
      if(this.team?.members && this.team.members.length > 0 && (this.team as DetailedWorkTeamAPIResponse).members[0].userId){
        specTeam = Object.assign(new DetailedWorkTeamAPIResponse(), this.team).deserialize();
      }
      else {
        specTeam = Object.assign(new SimpleWorkTeamAPIResponse(), this.team).deserialize();
      }
    }

    const workAgreement = new WorkAgreement(
      new ProjectName(this.name ?? ""), 
      new EntityClientRepresentative(
        new Guid(this.client.userId), 
        new Guid(this.client.entityId),
        new Name(this.client.firstName, this.client.lastName)
      )
    );
    this.setWorkAgreementValues(workAgreement, specTeam);

    return {
      client: new EntityClientRepresentative(
        new Guid(this.client.userId), 
        new Guid(this.client.entityId),
        new Name(this.client.firstName, this.client.lastName)
      ),
      name: new ProjectName(this.name ?? ""),
      description: new ProjectDescription(this.description ?? ""),
      negotiable: this.negotiable,
      responseDueBy: moment(this.responseDueBy).isValid() ? new Date(moment(this.responseDueBy)) : undefined,
      startDate: moment(this.startDate).isValid() ? new Date(moment(this.startDate)) : undefined,
      endDate: moment(this.endDate).isValid() ? new Date(moment(this.endDate)) : undefined,
      workAgreement: workAgreement,
      clientReviewers: this.clientReviewers?.map(reviewer => Object.assign(new ProposalReviewerAPIResponse(), reviewer).deserialize()) ?? [],
      vendorReviewers: this.vendorReviewers?.map(reviewer => Object.assign(new ProposalReviewerAPIResponse(), reviewer).deserialize()) ?? [],
    };
  }

  private setWorkAgreementValues(workAgreement: WorkAgreement, specTeam: Team | undefined) {
    workAgreement.conflictsCheckWaived = this.conflictsCheckWaived != undefined ? new AHBoolean(this.conflictsCheckWaived) : new AHBoolean(false);
    workAgreement.teamRestricted = this.teamRestricted != undefined ? new AHBoolean(this.teamRestricted) : new AHBoolean(false);
    workAgreement.clientTeamTemplateIds = this.clientTeamTemplateIds?.map(id => new Guid(id)) ?? [];
    workAgreement.vendorTeamTemplateIds = this.vendorTeamTemplateIds?.map(id => new Guid(id)) ?? [];
    workAgreement.clientFeeScheduleTemplateIds = this.clientFeeScheduleTemplateIds?.map(id => new Guid(id)) ?? [];
    workAgreement.vendorFeeScheduleTemplateIds = this.vendorFeeScheduleTemplateIds?.map(id => new Guid(id)) ?? [];
    workAgreement.clientPolicyDocuments = this.clientPolicyDocuments?.filter(document => document.documentInfo).map(document => Object.assign(new ReplaceableDocumentAPIResponse(), document).deserialize(WorkDocumentType.ClientPolicy)) ?? [];
    workAgreement.vendorPolicyDocuments = this.vendorPolicyDocuments?.filter(document => document.documentInfo).map(document => Object.assign(new ReplaceableDocumentAPIResponse(), document).deserialize(WorkDocumentType.VendorPolicy)) ?? [];
    workAgreement.conflictsDocuments = this.conflictsDocuments?.filter(document => document.documentInfo).map(document => Object.assign(new ReplaceableDocumentAPIResponse(), document).deserialize(WorkDocumentType.Conflicts)) ?? [];
    workAgreement.discount = new Percent(this.discount ?? 0);
    workAgreement.team = specTeam;
    workAgreement.feeSchedule = this.feeSchedule?.map(category => Object.assign(new WorkFeeScheduleCategoryAPIResponse(), category).deserialize()) ?? [];
    workAgreement.startDate = moment(this.startDate).isValid() ? new Date(moment(this.startDate)) : undefined;
    workAgreement.endDate = moment(this.endDate).isValid() ? new Date(moment(this.endDate)) : undefined;
    workAgreement.RFPId = this.RFPId ? new Guid(this.RFPId) : undefined;
    workAgreement.vendors = this.vendors?.map(vendor => Object.assign(new WorkVendorRepresentativeAPIResponse(), vendor).deserialize()) ?? [];
  }
}

export class InvalidProposalAPIResponse extends Error { }
