import AddCommentIcon from '@mui/icons-material/AddComment';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PublicIcon from '@mui/icons-material/Public';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import UndoIcon from '@mui/icons-material/Undo';
import {
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grow,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Switch,
  TextField,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import { green, lightBlue } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import LoadingButton from 'common/components/loading-button';
import * as Constants from 'common/helpers/constants';
import Guid from 'common/values/guid/guid';
import parse from 'html-react-parser';
import _ from 'lodash';
import Individual from 'marketplace/entities/individual/individual';
import IndividualProfile from 'marketplace/values/individual-profile/individual-profile';
import IndividualAvatar from 'marketplace/view/individual-avatar';
import Message from 'messaging/entities/message/message';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useSession } from 'users/session/session-context';
import Proposal from 'work/entities/proposal/proposal';
import { ProposalFieldName } from 'work/values/constants';
import { MarkEmailRead, MarkEmailUnread, Visibility, VisibilityOff } from '@mui/icons-material';

const CommentsContainer = styled('div')(({ theme }) => ({
  display: 'contents',
  height: '100%'
}));
const CommentsList = styled(List)(({ theme }) => ({
  overflowY: 'auto'
}));
const PostCommentInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 0,
    '& fieldset': {
      borderColor: theme.palette.common.black
    },
    '&:hover fieldset': {
      borderColor: theme.palette.common.black,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.common.black,
    },
  }
}));
const EditCommentInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 0,
    '& fieldset': {
      borderColor: theme.palette.common.black
    },
    '&:hover fieldset': {
      borderColor: theme.palette.common.black,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.common.black,
    },
  }
}));
const AudienceLabel = styled("span")<{ isexternal: boolean }>(({ theme, isexternal }) => ({
  color: isexternal ? theme.palette.error.main : theme.palette.common.black,
  fontWeight: isexternal ? theme.typography.fontWeightBold : undefined
}));

const CommentListItem = styled(ListItem, { shouldForwardProp: (prop) => prop !== 'pending' })<{ pending: boolean }>(({ theme, pending }) => ({
  opacity: pending ? 0.66 : 1,
}));
const NameLink = styled(Link)<{ disabled: boolean }>(({ theme, disabled }) => ({
  display: 'inline-block',
  cursor: disabled ? 'default' : 'pointer',
  textDecoration: disabled ? 'none' : 'underline'
}));
const CommentDate = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: 'block'
}));
const ExternalChip = styled(Chip)(({ theme }) => ({
  border: 0,
  marginLeft: theme.spacing(0.5)
}));
const PendingChip = styled(Chip)(({ theme }) => ({
  border: 0
}));
const BackToTopChipContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  height: 0,
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
  zIndex: 1
}));
const BackToTopChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  top: '20px',
  boxShadow: '0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px 0 rgb(0 0 0 / 20%)',
  color: 'white',
  position: 'absolute',
  '&:hover': {
    backgroundColor: theme.palette.primary.main
  }
}));
const CommentContent = styled("span")(({ theme }) => ({
  width: '100%',
  '& span.action': {
    fontWeight: 500,
    '&.accepted': {
      color: theme.palette.success.main
    },
    '&.rejected': {
      color: theme.palette.error.main
    }
  },
  '& > span.comment-redline': {
    width: '100%',
    '& > span.change-group': {
      whiteSpace: 'nowrap',
      '& .added': {
        '&.resolved': {
          color: theme.palette.text.primary
        },
        backgroundColor: 'unset',
        color: theme.palette.text.primary
      }
    },
    '& span.added': {
      backgroundColor: green[300],
      color: theme.palette.text.primary,
      '&.resolved': {
        backgroundColor: 'unset',
        color: lightBlue[700]
      }
    },
    '& span.removed': {
      textDecoration: 'line-through',
      textDecorationColor: theme.palette.error.main,
      '&.resolved': {
        textDecorationColor: lightBlue[700]
      }
    },
    '& span.unchanged': {
      color: theme.palette.text.primary
    }
  }
}));

type CommentsProps = {
  className?: string,
  proposal?: Proposal,
  field?: ProposalFieldName,
  fieldId?: Guid,
  activeComments: Message[],
  pendingComments: Message[],
  commenters: Individual[],
  isSaving: boolean,
  onCommentPosted: (
    content: string,
    isExternal: boolean,
    field?: ProposalFieldName,
    fieldId?: Guid
  ) => void,
  onCommentEdited: (
    messageId: Guid,
    content: string,
    isExternal: boolean,
    publishedOn?: moment.Moment
  ) => void,
  onCommentReadToggled: (messageId: Guid) => void,
  onCommentDeleted: (messageId: Guid) => void,
  onViewProfile: (individualId?: Guid) => void
}

export default function Comments(props: Readonly<CommentsProps>) {
  const {
    className,
    proposal,
    field,
    fieldId,
    activeComments,
    pendingComments,
    commenters,
    isSaving,
    onCommentPosted,
    onCommentEdited,
    onCommentReadToggled,
    onCommentDeleted,
    onViewProfile
  } = props;

  const topRef = useRef<HTMLDivElement>(null);
  const commentsListRef = useRef<HTMLUListElement>(null);

  const [commentText, setCommentText] = React.useState<string>('');
  const [editedCommentText, setEditedCommentText] = React.useState<string>('');
  const [isExternal, setIsExternal] = React.useState<boolean>(false);
  const [backToTopVisible, setBackToTopVisible] = React.useState(false);
  const [messageBeingEdited, setMessageBeingEdited] = React.useState<Message | null>(null);

  const session = useSession();

  const handleWindowScrolled = (_event: any) => {
    if (!commentsListRef?.current) return;
    const scrollPercent = Math.round(
      commentsListRef?.current.scrollTop /
      (commentsListRef?.current.scrollHeight - commentsListRef?.current.clientHeight) * 100.0
    );
    setBackToTopVisible(scrollPercent > 5);
  }

  const throttledScrollEventHandler = _.throttle(
    handleWindowScrolled,
    Constants.backToTopThrottleDelay
  );

  useEffect(() => {
    scrollToBottom();
    window.setTimeout(() => {
      commentsListRef?.current?.addEventListener('scroll', throttledScrollEventHandler);
    }, 1000);
  }, []);

  const scrollToBottom = () => {
    window.setTimeout(() => {
      topRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: "nearest",
        inline: "nearest"
      });
    }, 500);
  }

  function renderAudienceSwitch() {
    const isClient = proposal?.client?.userId.isEqualTo(session.user?.id);
    const isTeamLeader = proposal?.team?.leader.userId.isEqualTo(session.user?.id);
    if (proposal && !isClient && !isTeamLeader) return null;

    return (
      <FormControl component="span" variant="standard">
        <FormControlLabel
          control={
            <Switch
              checked={isExternal}
              color={isExternal ? "error" : undefined}
              onChange={() => setIsExternal(prevValue => !prevValue)}
            />
          }
          label={
            <AudienceLabel
              isexternal={isExternal}
            >
              External Comment
            </AudienceLabel>
          }
        />
        {isExternal && (
          <FormHelperText>
            <Typography color={isExternal ? "error" : undefined}>
              <strong>Note:</strong> This comment will be visible to everyone associated with this proposal, including:<br />
              <i>the proposal creator, team, and both client and vendor reviewers</i>.
            </Typography>
          </FormHelperText>
        )}
      </FormControl>
    );
  }

  function handleCommentPosted() {
    onCommentPosted(commentText, isExternal, field, fieldId);
    setCommentText('');
    setIsExternal(false);
    scrollToBottom();
  }

  function handleCommentEdited() {
    if (!messageBeingEdited?.id) return;
    onCommentEdited(
      messageBeingEdited.id,
      editedCommentText,
      isExternal,
      messageBeingEdited.publishedOn);
    setEditedCommentText('');
    setMessageBeingEdited(null);
    setIsExternal(false);
  }

  function sortCommentsByDate(comments: Message[]): Message[] {
    let sortedComments = [..._.uniqBy(comments, (comment) => comment.id?.value)];
    return sortedComments.sort((a, b) => {
      if (a.publishedOn && b.publishedOn) {
        return b.publishedOn.diff(a.publishedOn);
      }
      return 0;
    });
  }

  function getCommentContent(comment: Message) {
    if (comment.isDeleted && !comment.markedForDeletion) {
      return (<i>This comment was deleted</i>)
    }
    return (
      <CommentContent>
        {parse(comment.content)}
      </CommentContent>
    );
  }

  function getPendingStatus(comment: Message): string {
    if (comment.isDeleted) return 'Pending Deletion';
    if (comment.markedForEdit) return 'Pending Edit';
    return 'Pending';
  }

  const isBeingEdited = messageBeingEdited !== null;

  return (
    <CommentsContainer className={className}>
      {(!isBeingEdited && !proposal?.isArchived) &&
        <PostCommentInput
          placeholder='Type your comment here...'
          multiline
          rows={4}
          variant='outlined'
          fullWidth
          value={commentText}
          helperText={renderAudienceSwitch()}
          slotProps={{
            input: {
              endAdornment: (
                <LoadingButton
                  color='primary'
                  endIcon={<AddCommentIcon />}
                  disabled={commentText.trim() === ''}
                  loading={isSaving}
                  onClick={handleCommentPosted}>
                  Post
                </LoadingButton>
              )
            }
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && commentText.trim() !== '') {
              event.preventDefault();
              handleCommentPosted();
            }
          }}
          onChange={(event) => setCommentText(event.target.value)}
        />}
      {activeComments.length > 0 && (
        <BackToTopChipContainer>
          <Grow in={backToTopVisible}>
            <BackToTopChip
              label="Jump to latest"
              icon={<ArrowUpwardIcon style={{ color: 'white' }} />}
              onClick={() => topRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: "nearest",
                inline: "nearest"
              })}
            />
          </Grow>
        </BackToTopChipContainer>
      )}
      <CommentsList ref={commentsListRef}>
        <div ref={topRef} />
        {activeComments.length === 0 && (
          <ListItem key={Guid.generate().value} disableGutters>
            <ListItemText>No comments yet</ListItemText>
          </ListItem>
        )}
        {sortCommentsByDate(activeComments).map((comment: Message) => {
          let commenter: Individual | undefined = undefined;
          if (
            comment.senderId?.value === session.user?.id?.value &&
            session.user?.individualId &&
            session.user?.id &&
            session.user?.name.firstName &&
            session.user?.name.lastName
          ) {
            commenter = new Individual(
              session.user.individualId,
              session.user.id,
              new IndividualProfile(
                session.user.id,
                session.user.name.firstName,
                session.user.name.lastName
              )
            );
          } else {
            commenter = commenters.find((individual) =>
              individual.profile?.userId?.isEqualTo(comment.senderId)
            );
          }

          const pending = pendingComments.some(pending => pending.id?.isEqualTo(comment.id));
          const pendingDeletion = comment?.markedForDeletion ?? false;

          return (
            <CommentListItem
              key={comment.id?.value ?? Guid.generate().value}
              pending={pending}
              disableGutters
              alignItems="flex-start"
            >
              <ListItemAvatar>
                <IndividualAvatar
                  avatarId={commenter?.profile?.avatarId}
                  individualId={commenter?.id}
                  session={session}
                />
              </ListItemAvatar>
              {messageBeingEdited && messageBeingEdited.id?.isEqualTo(comment.id) &&
                <EditCommentInput
                  multiline
                  rows={4}
                  variant='outlined'
                  fullWidth
                  value={editedCommentText}
                  helperText={renderAudienceSwitch()}
                  InputProps={{
                    endAdornment: (
                      <>
                        <Tooltip title='Save comment'>
                          <IconButton
                            color='primary'
                            size='small'
                            disabled={editedCommentText.trim() === ''}
                            onClick={handleCommentEdited}>
                            <SaveIcon fontSize='inherit' />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Cancel editing'>
                          <IconButton
                            color='primary'
                            size='small'
                            onClick={() => {
                              setMessageBeingEdited(null);
                              setEditedCommentText('');
                            }}>
                            <CancelIcon fontSize='inherit' />
                          </IconButton>
                        </Tooltip>
                      </>
                    )
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' && editedCommentText.trim() !== '') {
                      event.preventDefault();
                      handleCommentEdited();
                    } else if (event.key === 'Escape') {
                      setMessageBeingEdited(null);
                      setEditedCommentText('');
                    }
                  }}
                  onChange={(event) => setEditedCommentText(event.target.value)}
                />
              }{(!messageBeingEdited || !messageBeingEdited.id?.isEqualTo(comment.id)) &&
                <ListItemText
                  primary={
                    <>
                      <NameLink
                        disabled={!commenter?.isVisible}
                        onClick={(event) => {
                          event.preventDefault();
                          if (!commenter?.isVisible) return;
                          onViewProfile(commenter?.id);
                        }}>
                        {commenter?.getFullName()}
                      </NameLink>
                      {comment.forum.topic?.context?.includes('allReviewers') && (
                        <ExternalChip
                          label="External"
                          size='small'
                          icon={<PublicIcon />}
                          variant='outlined'
                        />
                      )}
                      {pending && (
                        <PendingChip
                          label={getPendingStatus(comment)}

                          size='small'
                          color='warning'
                          variant='outlined'
                        />
                      )}
                      <CommentDate variant='caption'>
                        {comment.publishedOn?.format('MMM D, YYYY h:mm A') ?? moment().format('MMM D, YYYY h:mm A')}
                      </CommentDate>
                    </>
                  }
                  secondary={getCommentContent(comment)}
                  primaryTypographyProps={{ fontWeight: 500 }}
                  secondaryTypographyProps={{ color: 'textPrimary' }}
                />
              }
              {comment.senderId && !comment.senderId.isEqualTo(session.user?.id) &&
              <Tooltip title={(!comment.markedForReadReceipt && !comment.isReadByUser(session.user?.id)) || comment.markedForUnread ? 'Mark Read' : 'Mark Unread'}>
                <IconButton
                  color='primary'
                  size='small'
                  onClick={() => {
                    if (!comment.id) return;
                    onCommentReadToggled(comment.id);
                  }}
                >
                  {(!comment.markedForReadReceipt && !comment.isReadByUser(session.user?.id)) || comment.markedForUnread ? <MarkEmailUnread fontSize='inherit' /> : <MarkEmailRead fontSize='inherit' />}
                </IconButton>
              </Tooltip>
              }              
              {(!comment.isDeleted || comment.markedForDeletion) &&
                comment.senderId?.isEqualTo(session.user?.id) &&
                <>
                  {!isBeingEdited &&
                    !pendingDeletion &&
                    <>
                      <Tooltip title='Edit comment'>
                        <IconButton
                          color='primary'
                          size='small'
                          onClick={() => {
                            setMessageBeingEdited(comment);
                            setEditedCommentText(comment.content);
                            setIsExternal(comment.forum.topic?.context?.includes('allReviewers') ?? false);
                          }}
                        >
                          <EditIcon fontSize='inherit' />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Delete comment'>
                        <IconButton
                          color='primary'
                          size='small'
                          onClick={() => {
                            if (!comment.id) return;
                            onCommentDeleted(comment.id);
                          }}
                        >
                          <DeleteIcon color="error" fontSize='inherit' />
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                  {pendingDeletion &&
                    <Tooltip title='Undo deletion'>
                      <IconButton
                        color='primary'
                        size='small'
                        onClick={() => {
                          if (!comment.id) return;
                          onCommentDeleted(comment.id);
                        }}
                      >
                        <UndoIcon fontSize='inherit' />
                      </IconButton>
                    </Tooltip>
                  }
                </>
              }
            </CommentListItem>
          );
        })}
      </CommentsList>
    </CommentsContainer >
  );
}


