import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import Guid from "common/values/guid/guid";
import NetworkConnectionForumAPIResponse from "./response-contracts/network-connection-forum-api-response";
import Forum from "messaging/entities/forum/forum";
import NetworkedUserInfoAPIResponse from "users/entities/user-network-connection/api/response-contracts/networked-user-info-api-response";
import NetworkedUserInfo from "users/entities/user-network-connection/networked-user-info";
import axios, { AxiosHeaders, CanceledError } from "axios";
import Session from "users/session/session";

export default class UserNetworkConnectionAPIService {
  private authHeaders: AxiosHeaders = new AxiosHeaders();

  constructor(session: Readonly<Session>) {
    if (!session.authToken?.value)
      throw new Error("Session must have an authToken to create a UserNetworkConnectionAPIService");
    this.authHeaders.set("Authorization", `Bearer ${session.authToken.value}`);
  }

  async getUserConnectionsInfo(abortController?: AbortController): Promise<NetworkedUserInfo[]> {
    const url = new URL("/users/network-connections", AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(url.toString(), {
        headers: this.authHeaders,
        signal: abortController?.signal
      });
      const connections: Array<NetworkedUserInfo> = [];
      response.data.forEach((connection: any) => {
        const connectionResponse = Object.assign(new NetworkedUserInfoAPIResponse(), connection);
        connections.push(connectionResponse.deserialize());
      });
      return connections;
    } catch (error: any) {
      if (error?.response?.status === 400) {
        throw new Error(error.response.data);
      }
      if (error instanceof CanceledError) throw error;
      throw new UserNetworkConnectionAPIServiceError("getUserConnectionsInfo", error);
    }
  }

  async removeNetworkConnection(connectionId: Guid): Promise<void> { 
    try {
      const url = new URL(`/users/network-connections/${connectionId}`, AttorneyHubAPIService.apiBaseUrl);
      await axios.delete(url.toString(), {
        headers: this.authHeaders
      });
    } catch (error: any) {
      switch (error.response.status) {
        case 404:
          throw new NetworkConnectionNotFoundException(
            "Unable to remove network connection: not found."
          );
          case 400:
            throw new Error(error.response.data);
        default:
          throw new UserNetworkConnectionAPIServiceError("removeNetworkConnection", error);
      }
    }
  }

  async getChatForumForNetworkConnection(userId?: Guid, abortController?: AbortController): Promise<Forum> {
    if (!userId) throw new Error("User ID is required to get chat forum for network connection.");
    try {
      const url = new URL(`/users/network-chat/${userId}`, AttorneyHubAPIService.apiBaseUrl);
      const response = await axios.get(url.toString(), {
        headers: this.authHeaders,
        signal: abortController?.signal
      });
      const forumResponse: NetworkConnectionForumAPIResponse = Object.assign(new NetworkConnectionForumAPIResponse(), response.data);
      return forumResponse.deserialize();
    } catch (error: any) {
      if (error?.response?.status === 400) {
        throw new Error(error.response.data);
      }
      if (error instanceof CanceledError) throw error;
      throw new UserNetworkConnectionAPIServiceError("getChatForumForNetworkConnection", error);
    }
  }
}

export class UserNetworkConnectionAPIServiceError extends Error {
  constructor(public method: string, public error: any) {
    super(`Error in UserNetworkConnectionAPIService.${method}: ${error}`);
    this.name = "UserNetworkConnectionAPIServiceError";
  }
};
export class NetworkConnectionForumNotFoundException extends Error {};
export class NetworkConnectionNotFoundException extends Error {};
