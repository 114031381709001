import { ReactNode, useEffect, useState } from 'react';

import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { EntityOfficerInvitationsPage } from 'legal-entities/pages';
import SuperUserAPIService from 'users/entities/super-user/api/super-user-api-service';
import SystemNotifications from 'users/entities/super-user/view/system-notifications';
import UsersAdmin from 'users/entities/super-user/view/users';
import ActiveUserInvitationsPage from 'users/entities/super-user/view/active-user-invitations-page';
import { CanceledError } from 'axios';
import { useSession } from 'users/session/session-context';

const PageContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: 0
  },
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  marginTop: theme.spacing(6),
  maxWidth: '70rem'
}));
const PageTitle = styled(Typography)({
  marginBottom: '2rem',
  textAlign: 'center'
});
const Version = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontSize: '1.2em',
  marginTop: theme.spacing(2),
  textAlign: 'left'
}));

type SuperUserDashboardProps = {
  children?: ReactNode;
};

export default function SuperUserDashboard(props: Readonly<SuperUserDashboardProps>) {
  const [tab, setTab] = useState<string>('users');
  const [appVersion, setAppVersion] = useState<string>('Unknown');
  const session = useSession();

  useEffect(() => {
    async function getAppVersion(abortController: AbortController): Promise<string | undefined> {
      try {
        const superUserService = new SuperUserAPIService(session);
        return await superUserService.getAppVersion(abortController);
      } catch (error: any) {
        if (error instanceof CanceledError) {
          return;
        } else {
          console.error(error.message);
        }
      }
    }
    let abortController = new AbortController();
    
    getAppVersion(abortController).then((version) => setAppVersion(version ?? 'Unknown'));
    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, []);

  return (
    <PageContainer>
      <PageTitle id="super-user-dashboard-header" variant="h4">Super User Dashboard</PageTitle>
      <Tabs
        variant="fullWidth"
        indicatorColor="primary"
        value={tab}
        onChange={(_event, newValue) => setTab(newValue)}
        textColor="primary">
        <Tab id="super-user-users-tab" label="Users" value="users" />
        <Tab id="super-user-entities-tab" label="Entities" value="entities" />
        <Tab id="super-user-invitations-tab" label="Invitations" value="invitations" />
        <Tab id="super-user-notifications-tab" label="System Notifications" value="system-notifications" />
      </Tabs>
      {tab === 'users' && (
        <UsersAdmin />
      )}
      {tab === 'entities' && (
        <EntityOfficerInvitationsPage />
      )}
      {tab === 'invitations' && (
        <ActiveUserInvitationsPage />
      )}
      {tab === 'system-notifications' && (
        <SystemNotifications />
      )}
      <Version>{appVersion}</Version>
    </PageContainer>
  )
}
