import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import CommentIcon from "@mui/icons-material/Comment";
import {
  Badge,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornmentProps,
  TextField,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AccountType } from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import Forum from "messaging/entities/forum/forum";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import NetworkedUserInfo from "users/entities/user-network-connection/networked-user-info";
import { useSession } from "users/session/session-context";
import EntityClientRepresentative from "work/entities/entity-client-representative/entity-client-representative";
import { ProposalTab } from "work/entities/proposal/redlining/view/proposal-redline-dialog";
import { getForumForField } from "work/entities/proposal/utils/comment-utils";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";
import { ProposalFieldName } from "work/values/constants";
import ProjectDescription from "work/values/project-description/project-description";
import ProjectName from "work/values/project-name/project-name";
import ClientSelector from "work/view/components/client-selector";
import Date from "common/values/date/date";

const ProposalContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
  },
  display: "grid",
  gap: theme.spacing(6),
  gridTemplateColumns: "auto auto",
  width: "100%",
}));
const GeneralSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));
const DateSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  "& .MuiFormControl-root": {
    marginTop: theme.spacing(1),
  },
}));
const ClientInput = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    minWidth: "unset",
    width: "100%",
  },
  margin: theme.spacing(1, 0),
  minWidth: theme.spacing(50),
}));
const NameTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    minWidth: "unset",
    width: "100%",
  },
  margin: theme.spacing(1, 0),
  minWidth: theme.spacing(50),
}));
const DescriptionTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    minWidth: "unset",
    width: "100%",
  },
  margin: theme.spacing(1, 0),
  minWidth: theme.spacing(50),
}));
const NegotiableCheckbox = styled("div")(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));
const DateContainer = styled("div")(({ theme }) => ({
  display: "flex",
  margin: theme.spacing(1, 0),
  width: "100%",
  "& > :first-child": {
    flex: 1,
  },
}));
const DateControlLabel = styled(FormControlLabel)(({ theme }) => ({
  width: "100%",
}));
const DateFieldButtons = styled("span")(({ theme }) => ({
  alignItems: "center",
  flexDirection: "row",
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  "& > :first-child": {
    marginRight: 0,
  },
}));

type DetailsTabProps = {
  activeTab: ProposalTab;
  proposalBuilder: ProposalBuilder;
  disableCommenting: boolean;
  disableEditing?: boolean;
  commentForums: Forum[];
  isRequesting?: boolean;
  onChange: () => void;
  onSelectingClientChange: (isSelectingClient: boolean) => void;
  onErrorCountChange: (errorCount: number) => void;
  onCommentsClicked: (
    field: ProposalFieldName,
    id?: Guid,
    name?: string
  ) => void;
};

export default function DetailsTab(props: Readonly<DetailsTabProps>) {
  const {
    activeTab,
    proposalBuilder,
    disableCommenting,
    disableEditing,
    commentForums,
    isRequesting,
    onChange,
    onSelectingClientChange,
    onErrorCountChange,
    onCommentsClicked,
  } = props;

  const [nameValue, setNameValue] = useState<string>(
    proposalBuilder.currentSpec.name?.value ?? ""
  );
  const [descriptionValue, setDescriptionValue] = useState<string>(
    proposalBuilder.currentSpec.description?.value ?? ""
  );
  const [isNegotiable, setIsNegotiable] = useState<boolean>(
    proposalBuilder.currentSpec.negotiable ?? true
  );
  const [isUsingResponseDueBy, setIsUsingResponseDueBy] = useState<boolean>(
    Boolean(proposalBuilder.currentSpec.responseDueBy)
  );
  const [responseDueBy, setResponseDueBy] = useState<Moment | undefined>(
    proposalBuilder.currentSpec.responseDueBy?.value
  );
  const [isUsingStartDate, setIsUsingStartDate] = useState<boolean>(
    Boolean(proposalBuilder.currentSpec.startDate)
  );
  const [startDate, setStartDate] = useState<Moment | undefined>(
    proposalBuilder.currentSpec.startDate?.value
  );
  const [isUsingEndDate, setIsUsingEndDate] = useState<boolean>(
    Boolean(proposalBuilder.currentSpec.endDate)
  );
  const [endDate, setEndDate] = useState<Moment | undefined>(
    proposalBuilder.currentSpec.endDate?.value
  );
  const [isSelectingClient, setIsSelectingClient] = useState<boolean>(false);
  const [isEditingName, setIsEditingName] = useState<boolean>(false);
  const [isEditingDescription, setIsEditingDescription] =
    useState<boolean>(false);
  const [clientSelectorAnchor, setClientSelectorAnchor] =
    useState<HTMLDivElement>();
  const [clientName, setClientName] = useState<string>("");
  const [lastErrorCount, setLastErrorCount] = useState<number>(0);

  const session = useSession();

  useEffect(() => {
    if (proposalBuilder.currentSpec.client) {
      setClientName(proposalBuilder.currentSpec.client.name.toString());
    } else {
      setDefaultProposalClient();
    }
  }, []);

  function getErrorCount() {
    return [!clientName, !nameValue, !descriptionValue].filter((error) => error)
      .length;
  }

  function handleFormDataChanged() {
    const errorCount = getErrorCount();

    if (errorCount !== lastErrorCount) {
      onErrorCountChange(errorCount);
      setLastErrorCount(errorCount);
    }

    onChange();
  }

  function getMinValidDate(compareDate: Moment, daysToAdd: number = 1) {
    const currentDateUnix = moment().unix();
    const compareDateUnix = compareDate.unix();

    return moment
      .unix(Math.max(currentDateUnix, compareDateUnix))
      .add(daysToAdd, "day");
  }

  function setDefaultProposalClient() {
    if (
      session.accountType === AccountType.Client &&
      session.context?.isClientRepresentative &&
      session.user?.asClientRepresentative &&
      !proposalBuilder.currentSpec.client
    ) {
      proposalBuilder.setClient(session.user.asClientRepresentative);
      setClientName(session.user?.name?.toString() ?? "");
    }
  }

  function handleSelectClientClicked(event: React.MouseEvent<HTMLDivElement>) {
    const inputElements = event.currentTarget.getElementsByTagName("input");
    setClientSelectorAnchor(
      inputElements.length > 0 ? inputElements[0] : event.currentTarget
    );
    setIsSelectingClient(true);
    onSelectingClientChange(true);
  }

  async function handleClientUpdated(userInfo?: NetworkedUserInfo) {
    if (!userInfo?.userId || !userInfo?.companyEntityId || !userInfo?.name) {
      proposalBuilder.setClient();
      setClientName("");
      handleFormDataChanged();
      return;
    }

    proposalBuilder.setClient(
      new EntityClientRepresentative(
        userInfo.userId,
        userInfo.companyEntityId,
        userInfo.name
      )
    );
    setClientName(userInfo.name?.toString() ?? "");
    handleFormDataChanged();
  }

  function renderCommentsButton(field: ProposalFieldName) {
    return (
      <Tooltip
        title={
          disableCommenting ? "Save proposal to enable commenting" : "Comments"
        }
      >
        <span>
          <IconButton
            disabled={disableCommenting}
            onClick={(event) => {
              event.stopPropagation();
              onCommentsClicked(field);
            }}
          >
            <Badge
              variant="dot"
              color="secondary"
              overlap="circular"
              invisible={!getForumForField(field, undefined, commentForums)}
            >
              <CommentIcon fontSize="medium" />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  function renderTextFieldButtons(
    field: "name" | "description",
    isEditing: boolean,
    setter: (value: React.SetStateAction<boolean>) => void,
    callback: () => void
  ) {
    return (
      <>
        {!disableEditing && !isEditing && (
          <Tooltip title="Edit">
            <span>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  setter(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {isEditing && (
          <>
            <Tooltip title="Submit">
              <span>
                <IconButton onClick={(event) => {
                  console.log("submitting change");
                  event.preventDefault();
                  event.stopPropagation();
                  callback()
                }}>
                  <CheckIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Cancel">
              <span>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    setter(false);
                    if (field === "name") {
                      setNameValue(
                        proposalBuilder.currentSpec.name?.value ?? ""
                      );
                    }
                    if (field === "description") {
                      setDescriptionValue(
                        proposalBuilder.currentSpec.description?.value ?? ""
                      );
                    }
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
      </>
    );
  }

  function renderDateFieldButtons(
    params: InputAdornmentProps,
    field: ProposalFieldName
  ) {
    return (
      <DateFieldButtons>
        {params.children}
        {renderCommentsButton(field)}
      </DateFieldButtons>
    );
  }

  function setProposalName() {
    setIsEditingName(false);
    const projectName = new ProjectName(nameValue);
    proposalBuilder.setName(projectName);
    handleFormDataChanged();
  }

  function setProposalDescription() {
    setIsEditingDescription(false);
    const projectDescription = new ProjectDescription(descriptionValue);
    proposalBuilder.setDescription(projectDescription);
    handleFormDataChanged();
  }

  if (activeTab !== ProposalTab.Details) return null;

  return (
    <ProposalContainer>
      <GeneralSection>
        <ClientInput
          disabled={
            disableEditing ||
            isSelectingClient ||
            clientSelectorAnchor !== undefined
          }
          label={
            clientName
              ? "Client Representative"
              : "Select Client Representative"
          }
          helperText="required"
          required
          error={!clientName}
          onClick={handleSelectClientClicked}
          value={clientName}
          slotProps={{
            input: {
              endAdornment: (
                <>
                  {!disableEditing && clientName && (
                    <Tooltip title="Remove Client">
                      <span>
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            proposalBuilder.setClient();
                            setClientName("");
                            handleFormDataChanged();
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </>
              ),
            },
            inputLabel: {
              shrink: clientName !== "",
            },
          }}
        />
        <NameTextField
          label="Proposal Name"
          required={true}
          value={nameValue}
          helperText="required"
          error={proposalBuilder.currentSpec.name?.value === ""}
          slotProps={{
            input: {
              endAdornment: (
                <>
                  {renderTextFieldButtons(
                    "name",
                    isEditingName,
                    setIsEditingName,
                    setProposalName
                  )}
                  {renderCommentsButton(ProposalFieldName.Name)}
                </>
              ),
            },
          }}
          onClick={() => setIsEditingName(true)}
          onChange={(event) => setNameValue(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === "Tab") {
              event.stopPropagation();
              event.preventDefault();
              setProposalName();
            } else if (event.key === "Escape") {
              setNameValue(proposalBuilder.currentSpec.name?.value ?? "");
              setIsEditingName(false);
            } else {
              setIsEditingName(true);
            }
          }}
        />
        <DescriptionTextField
          label="Description"
          required={true}
          multiline={true}
          rows={3}
          value={descriptionValue}
          helperText="required"
          error={proposalBuilder.currentSpec.description?.value === ""}
          slotProps={{
            input: {
              endAdornment: (
                <>
                  {renderTextFieldButtons(
                    "description",
                    isEditingDescription,
                    setIsEditingDescription,
                    setProposalDescription
                  )}
                  {renderCommentsButton(ProposalFieldName.Description)}
                </>
              ),
            },
          }}
          onClick={() => setIsEditingDescription(true)}
          onChange={(event) => setDescriptionValue(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === "Tab") {
              event.stopPropagation();
              event.preventDefault();
              setProposalDescription();
            }
            else if (event.key === "Escape") {
              setDescriptionValue(proposalBuilder.currentSpec.description?.value ?? "");
              setIsEditingDescription(false);
            } else {
              setIsEditingDescription(true);
            }
          }}
        />
      </GeneralSection>
      {!isRequesting && (
        <DateSection>
          <NegotiableCheckbox>
            <FormControlLabel
              label="Negotiable"
              control={
                <Checkbox
                  color="primary"
                  checked={isNegotiable}
                  disabled={disableEditing}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => {
                    setIsNegotiable(checked);
                    proposalBuilder?.setNegotiable(checked);
                    handleFormDataChanged();
                  }}
                />
              }
            />
          </NegotiableCheckbox>
          <DateContainer>
            <span>
              <DateControlLabel
                slotProps={{
                  typography: {
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  },
                }}
                label={
                  !isUsingResponseDueBy ? (
                    <>
                      <span>Set a response due date?</span>
                      <span>
                        {renderCommentsButton(ProposalFieldName.ResponseDueBy)}
                      </span>
                    </>
                  ) : null
                }
                control={
                  <Checkbox
                    color="primary"
                    required={false}
                    disabled={disableEditing}
                    checked={isUsingResponseDueBy}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean
                    ) => {
                      setIsUsingResponseDueBy(checked);
                      const newDate = checked ? new Date(moment()) : undefined;
                      setResponseDueBy(newDate?.value);
                      proposalBuilder?.setResponseDueBy(newDate);
                      handleFormDataChanged();
                    }}
                  />
                }
              />
            </span>
            {isUsingResponseDueBy && (
              <DatePicker
                label="Response Due Date"
                disablePast
                disabled={disableEditing}
                value={responseDueBy}
                minDate={moment()}
                slots={{
                  inputAdornment: (params) =>
                    renderDateFieldButtons(
                      params,
                      ProposalFieldName.ResponseDueBy
                    ),
                }}
                onChange={(date: Moment | null) => {
                  setResponseDueBy(date ?? undefined);
                  proposalBuilder?.setResponseDueBy(
                    date ? new Date(date) : undefined
                  );
                  handleFormDataChanged();
                }}
              />
            )}
          </DateContainer>
          <DateContainer>
            <Tooltip
              title={
                !proposalBuilder.canBuild
                  ? "You must have a client, name and description before setting a start date."
                  : ""
              }
            >
              <span>
                <DateControlLabel
                  slotProps={{
                    typography: {
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    },
                  }}
                  label={
                    !isUsingStartDate ? (
                      <>
                        <span>Set a start date?</span>
                        <span>
                          {renderCommentsButton(ProposalFieldName.StartDate)}
                        </span>
                      </>
                    ) : null
                  }
                  control={
                    <Checkbox
                      color="primary"
                      required={false}
                      checked={isUsingStartDate}
                      disabled={!proposalBuilder.canBuild || disableEditing}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                      ) => {
                        setIsUsingStartDate(checked);
                        const newDate = checked
                          ? new Date(moment())
                          : undefined;
                        setStartDate(newDate?.value);
                        proposalBuilder?.setStartDate(newDate);
                        handleFormDataChanged();
                      }}
                    />
                  }
                />
              </span>
            </Tooltip>
            {isUsingStartDate && (
              <DatePicker
                label="Start Date"
                disablePast
                disabled={disableEditing}
                value={startDate}
                minDate={responseDueBy ?? moment()}
                slots={{
                  inputAdornment: (params) =>
                    renderDateFieldButtons(params, ProposalFieldName.StartDate),
                }}
                onChange={(date: Moment | null) => {
                  setStartDate(date ?? undefined);
                  proposalBuilder?.setStartDate(
                    date ? new Date(date) : undefined
                  );
                  handleFormDataChanged();
                }}
              />
            )}
          </DateContainer>
          <DateContainer>
            <Tooltip
              title={
                !proposalBuilder.canBuild
                  ? "You must have a client, name and description before setting an end date."
                  : ""
              }
            >
              <span>
                <DateControlLabel
                  slotProps={{
                    typography: {
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    },
                  }}
                  label={
                    !isUsingEndDate ? (
                      <>
                        <span>Set a end date?</span>
                        <span>
                          {renderCommentsButton(ProposalFieldName.EndDate)}
                        </span>
                      </>
                    ) : null
                  }
                  control={
                    <Checkbox
                      color="primary"
                      required={false}
                      checked={isUsingEndDate}
                      disabled={!proposalBuilder.canBuild || disableEditing}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                      ) => {
                        setIsUsingEndDate(checked);
                        const newDate = checked
                          ? new Date(moment())
                          : undefined;
                        setEndDate(newDate?.value);
                        proposalBuilder?.setEndDate(newDate);
                        handleFormDataChanged();
                      }}
                    />
                  }
                />
              </span>
            </Tooltip>
            {isUsingEndDate && (
              <DatePicker
                label="End Date"
                disablePast
                disabled={disableEditing}
                value={endDate}
                minDate={getMinValidDate(
                  startDate ?? responseDueBy ?? moment(),
                  1
                )}
                slots={{
                  inputAdornment: (params) =>
                    renderDateFieldButtons(params, ProposalFieldName.EndDate),
                }}
                onChange={(date: Moment | null) => {
                  setEndDate(date ?? undefined);
                  proposalBuilder?.setEndDate(
                    date ? new Date(date) : undefined
                  );
                  handleFormDataChanged();
                }}
              />
            )}
          </DateContainer>
        </DateSection>
      )}
      <ClientSelector
        clientUserId={proposalBuilder.currentSpec.client?.userId}
        popoverAnchor={clientSelectorAnchor}
        onPopoverClose={() => {
          setClientSelectorAnchor(undefined);
          setIsSelectingClient(false);
          onSelectingClientChange(false);
        }}
        onClientUpdated={handleClientUpdated}
      />
    </ProposalContainer>
  );
}
