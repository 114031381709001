export enum ProposalStatus {
  AwaitingSubmission = "AwaitingSubmission",
  AwaitingApprovalByClient = "AwaitingApprovalByClient",
  AwaitingApprovalByTeamLeader = "AwaitingApprovalByTeamLeader",
  AwaitingApprovalByVendors = "AwaitingApprovalByVendors",
  AwaitingApprovalByTeam = "AwaitingApprovalByTeam",
  AwaitingHire = "AwaitingHire",
  RevisionRequested = "RevisionRequested",
  Archived = "Archived"
}

export enum HumanReadableProposalStatus {
  AwaitingSubmission = 'Awaiting Submission',
  AwaitingApprovalByClient = 'Awaiting Client Approval',
  AwaitingApprovalByTeamLeader = 'Awaiting Team Leader Approval ',
  AwaitingApprovalByVendors = 'Awaiting Vendor Approval ',
  AwaitingApprovalByTeam = 'Awaiting Team Approval',
  AwaitingHire = 'Awaiting Hire',
  AwaitingFurtherRevision = 'Awaiting Further Revision',
  RevisionRequested = 'Revision Requested',
  Canceled = 'Canceled',
  Archived = 'Archived',
  Unknown = 'Unknown'
};

export enum ProposalFieldName {
  Client = 'client',
  Name = 'name',
  Description = 'description',
  Negotiable = 'negotiable',
  ResponseDueBy = 'responseDueBy',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Team = 'team',
  FeeSchedule = 'feeSchedule',
  WaiveConflictsCheck = 'waiveConflictsCheck',
  TeamRestriction = 'teamRestriction',
  Conflicts = 'conflicts',
  ClientPolicies = 'clientPolicies',
  VendorPolicies = 'vendorPolicies',
  Discount = 'discount',
  General = 'general'
};

export enum HumanReadableProposalFieldName {
  client = 'Client',
  name = 'Name',
  description = 'Description',
  negotiable = 'Negotiable',
  responseDueBy = 'Response Due By',
  startDate = 'Start Date',
  endDate = 'End Date',
  team = 'Team',
  feeSchedule = 'Fee Schedule',
  waiveConflictsCheck = 'Waive Conflicts Check',
  teamRestriction = 'Team Restriction',
  conflicts = 'Conflicts',
  clientPolicies = 'Client Policies',
  vendorPolicies = 'Vendor Policies',
  discount = 'Discount',
  general = 'General'
};

export const proposalActor = {
  Client: 0,
  Vendor: 1,
  TeamLeader: 2,
  TeamMember: 3,
  Unknown: 4
};

export const proposalActions = {
  Delete: 0,
  Submit: 1,
  Approve: 2,
  Reject: 3,
  Hire: 4,
  Revise: 5,
  Cancel: 6,
  Edit: 7
};

export enum ProposalAction {
  Submit = "Submit",
  Approve = "Approve",
  Reject = "Reject",
  Revise = "Revise",
  Hire = "Hire",
  Delete = "Delete",
  Cancel = "Cancel",
  Edit = "Edit",
  Manage = "Manage",
  Review = "Review"
}
