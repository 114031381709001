import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Button,
  Chip,
  Icon,
  IconButton,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {
  ConfirmResponse,
  useConfirmDialog,
} from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import { PageTab } from "app/routes/network/tabs";
import { CanceledError } from "axios";
import Guid from "common/values/guid/guid";
import EntityMemberAPIService from "legal-entities/entities/entity-member/api/entity-member-api-service";
import EntityMember from "legal-entities/entities/entity-member/entity-member";
import LegalEntity from "legal-entities/entities/legal-entity/legal-entity";
import ViewIndividualProfile from "marketplace/values/individual-profile/view/view-individual-profile";
import IndividualAvatar from "marketplace/view/individual-avatar";
import {
  createMRTColumnHelper,
  MaterialReactTable,
  MRT_Row,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable,
} from "material-react-table";
import ChatDialog from "messaging/entities/forum/view/components/chat-dialog";
import { enqueueSnackbar } from "notistack";
import * as React from "react";
import { useEffect } from "react";
import {
  Params,
  useLoaderData,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import UserNetworkConnectionAPIService from "users/entities/user-network-connection/api/user-network-connection-api-service";
import NetworkedUserInfo from "users/entities/user-network-connection/networked-user-info";
import NetworkInvitationAPIService from "users/entities/user-network-invitation/api/network-invitation-api-service";
import NetworkInvitationInfo from "users/entities/user-network-invitation/network-invitation-info";
import { useSession } from "users/session/session-context";

const ToolbarButtonsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "end",
  flexDirection: "column",
  gap: theme.spacing(1),
}));
const ToolbarButtons = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "end",
  flexWrap: "nowrap",
}));
const RowActionsContainer = styled("div")(({ theme }) => ({
  whiteSpace: "nowrap",
}));
const TableTopToolbarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  widtgh: "100%",
}));
const LowerTopToolbarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
}));
const TableTitle = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));
const RowTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.1em",
  fontWeight: "600",
  marginLeft: theme.spacing(1.5),
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));
const NameCellContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));
const FilterButtons = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    marginLeft: theme.spacing(1),
  },
  "& > .MuiChip-root": {
    marginLeft: theme.spacing(1),
  },
}));

export type NetworkTableDataEntry =
  | NetworkedUserInfo
  | EntityMember
  | NetworkInvitationInfo;
type NetworkProps = {};

export default function Network(props: Readonly<NetworkProps>) {
  const [data, setData] = React.useState<NetworkTableDataEntry[]>([]);
  const [wasErrorLoading, setWasErrorLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);

  const [activeTab, setActiveTab] = React.useState(PageTab.Contacts);
  const [sent, setSent] = React.useState(false);
  const [received, setReceived] = React.useState(false);

  const routeParams = useLoaderData() as Params<string>;
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const session = useSession();
  const confirm = useConfirmDialog();
  const { openDialog, closeAllDialogs } = useDialog();

  // Close any open dialogs when the URL changes
  useEffect(() => {
    closeAllDialogs();
  }, [location]);

  // Handle URL changes
  useEffect(() => {
    let abortController = new AbortController();

    if (routeParams.tab) {
      setActiveTab(routeParams.tab as PageTab);
    } else {
      navigate(`/network/${PageTab.Contacts}`);
    }

    if (routeParams.action === "message" && routeParams.id) {
      handleSendMessageToContact(new Guid(routeParams.id));
    }
    if (routeParams.action === "view" && routeParams.id) {
      handleViewProfile(new Guid(routeParams.id));
    }

    let context = searchParams.get("context");
    if (context === null && routeParams.tab === PageTab.Pending) {
      setSearchParams((prevParams) => {
        const params = new URLSearchParams(prevParams);
        params.set("context", "received");
        return params;
      });
      context = "received";
    }
    setSent(context === "sent");
    setReceived(context === "received");

    fetchData(routeParams.tab as PageTab, context, abortController);

    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, [routeParams]);

  const fetchData = async (
    tab: PageTab,
    context: string | null,
    abortController?: AbortController
  ) => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      let data: NetworkTableDataEntry[] = [];

      if ((tab ?? activeTab) === PageTab.Contacts) {
        const userNetworkService = new UserNetworkConnectionAPIService(session);
        let connections = await userNetworkService.getUserConnectionsInfo(
          abortController
        );
        session?.updateNetworkConnections(connections);
        data = connections;
      } else if ((tab ?? activeTab) === PageTab.Coworkers) {
        const entityId = session?.entities[0]?.entityId;
        if (entityId) {
          const entity = new LegalEntity(entityId);
          const service = new EntityMemberAPIService(session);
          data = await service.getLegalEntityMembersUserInfo(
            entity,
            abortController
          );
        }
      } else if ((tab ?? activeTab) === PageTab.Pending) {
        const networkInvitationService = new NetworkInvitationAPIService(
          session
        );
        data = await networkInvitationService.getUserNetworkInvitations(
          abortController
        );
        data = data.filter((invitation) => {
          invitation = invitation as NetworkInvitationInfo;
          if (context === "sent" && invitation.inviter) return true;
          if (context === "received" && !invitation.inviter) return true;
          return false;
        });
      }

      setData(data);
    } catch (error) {
      if (error instanceof CanceledError) return;
      setWasErrorLoading(true);
      console.error(error);
      return;
    }

    setWasErrorLoading(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  function renderContactNameColumn(user: NetworkedUserInfo | EntityMember) {
    return (
      <NameCellContainer>
        <IndividualAvatar
          avatarId={user.avatarId}
          individualId={user.individualId}
          session={session}
        />
        <RowTitle>{user.name?.toString()}</RowTitle>
      </NameCellContainer>
    );
  }

  function renderPendingNameColumn(invitation: NetworkInvitationInfo) {
    return (
      <NameCellContainer>
        <IndividualAvatar
          avatarId={invitation.avatarId}
          individualId={invitation.individualId}
          session={session}
        />
        <RowTitle>{invitation.user?.fullName?.toString()}</RowTitle>
      </NameCellContainer>
    );
  }

  const columnHelper = createMRTColumnHelper<NetworkTableDataEntry>();

  const contactsColumns = [
    columnHelper.accessor(
      (data) =>
        renderContactNameColumn(data as NetworkedUserInfo | EntityMember),
      {
        header: "Name",
        id: "name",
      }
    ),
    columnHelper.accessor(
      (entry) => (entry as NetworkedUserInfo).createdDate?.format("L"),
      {
        header: "Connected On",
        id: "createdDate",
      }
    ),
  ];

  const pendingColumns = [
    columnHelper.accessor(
      (data) => renderPendingNameColumn(data as NetworkInvitationInfo),
      {
        header: "Name",
        id: "name",
      }
    ),
    columnHelper.accessor(
      (entry) => {
        const created = (entry as NetworkInvitationInfo).created;
        if (created instanceof Date) {
          return created.toLocaleDateString();
        }
        return created;
      },
      {
        header: "Sent",
        id: "createdDate",
      }
    ),
    columnHelper.accessor(
      (entry) => {
        const message = (entry as NetworkInvitationInfo).message;
        return message ? message : <Icon>remove</Icon>;
      },
      {
        header: "Message",
        id: "message",
      }
    ),
    columnHelper.accessor((entry) => (entry as NetworkInvitationInfo).status, {
      header: "Status",
      id: "status",
    }),
  ];

  async function handleAcceptInvitation(invitationId: Guid): Promise<void> {
    if (!invitationId)
      Promise.reject(new Error("No network invitation id provided"));
    try {
      const networkInvitationService = new NetworkInvitationAPIService(session);
      const connection = await networkInvitationService.acceptNetworkInvitation(
        invitationId
      );
      session.addNetworkConnection(
        connection.toProfileConnection(session.user?.id)
      );
      fetchData(activeTab, searchParams.get("context"));
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Failed to accept invitation", { variant: "error" });
    }
  }

  async function handleRejectInvitation(invitationId: Guid): Promise<void> {
    try {
      if (!invitationId)
        Promise.reject(new Error("No network invitation id provided"));
      const networkInvitationService = new NetworkInvitationAPIService(session);
      await networkInvitationService.rejectNetworkInvitation(invitationId);
      session.removeNetworkInvitation(invitationId);
      fetchData(activeTab, searchParams.get("context"));
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Failed to reject invitation", { variant: "error" });
    }
  }

  async function handleCancelInvitation(invitationId: Guid): Promise<void> {
    try {
      if (!invitationId)
        Promise.reject(new Error("No network invitation id provided"));

      const response = await confirm({
        title: "Cancel Network Connection Invitation?",
        message:
          "Are you sure you want to cancel this network connection invitation?",
        okButtonText: "Yes",
        cancelButtonText: "No",
      });

      if (response === ConfirmResponse.Cancel) return;

      const networkInvitationService = new NetworkInvitationAPIService(session);
      await networkInvitationService.cancelNetworkInvitation(invitationId);
      session.removeNetworkInvitation(invitationId);
      fetchData(activeTab, searchParams.get("context"));
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Failed to cancel invitation", { variant: "error" });
    }
  }

  async function handleRemoveNetworkConnection(
    connectionId?: Guid
  ): Promise<void> {
    try {
      if (!connectionId) throw new Error("No network connection id provided");

      const response = await confirm({
        title: "Remove Network Connection",
        message: "Are you sure you want to remove this network connection?",
        okButtonText: "Remove",
      });

      if (response === ConfirmResponse.Cancel) return;

      const userNetworkService = new UserNetworkConnectionAPIService(session);
      await userNetworkService.removeNetworkConnection(connectionId);
      session.removeNetworkConnection(connectionId);
      fetchData(activeTab, searchParams.get("context"));
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Failed to remove network connection", {
        variant: "error",
      });
    }
  }

  async function handleSendProposalToContact(targetUrl: URL): Promise<void> {
    navigate(targetUrl);
  }

  async function handleSendMessageToContact(userId?: Guid): Promise<void> {
    try {
      if (!userId) throw new Error("No user id provided");

      const userNetworkService = new UserNetworkConnectionAPIService(session);
      const forum = await userNetworkService.getChatForumForNetworkConnection(
        userId
      );
      const parentPath = new URL(
        ".",
        window.location.origin + window.location.pathname
      );

      openDialog(
        {
          title: "Message",
          contentSxProps: {
            display: "flex",
            overflowX: "hidden",
            paddingBottom: 0,
          },
          MuiProps: {
            fullWidth: true,
            maxWidth: "md",
          },
          component: <ChatDialog forums={[forum]} />,
        },
        () => navigate(parentPath.pathname)
      );
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Failed to send message", { variant: "error" });
    }
  }

  function handleViewProfile(individualId?: Guid): void {
    if (!individualId) return;

    openDialog(
      {
        titleStyle: {
          position: "absolute",
          right: 0,
          top: 0,
        },
        contentSxProps: {
          display: "flex",
          overflowX: "hidden",
        },
        MuiProps: {
          maxWidth: "lg",
          fullWidth: false,
        },
        component: (
          <ViewIndividualProfile
            individualId={individualId}
            onNetworkInvitationResolved={() => fetchData(activeTab, null)}
          />
        ),
      }
    );
  }

  function handleExpandNetwork(): void {
    navigate("/marketplace");
  }

  function getPendingTabActions(row: MRT_Row<NetworkTableDataEntry>) {
    const invitation = row.original as NetworkInvitationInfo;
    return (
      <>
        {invitation.inviter ? (
          <Tooltip title="Cancel Invitation">
            <span>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  handleCancelInvitation(invitation.id);
                }}
              >
                <CancelIcon color="error" />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          <>
            <Tooltip title="Accept Invitation">
              <span>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    handleAcceptInvitation(invitation.id);
                  }}
                >
                  <CheckIcon color="success" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Reject Invitation">
              <span>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    handleRejectInvitation(invitation.id);
                  }}
                >
                  <CloseIcon color="error" />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
      </>
    );
  }

  function getContactsActions(row: MRT_Row<NetworkTableDataEntry>) {
    // If the user is a contact
    const connection = row.original as NetworkedUserInfo;
    return (
      <Tooltip title="Remove Connection">
        <span>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              handleRemoveNetworkConnection(connection.connectionId);
            }}
          >
            <DeleteIcon color="error" />
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  const table = useMaterialReactTable({
    columns: activeTab === PageTab.Pending ? pendingColumns : contactsColumns,
    data,
    enableRowSelection: false,
    enableTableHead: true,
    enableRowActions: activeTab !== PageTab.Coworkers,
    enableColumnPinning: true,
    renderToolbarInternalActions: ({ table }) => (
      <ToolbarButtonsContainer>
        <ToolbarButtons>
          <MRT_ToggleGlobalFilterButton table={table} />
          <MRT_ToggleFiltersButton table={table} />
          <MRT_ShowHideColumnsButton table={table} />
          <MRT_ToggleDensePaddingButton table={table} />
          <MRT_ToggleFullScreenButton table={table} />
        </ToolbarButtons>
        <Button startIcon={<AddIcon />} onClick={() => handleExpandNetwork()}>
          Expand Network
        </Button>
      </ToolbarButtonsContainer>
    ),
    renderRowActions: ({ row }) => {
      return (
        <RowActionsContainer>
          {activeTab === PageTab.Pending && getPendingTabActions(row)}
          {activeTab === PageTab.Contacts && getContactsActions(row)}
        </RowActionsContainer>
      );
    },
    renderTopToolbarCustomActions: () => {
      return (
        <TableTopToolbarContainer>
          <TableTitle variant="h4">Network</TableTitle>
          <LowerTopToolbarContainer>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_event, newValue) => {
                navigate(`/network/${newValue}`);
              }}
            >
              <Tab value={PageTab.Contacts} label="Contacts" />
              <Tab value={PageTab.Coworkers} label="Coworkers" />
              <Tab value={PageTab.Pending} label="Pending" />
            </Tabs>
            {activeTab === PageTab.Pending && (
              <FilterButtons>
                <Chip
                  icon={<FilterListIcon />}
                  label="Received"
                  color="primary"
                  variant={received ? "filled" : "outlined"}
                  onClick={() => {
                    if (received) return;
                    setReceived((prevValue) => !prevValue);
                    setSearchParams((prevParams) => {
                      const params = new URLSearchParams(prevParams);
                      if (!received) {
                        params.set("context", "received");
                      } else {
                        params.set("context", "sent");
                      }
                      return params;
                    });
                  }}
                />
                <Chip
                  icon={<FilterListIcon />}
                  label="Sent"
                  color="primary"
                  variant={sent ? "filled" : "outlined"}
                  onClick={() => {
                    if (sent) return;
                    setSent((prevValue) => !prevValue);
                    setSearchParams((prevParams) => {
                      const params = new URLSearchParams(prevParams);
                      if (!sent) {
                        params.set("context", "sent");
                      } else {
                        params.set("context", "receieved");
                      }
                      return params;
                    });
                  }}
                />
              </FilterButtons>
            )}
          </LowerTopToolbarContainer>
        </TableTopToolbarContainer>
      );
    },
    getRowId: (row) =>
      row.individualId?.toString() ?? Guid.generate().toString(),
    initialState: {
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    manualPagination: true,
    manualSorting: true,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => handleViewProfile(row.original.individualId),
      sx: { cursor: "pointer" },
    }),
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
      },
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%",
      },
    },
    muiToolbarAlertBannerProps: wasErrorLoading
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    // rowCount,
    state: {
      isLoading,
      showAlertBanner: wasErrorLoading,
      showProgressBars: isRefetching,
    },
  });

  return <MaterialReactTable table={table} />;
}
