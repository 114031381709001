import AddBoxIcon from "@mui/icons-material/AddBox";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Chip, Icon, ListItemIcon, ListItemText, MenuItem, Tab, Tabs, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ConfirmResponse,
  useConfirmDialog,
} from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import CreatorLink from "app/routes/proposals/creator-link";
import { PageTab } from "app/routes/proposals/tabs";
import { CanceledError } from "axios";
import SignalRConnection, {
  HubName,
  ProposalSignalType,
  SignalName,
} from "common/helpers/signalr-connection";
import { AccountType } from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import {
  createMRTColumnHelper,
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable,
} from "material-react-table";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Params, useLoaderData, useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useSession } from "users/session/session-context";
import ProposalAPIService, {
  ProposalContextParam,
} from "work/entities/proposal/api/proposal-api-service";
import ProposalDraftDialog from "work/entities/proposal/draft/view/proposal-draft-dialog";
import ProposalRequestDraftDialog from "work/entities/proposal/draft/view/proposal-request-draft-dialog";
import Proposal from "work/entities/proposal/proposal";
import {
  default as ProposalRedline,
  default as ProposalRedlineDialog,
  ProposalTab,
} from "work/entities/proposal/redlining/view/proposal-redline-dialog";
import ProposalViewDialog from "work/entities/proposal/view/proposal-view-dialog";
import {
  HumanReadableProposalStatus,
  ProposalStatus
} from "work/values/constants";
import FeeScheduleLink from "work/values/fee-schedule/view/fee-schedule-link";
import SelectedRepresentative from "work/values/selected-representative/selected-representative";
import SelectedTeam from "work/values/selected-team/selected-team";
import TeamLink from "work/values/team/view/team-link";

const ToolbarButtonsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "end",
  flexDirection: "column",
  gap: theme.spacing(1),
}));
const ToolbarButtons = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "end",
  flexWrap: "nowrap"
}));
const TableTopToolbarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  widtgh: "100%",
}));
const LowerTopToolbarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
}));
const TableTitle = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));
const FilterButtons = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    marginLeft: theme.spacing(1)
  },
  "& > .MuiChip-root": {
    marginLeft: theme.spacing(1)
  }
}));
const AlertActions = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  gap: theme.spacing(1),
  flexWrap: "nowrap",
  height: '100%',
  paddingRight: theme.spacing(2),
  whiteSpace: "nowrap"
}));
const RowTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.1em",
  fontWeight: "600",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

type ProposalProps = {};

export default function Proposals(_props: Readonly<ProposalProps>) {
  const columnHelper = createMRTColumnHelper<Proposal>();

  const draftIsDirtyRef = React.useRef<boolean>(false);
  const redlineIsDirtyRef = React.useRef<boolean>(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [errorLoading, setErrorLoading] = React.useState(false);
  const [data, setData] = React.useState<Proposal[]>([]);
  const [rowCount, setRowCount] = React.useState(0);
  const [activeTab, setActiveTab] = React.useState(PageTab.Active);
  const [rowSelection, setRowSelection] = React.useState<Record<string, boolean>>({});

  const [inbox, setInbox] = React.useState(false);
  const [outbox, setOutbox] = React.useState(false);
  const [pendingReview, setPendingReview] = React.useState(false);

  const routeParams = useLoaderData() as Params<string>;
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const session = useSession();
  const confirm = useConfirmDialog();
  const { openDialog, closeAllDialogs } = useDialog();

  // Close any open dialogs when the URL changes
  useEffect(() => {
    closeAllDialogs();
  }, [location]);

  // Handle URL changes
  useEffect(() => {
    let abortController = new AbortController();
    let forceReload = false;

    if (routeParams.pageTab) {
      forceReload = true;
      setActiveTab(routeParams.pageTab as PageTab);
    } else {
      navigate(`/proposals/${PageTab.Active}`);
    }

    if (routeParams.action === "create") {
      handleCreateProposal();
      return;
    }
    if (routeParams.action === "request") {
      handleRequestProposal();
      return;
    }
    if (routeParams.id) {
      const pageTab = routeParams.pageTab as PageTab;
      const action = routeParams.action as string;
      const tab = ProposalTab[routeParams.tab as keyof typeof ProposalTab];
      let actionMethod: (proposal: Proposal, tab?: ProposalTab) => void;

      if (pageTab === PageTab.Drafts && action === "edit") {
        actionMethod = handleEditProposal;
      }
      if (pageTab === PageTab.Drafts && action === "review") {
        actionMethod = handleReviewDraftProposal;
      } else if (pageTab === PageTab.Active && action === "revise") {
        actionMethod = handleReviseProposal;
      } else if (pageTab === PageTab.Active && action === "review") {
        actionMethod = handleReviewProposal;
      } else if (pageTab === PageTab.Active && action === "view") {
        actionMethod = handleViewProposal;
      } else if (pageTab === PageTab.Archived) {
        actionMethod = handleViewProposal;
      }

      getProposal(new Guid(routeParams.id), abortController)
        .then((proposal?: Proposal) => {
          if (!proposal) return;
          actionMethod(proposal, tab);
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar("Unable to load proposal", { variant: "error" });
        });
    }

    let context = searchParams.get("context");
    setInbox(context === "inbox");
    setOutbox(context === "outbox");
    setPendingReview(context?.includes("review") ?? false);

    fetchData(
      routeParams.pageTab as PageTab,
      forceReload,
      abortController
    );

    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, [routeParams, searchParams]);

  const fetchData = async (
    tab: PageTab,
    forceReload?: boolean,
    abortController?: AbortController
  ) => {
    if (!data.length || forceReload) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      const contextParam = searchParams.get('context');
      let context: ProposalContextParam | null = null;

      if (contextParam && contextParam !== "") {
        context = contextParam as ProposalContextParam;
      }
      if (!context && (tab ?? activeTab) === PageTab.Active) context = "allactive";
      else if (!context && (tab ?? activeTab) === PageTab.Drafts) context = "draft";
      else if (!context && (tab ?? activeTab) === PageTab.Archived) context = "archive";

      const proposalService = new ProposalAPIService(session);
      const proposals = await proposalService.getProposals({
        entityId: session.entities[0].entityId,
        viewingAs: session.context?.viewingAsVendor
          ? AccountType.Vendor
          : AccountType.Client,
        context: context ?? "allactive",
      },
        abortController
      );

      setData(proposals);
      setRowCount(proposals.length);
    } catch (error) {
      if (error instanceof CanceledError) return;
      setErrorLoading(true);
      console.error(error);
      return;
    }

    setErrorLoading(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  async function getProposal(id: Guid, abortController: AbortController) {
    try {
      setIsLoading(true);
      const proposalService = new ProposalAPIService(session);
      return await proposalService.getProposalById(id, abortController);
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
    } finally {
      if (!abortController.signal.aborted) {
        setIsLoading(false);
      }
    }
  }

  function handleRequestProposal() {
    const preSelectedRep =
      SelectedRepresentative.fromURLSearchParams(searchParams);
    const preSelectedTeam = SelectedTeam.fromURLSearchParams(searchParams);
    const parentPath = new URL(
      ".",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        title: `Request Proposal from ${preSelectedRep?.name ?? preSelectedTeam?.targetTeamName ?? "Vendor"
          }`,
        component: (
          <ProposalRequestDraftDialog
            onProposalSubmitted={(submittedProposal: Proposal) => {
              closeAllDialogs();
              navigate(`/proposals/active/revise/${submittedProposal.id}`, {
                replace: true,
              });
            }}
            onCancel={() => {
              closeAllDialogs();
              navigate(parentPath.pathname);
            }}
            preSelectedRep={preSelectedRep}
            preSelectedTeam={preSelectedTeam}
          />
        ),
      },
      () => navigate(parentPath.pathname),
      () => draftIsDirtyRef.current
    );
  }

  function handleCreateProposal() {
    let url: string;
    const preSelectedRep =
      SelectedRepresentative.fromURLSearchParams(searchParams);
    const preSelectedTeam = SelectedTeam.fromURLSearchParams(searchParams);
    const parentPath = new URL(
      ".",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        title: "Create Proposal",
        MuiProps: { fullWidth: false, maxWidth: "lg" },
        contentSxProps: { pb: { xs: 0 } },
        titleStyle: { paddingBottom: 0 },
        component: (
          <ProposalDraftDialog
            onProposalSaved={() => {
              url = "/proposals/drafts";
            }}
            onProposalSubmitted={(submittedProposal: Proposal) => {
              closeAllDialogs();
              navigate("/proposals/active", { replace: true });
            }}
            onDirtyChange={(isDirty) => (draftIsDirtyRef.current = isDirty)}
            onRefreshStaleProposalDialog={(revisedProposal: Proposal) => {
              closeAllDialogs();
              handleEditProposal(revisedProposal);
            }}
            preSelectedRep={preSelectedRep}
            preSelectedTeam={preSelectedTeam}
          />
        ),
      },
      () => navigate(url ?? parentPath.pathname),
      () => draftIsDirtyRef.current
    );
  }

  function handleEditProposal(proposal: Proposal, tab?: ProposalTab) {
    if (!proposal.id) return;

    let url: string;
    const parentPath = new URL(
      "..",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        title: "Edit Proposal",
        MuiProps: { fullWidth: false, maxWidth: "lg" },
        contentSxProps: { pb: { xs: 0 } },
        titleStyle: { paddingBottom: 0 },
        component: (
          <ProposalDraftDialog
            tab={tab}
            proposal={proposal}
            onProposalSaved={() => {
              url = "/proposals/drafts";
            }}
            onProposalSubmitted={(submittedProposal: Proposal) => {
              closeAllDialogs();
              navigate("/proposals/active", { replace: true });
            }}
            onDirtyChange={(isDirty) => (draftIsDirtyRef.current = isDirty)}
            onRefreshStaleProposalDialog={(revisedProposal: Proposal) => {
              closeAllDialogs();
              handleEditProposal(revisedProposal, tab);
            }}
          />
        ),
      },
      () => navigate(url ?? parentPath.pathname),
      () => draftIsDirtyRef.current
    );
  }

  function handleReviewDraftProposal(proposal: Proposal, tab?: ProposalTab) {
    if (!proposal.id) return;

    const reviewerCanEdit =
      (proposal.clientReviewers.find((r) =>
        r.userId.isEqualTo(session.user?.id)
      )?.canEdit ??
        false) ||
      (proposal.vendorReviewers.find((r) =>
        r.userId.isEqualTo(session.user?.id)
      )?.canEdit ??
        false);
    const parentPath = new URL(
      "..",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        title: "Review Proposal",
        MuiProps: { fullWidth: false, maxWidth: "lg" },
        contentSxProps: { pb: { xs: 0 } },
        titleStyle: { paddingBottom: 0 },
        component: (
          <ProposalDraftDialog
            tab={tab}
            proposal={proposal}
            isReviewing={
              session.context?.viewingAsVendor
                ? proposal.vendorReviewers.length > 0
                : proposal.clientReviewers.length > 0
            }
            disableEditing={!reviewerCanEdit}
            onDirtyChange={(isDirty) => (draftIsDirtyRef.current = isDirty)}
            onRefreshStaleProposalDialog={(revisedProposal: Proposal) => {
              closeAllDialogs();
              handleReviewDraftProposal(revisedProposal, tab);
            }}
          />
        ),
      },
      () => navigate(parentPath.pathname),
      () => draftIsDirtyRef.current
    );
  }

  function handleReviewProposal(proposal: Proposal, tab?: ProposalTab) {
    if (!proposal.id) return;

    const reviewerCanEdit =
      (proposal.clientReviewers.find((r) =>
        r.userId.isEqualTo(session.user?.id)
      )?.canEdit ??
        false) ||
      (proposal.vendorReviewers.find((r) =>
        r.userId.isEqualTo(session.user?.id)
      )?.canEdit ??
        false);
    const parentPath = new URL(
      "..",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        title: "Review Proposal",
        MuiProps: { fullWidth: false, maxWidth: "lg" },
        contentSxProps: { pb: { xs: 0 } },
        titleStyle: { paddingBottom: 0 },
        component: (
          <ProposalRedlineDialog
            proposalId={proposal.id}
            isReviewing={
              session.context?.viewingAsVendor
                ? proposal.vendorReviewers.length > 0
                : proposal.clientReviewers.length > 0
            }
            disableEditing={!reviewerCanEdit}
            onDirtyChange={(isDirty) => (redlineIsDirtyRef.current = isDirty)}
            onRevisedProposalReceived={(revisedProposal: Proposal) => {
              closeAllDialogs();
              handleReviewProposal(revisedProposal, tab);
            }}
          />
        ),
      },
      () => navigate(parentPath.pathname),
      () => redlineIsDirtyRef.current
    );
  }

  async function handleSubmitProposal(proposal: Proposal) {
    if (!proposal.id)
      return Promise.reject(new Error("Proposal ID is required"));

    const response = await confirm({
      title: "Submit Proposal",
      message: "Are you use you want to submit this proposal?",
      okButtonText: "Submit",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setIsLoading(true);
      await proposal.submit(session.user?.id);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleApproveProposal(proposal: Proposal) {
    if (!proposal.id)
      return Promise.reject(new Error("Proposal ID is required"));

    const response = await confirm({
      title: "Approve Proposal",
      message: "Are you use you want to approve this proposal?",
      okButtonText: "Approve",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setIsLoading(true);
      await proposal.approve(session.user?.id);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleRejectProposal(proposal: Proposal) {
    if (!proposal.id)
      return Promise.reject(new Error("Proposal ID is required"));

    const response = await confirm({
      title: "Reject Proposal",
      message: "Are you use you want to reject this proposal?",
      okButtonText: "Reject",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setIsLoading(true);
      await proposal.reject(session.user?.id);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleHireProposal(proposal: Proposal) {
    if (!proposal.id)
      return Promise.reject(new Error("Proposal ID is required"));

    const response = await confirm({
      title: "Hire Proposal",
      message: "Are you use you want to hire this proposal?",
      okButtonText: "Hire",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setIsLoading(true);
      await proposal.hire(session.user?.id);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDeleteProposal(proposal: Proposal) {
    if (!proposal.id)
      return Promise.reject(new Error("Proposal ID is required"));

    const response = await confirm({
      title: "Delete Proposal",
      message: "Are you use you want to delete this proposal?",
      okButtonText: "Delete",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setIsLoading(true);
      await proposal.delete(session.user?.id);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCancelProposal(proposal: Proposal) {
    if (!proposal.id)
      return Promise.reject(new Error("Proposal ID is required"));

    const response = await confirm({
      title: "Cancel Proposal",
      message: "Are you use you want to cancel this proposal?",
      okButtonText: "Ok",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setIsLoading(true);
      await proposal.cancel(session.user?.id);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function handleViewProposal(proposal: Proposal, tab?: ProposalTab) {
    if (!proposal.id) return;

    const parentPath = new URL(
      "..",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        title: proposal.name.value,
        titleStyle: { paddingBottom: 0 },
        MuiProps: { fullWidth: false, maxWidth: "xl" },
        contentSxProps: { pb: { xs: 0 } },
        component: <ProposalViewDialog tab={tab} proposalId={proposal.id} />,
      },
      () => navigate(parentPath.pathname)
    );
  }

  function handleReviseProposal(proposal: Proposal, tab?: ProposalTab) {
    if (!proposal.id) return;

    const parentPath = new URL(
      "..",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        title: "Review/Revise Proposal",
        titleStyle: { paddingBottom: 0 },
        MuiProps: { fullWidth: false, maxWidth: "xl" },
        contentSxProps: { pb: { xs: 0 } },
        component: (
          <ProposalRedline
            proposalId={proposal.id}
            onDirtyChange={(isDirty) => (redlineIsDirtyRef.current = isDirty)}
            onRevisedProposalReceived={(revisedProposal: Proposal) => {
              closeAllDialogs();
              handleReviseProposal(revisedProposal, tab);
            }}
          />
        ),
      },
      () => navigate(parentPath.pathname),
      () => redlineIsDirtyRef.current
    );
  }

  async function handleBulkDraftProposalDelete() {
    const selectedRowIds: Guid[] = Object.keys(rowSelection)
      .filter(id => rowSelection[id] === true)
      .map(id => new Guid(id));

    const response = await confirm({
      title: "Delete Proposals?",
      message: `Are you sure you want to delete ${selectedRowIds.length} proposal(s)?`,
      okButtonText: "Delete",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      setIsLoading(true);

      const proposalAPIService = new ProposalAPIService(session);
      await proposalAPIService.removeDraftProposals(selectedRowIds);

      setRowSelection({});
      fetchData(PageTab.Drafts);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleProposalNotificationCreated(proposal: Proposal) {
    // Drafting proposals generates many SignalR messages as it is updated,
    // and results in a lot of refresh calls if not ignored
    if (!proposal || proposal.status === ProposalStatus.AwaitingSubmission)
      return;

    fetchData(routeParams.pageTab as PageTab);
  }

  function renderStatusColumn(proposal: Proposal): string {
    const userId = session.user?.id;
    let proposalStatus: string = ProposalStatus[proposal.status].toString();
    let displayStatus: string =
      HumanReadableProposalStatus[
      proposalStatus as keyof typeof ProposalStatus
      ];
    const isClient = proposal.client?.userId.isEqualTo(userId);
    const isTeamLeader = proposal.team?.leader.userId.isEqualTo(userId);
    const isVendor = proposal.vendors?.some((vendor) =>
      vendor.userId.isEqualTo(userId)
    );

    if (
      (proposal.status === ProposalStatus.AwaitingApprovalByClient &&
        isClient) ||
      (proposal.status === ProposalStatus.AwaitingApprovalByTeamLeader &&
        isTeamLeader) ||
      (proposal.status === ProposalStatus.AwaitingApprovalByVendors && isVendor)
    )
      displayStatus = "Awaiting Your Approval";

    return displayStatus;
  }

  function renderCreatorColumn(proposal: Proposal) {
    if (!proposal.creatorInfo) return null;
    if (proposal.creatorInfo.userId.isEqualTo(session.user?.id)) {
      return "You";
    }
    return <CreatorLink creator={proposal.creatorInfo} />;
  }

  function renderTeamColumn(proposal: Proposal) {
    if (!proposal.team) return <Icon>remove</Icon>;
    return <TeamLink proposal={proposal} session={session} />;
  }

  function renderFeeScheduleColumn(proposal: Proposal) {
    if (!proposal.feeSchedule) return <Icon>remove</Icon>;
    return <FeeScheduleLink proposal={proposal} />;
  }

  const columns = [
    columnHelper.accessor(renderStatusColumn, {
      header: "Status",
      id: "status",
      filterVariant: 'select',
      filterSelectOptions: Object.entries(HumanReadableProposalStatus).map(([key, value]) => ({ value: key, label: value })),
    }),
    columnHelper.accessor((proposal) => proposal.name.value, {
      header: "Name",
      id: "name",
      Cell: ({ row }) => (
        <RowTitle>{row.original.name.value}</RowTitle>
      ),
    }),
    columnHelper.accessor((proposal) => proposal.description.value, {
      header: "Description",
      id: "description",
    }),
    columnHelper.accessor(renderCreatorColumn, {
      header: "Creator",
      id: "creator",
    }),
    columnHelper.accessor((proposal) => proposal.negotiable, {
      header: "Negotiable",
      id: "negotiable",
      size: 50,
      Cell: ({ cell }) =>
        cell.getValue<boolean>() ? <CheckIcon /> : <CloseIcon />,
    }),
    columnHelper.accessor(renderTeamColumn, {
      header: "Team",
      id: "team",
      size: 50,
    }),
    columnHelper.accessor(renderFeeScheduleColumn, {
      header: "Fee Schedule",
      id: "feeSchedule",
      size: 50,
    }),
    columnHelper.accessor((proposal) => proposal.responseDueBy?.format('MM/DD/YYYY') ?? <Icon>remove</Icon>, {
      header: "Response Due By",
      id: "responseDueBy",
      size: 50,
    }),
    columnHelper.accessor((proposal) => proposal.startDate?.format('MM/DD/YYYY') ?? <Icon>remove</Icon>, {
      header: "Start Date",
      id: "startDate",
      size: 50,
    }),
    columnHelper.accessor((proposal) => proposal.endDate?.format('MM/DD/YYYY') ?? <Icon>remove</Icon>, {
      header: "End Date",
      id: "endDate",
      size: 50,
    }),
  ];

  const getMenuItems = (proposal: Proposal): React.ReactNode[] => {
    const menuItems: React.ReactNode[] = [];

    if (
      !proposal.availableActions?.Edit.some(id => id.isEqualTo(session.user?.id)) &&
      !proposal.availableActions?.Review.some(id => id.isEqualTo(session.user?.id)) &&
      !proposal.availableActions?.Revise.some(id => id.isEqualTo(session.user?.id))
    ) {
      menuItems.push(
        <MenuItem key="view" onClick={() => navigate(`/proposals/${activeTab}/view/${proposal.id}`)}>
          <ListItemIcon>
            <Icon>visibility</Icon>
          </ListItemIcon>
          <ListItemText primary="View" />
        </MenuItem>
      );
    }

    if (proposal.availableActions?.Edit.some(id => id.isEqualTo(session.user?.id))) {
      menuItems.push(
        <MenuItem key="edit" onClick={() => navigate(`/proposals/drafts/edit/${proposal.id}`)}>
          <ListItemIcon>
            <Icon>edit</Icon>
          </ListItemIcon>
          <ListItemText primary="Review" />
        </MenuItem>
      );
    }

    if (proposal.availableActions?.Review.some(id => id.isEqualTo(session.user?.id))) {
      menuItems.push(
        <MenuItem key="review" onClick={() => navigate(`/proposals/${activeTab}/review/${proposal.id}`)}>
          <ListItemIcon>
            <Icon>edit</Icon>
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
      );
    }

    if (proposal.availableActions?.Submit.some(id => id.isEqualTo(session.user?.id))) {
      menuItems.push(
        <MenuItem key="submit" onClick={() => handleSubmitProposal(proposal)}>
          <ListItemIcon>
            <Icon>send</Icon>
          </ListItemIcon>
          <ListItemText primary="Submit" />
        </MenuItem>
      );
    }

    if (proposal.availableActions?.Revise.some(id => id.isEqualTo(session.user?.id))) {
      menuItems.push(
        <MenuItem key="revise" onClick={() => navigate(`/proposals/active/revise/${proposal.id}`)}>
          <ListItemIcon>
            <Icon>edit</Icon>
          </ListItemIcon>
          <ListItemText primary="Revise" />
        </MenuItem>
      );
    }

    if (proposal.availableActions?.Approve.some(id => id.isEqualTo(session.user?.id))) {
      menuItems.push(
        <MenuItem key="approve" onClick={() => handleApproveProposal(proposal)}>
          <ListItemIcon>
            <Icon color="success">check</Icon>
          </ListItemIcon>
          <ListItemText primary="Approve" />
        </MenuItem>
      );
    }

    if (proposal.availableActions?.Reject.some(id => id.isEqualTo(session.user?.id))) {
      menuItems.push(
        <MenuItem key="reject" onClick={() => handleRejectProposal(proposal)}>
          <ListItemIcon>
            <Icon color="error">close</Icon>
          </ListItemIcon>
          <ListItemText primary="Reject" />
        </MenuItem>
      );
    }

    if (
      activeTab !== PageTab.Archived &&
      proposal.availableActions?.Hire?.some(id => id.isEqualTo(session.user?.id))
    ) {
      menuItems.push(
        <MenuItem key="hire" onClick={() => handleHireProposal(proposal)}>
          <ListItemIcon>
            <Icon>work</Icon>
          </ListItemIcon>
          <ListItemText primary="Hire" />
        </MenuItem>
      );
    }

    if (proposal.availableActions?.Cancel.some(id => id.isEqualTo(session.user?.id))) {
      menuItems.push(
        <MenuItem key="cancel" onClick={() => handleCancelProposal(proposal)}>
          <ListItemIcon>
            <Icon color="error">cancel</Icon>
          </ListItemIcon>
          <ListItemText primary="Cancel Negotiation" />
        </MenuItem>
      );
    }

    if (proposal.availableActions?.Delete.some(id => id.isEqualTo(session.user?.id))) {
      menuItems.push(
        <MenuItem key="delete" onClick={() => handleDeleteProposal(proposal)}>
          <ListItemIcon>
            <DeleteIcon color="error" />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      );
    }

    return menuItems;
  };

  const openProposal = (proposal: Proposal) => {
    if (!proposal.id) {
      console.error("Proposal ID is required");
      return;
    }

    const editable = proposal.availableActions.Edit.some((userId) =>
      userId.isEqualTo(session.user?.id)
    );
    if (editable) {
      navigate(`/proposals/drafts/edit/${proposal.id}`);
      return;
    }

    const revisable = proposal.availableActions.Revise.some((userId) =>
      userId.isEqualTo(session.user?.id)
    );
    if (revisable) {
      navigate(`/proposals/active/revise/${proposal.id}`);
      return;
    }

    const reviewable = proposal.availableActions.Review.some((userId) =>
      userId.isEqualTo(session.user?.id)
    );
    if (reviewable) {
      navigate(`/proposals/${activeTab}/review/${proposal.id}`);
      return;
    }

    navigate(`/proposals/${activeTab}/view/${proposal.id}`);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: activeTab === PageTab.Drafts,
    enableRowActions: true,
    enableTableHead: true,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => openProposal(row.original),
      sx: { cursor: "pointer" },
    }),
    renderToolbarInternalActions: ({ table }) => (
      <ToolbarButtonsContainer>
        <ToolbarButtons>
          <MRT_ToggleGlobalFilterButton table={table} />
          <MRT_ToggleFiltersButton table={table} />
          <MRT_ShowHideColumnsButton table={table} />
          <MRT_ToggleDensePaddingButton table={table} />
          <MRT_ToggleFullScreenButton table={table} />
        </ToolbarButtons>
        <Button
          startIcon={<AddBoxIcon />}
          onClick={() => navigate("/proposals/drafts/create")}>
          Create Proposal
        </Button>
      </ToolbarButtonsContainer>
    ),
    renderTopToolbarCustomActions: () => {
      return (
        <TableTopToolbarContainer>
          <TableTitle variant="h4">
            Proposals
          </TableTitle>
          <LowerTopToolbarContainer>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_event, newValue) => navigate(`/proposals/${newValue}`)}
            >
              <Tab value={PageTab.Active} label="Active" />
              <Tab value={PageTab.Drafts} label="Drafts" />
              <Tab value={PageTab.Archived} label="Archived" />
            </Tabs>
            <FilterButtons>
              {activeTab === PageTab.Active && (
                <>
                  <Chip
                    icon={<FilterListIcon />}
                    label="Inbox"
                    color="primary"
                    variant={inbox ? 'filled' : 'outlined'}
                    onClick={() => {
                      setInbox(!inbox);
                      setOutbox(false);
                      setPendingReview(false);
                      setSearchParams((prevParams) => {
                        const params = new URLSearchParams(prevParams);
                        if (!inbox) {
                          params.set('context', 'inbox');
                        } else {
                          params.delete('context');
                        }
                        return params;
                      });
                    }}
                  />
                  <Chip
                    icon={<FilterListIcon />}
                    label="Outbox"
                    color="primary"
                    variant={outbox ? 'filled' : 'outlined'}
                    onClick={() => {
                      setOutbox(!outbox);
                      setInbox(false);
                      setPendingReview(false);
                      setSearchParams((prevParams) => {
                        const params = new URLSearchParams(prevParams);
                        if (!outbox) {
                          params.set('context', 'outbox');
                        } else {
                          params.delete('context');
                        }
                        return params;
                      });
                    }}
                  />
                  <Chip
                    icon={<FilterListIcon />}
                    label="Pending Your Review"
                    color="primary"
                    variant={pendingReview ? 'filled' : 'outlined'}
                    onClick={() => {
                      setPendingReview(!pendingReview);
                      setInbox(false);
                      setOutbox(false);
                      setSearchParams((prevParams) => {
                        const params = new URLSearchParams(prevParams);
                        if (!pendingReview) {
                          params.set('context', 'activereview');
                        } else {
                          params.delete('context');
                        }
                        return params;
                      });
                    }}
                  />
                </>
              )}
              {activeTab === PageTab.Drafts && (
                <Chip
                  icon={<FilterListIcon />}
                  label="Pending Your Review"
                  color="primary"
                  variant={pendingReview ? 'filled' : 'outlined'}
                  onClick={() => {
                    setPendingReview(!pendingReview);
                    setInbox(false);
                    setOutbox(false);
                    setSearchParams((prevParams) => {
                      const params = new URLSearchParams(prevParams);
                      if (!pendingReview) {
                        params.set('context', 'draftreview');
                      } else {
                        params.delete('context');
                      }
                      return params;
                    });
                  }}
                />
              )}
            </FilterButtons>
          </LowerTopToolbarContainer>
        </TableTopToolbarContainer>
      );
    },
    getRowId: (row) => row.id?.toString() ?? Guid.generate().toString(),
    renderRowActionMenuItems: ({ row }) => getMenuItems(row.original),
    enableColumnPinning: true,
    initialState: {
      columnPinning: { right: ['mrt-row-actions'] }
    },
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    manualPagination: true,
    manualSorting: true,
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
      },
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%",
      },
    },
    muiToolbarAlertBannerProps: errorLoading ? {
      color: "error",
      children: "Error loading data",
    } : {
      action: (
        <AlertActions>
          <Button
            color="primary"
            startIcon={<DeleteIcon />}
            onClick={handleBulkDraftProposalDelete}
          >
            Delete
          </Button>
        </AlertActions>
      )
    },
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    rowCount,
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      // pagination,
      showAlertBanner: errorLoading,
      showProgressBars: isRefetching,
      // sorting,
      rowSelection
    },
  });

  return (
    <>
      <SignalRConnection
        hubName={HubName.Proposals}
        signals={
          [
            {
              signal: SignalName.ProposalCreated,
              handler: handleProposalNotificationCreated,
            },
            {
              signal: SignalName.ProposalUpdated,
              handler: () => fetchData(routeParams.pageTab as PageTab),
            },
            {
              signal: SignalName.ProposalDeleted,
              handler: () => fetchData(routeParams.pageTab as PageTab),
            },
          ] as ProposalSignalType[]
        }
      />
      <MaterialReactTable table={table} />
    </>
  );
}
