import { HttpTransportType, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import * as Constants from 'common/helpers/constants';
import Message from 'messaging/entities/message/message';
import MessageNotification from 'notifications/entities/message-notification/message-notification';
import { useEffect } from "react";
import Session from 'users/session/session';
import { useSession } from 'users/session/session-context';
import Proposal from 'work/entities/proposal/proposal';

export enum HubName {
  Notifications = 'notificationHub',
  Messaging = 'messageHub',
  Proposals = 'proposalHub'
}

export enum SignalName {
  NotificationReceived = 'notification-received',
  NotificationUpdated = 'notification-updated',
  NotificationDeleted = 'notification-deleted',
  MessageReceived = 'message-received',
  MessageUpdated = 'message-updated',
  MessageDeleted = 'message-deleted',
  ProposalCreated = 'proposal-created',
  ProposalUpdated = 'proposal-updated',
  ProposalDeleted = 'proposal-deleted'
}

export type SignalType = {
  signal: SignalName,
  handler: (notification: any) => Promise<void>
}

export interface NotificationSignalType extends SignalType {
  signal: SignalName.NotificationReceived | SignalName.NotificationUpdated | SignalName.NotificationDeleted,
  handler: (notification: Notification | MessageNotification) => Promise<void>
}

export interface MessagingSignalType extends SignalType {
  signal: SignalName.MessageReceived | SignalName.MessageUpdated | SignalName.MessageDeleted,
  handler: (message: Message) => Promise<void>
}

export interface ProposalSignalType extends SignalType {
  signal: SignalName.ProposalCreated | SignalName.ProposalUpdated | SignalName.ProposalDeleted,
  handler: (proposal: Proposal) => Promise<void>
}

type SignalRConnectionProps = {
  hubName: HubName | string;
  signals?: NotificationSignalType[]
  | MessagingSignalType[]
  | ProposalSignalType[];
  onSignalReceived?: (signal: string, ...args: any[]) => void;
}

export default function SignalRConnection(props: Readonly<SignalRConnectionProps>) {
  const session = useSession();

  const signalRConnection = new HubConnectionBuilder()
    .withUrl(
      `${Constants.apiBaseUrl}/${props?.hubName}`,
      {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => Session.loadFromStorage(() => {}).authToken?.value ?? ''
      }
    )
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Information)
    .build();

  signalRConnection.onclose(error => {
    console.error('SignalR connection closed. Reason: ', error);
    startSignalR();
  });

  signalRConnection.onreconnecting(error => {
    console.error('Re-establishing connection to signalR. Reason: ', error);
  });

  signalRConnection.onreconnected(connectionId => {
    console.debug(`SignalR connection reestablished. Connected with connectionId "${connectionId}".`);
  });

  for (let signal of props?.signals ?? []) {
    signalRConnection.on(signal.signal, (notification) => {
      signal.handler(notification);
    });
  }

  async function startSignalR() {
    if (signalRConnection.state !== HubConnectionState.Disconnected) return;

    try {
      await signalRConnection?.start();
    } catch (err: any) {
      if (!err.message.includes('Failed to start the HttpConnection before stop() was called')) {
        console.error('Failed to start signalR connection. Reason: ', err);
        setTimeout(startSignalR, 5000);
      }
    }
  }

  useEffect(() => {
    if (!session.isLoggedIn) return;

    startSignalR();

    return () => {
      if (signalRConnection.state !== HubConnectionState.Connected) return;
      signalRConnection.stop();
    };
  }, []);

  return null;
}
