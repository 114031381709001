
import { JsonPatchOperation } from "common/services/api/attorney-hub-api-service";
import _ from "lodash";
import VendorRepresentativeAPIRequest from "work/entities/entity-vendor-representative/api/request-contracts/vendor-representative-api-request";
import EntityVendorRepresentative from "work/entities/entity-vendor-representative/entity-vendor-representative";
import TeamTemplate from "work/entities/team-template/team-template";
import TeamName from "work/values/team-name/team-name";
import Team from "work/values/team/team";


export default class UpdateWorkTeamTemplateAPIRequest {
    payload: JsonPatchOperation[] = [];

    constructor(originalTemplate: TeamTemplate, updatedTeam: Team, updatedTeamName: TeamName){
        if(originalTemplate.name !== updatedTeamName){
            this.payload.push({
                op: "replace",
                path: "/name",
                value: updatedTeamName.value
            });
        }
        if(originalTemplate.leader.userId?.isEqualTo(updatedTeam.leader.userId) && updatedTeam.leader.entityId){
            const vendorRep = new EntityVendorRepresentative(updatedTeam.leader.userId, updatedTeam.leader.entityId)
            this.payload.push({
                op: "replace",
                path: "/leader",
                value: new VendorRepresentativeAPIRequest(vendorRep)
            });
        }
        if(!_.isEqual(originalTemplate.memberUserIds, updatedTeam.memberUserIds)){
            this.payload.push({
                op: "replace",
                path: "/memberUserIds",
                value: updatedTeam.memberUserIds.map(userId => userId.value)
            });
        }
        if(originalTemplate.marketplaceId?.isEqualTo(updatedTeam.marketplaceId)){
            this.payload.push({
                op: "replace",
                path: "/marketplaceId",
                value: updatedTeam.marketplaceId?.value
            });
        }
    }
}
