import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CloseIcon from '@mui/icons-material/Close';
import {
  Chip, CircularProgress, Grow, IconButton, styled, Tab, Tabs, Tooltip, Typography
} from '@mui/material';
import { useDialog } from 'app/providers/dialog';
import { CanceledError } from 'axios';
import * as Constants from 'common/helpers/constants';
import SignalRConnection, { HubName, MessagingSignalType, SignalName } from 'common/helpers/signalr-connection';
import _ from 'lodash';
import IndividualAPIService from 'marketplace/entities/individual/api/individual-api-service';
import Individual from 'marketplace/entities/individual/individual';
import MessagingAPIService from 'messaging/api/messaging-api-service';
import AddSubscriber from 'messaging/entities/forum-invitations/view/add-subscriber';
import Forum from 'messaging/entities/forum/forum';
import Subscribers from 'messaging/entities/forum/view/components/subscribers';
import MessageAPIResponse from 'messaging/entities/message/api/response-contracts/message-api-response';
import Message from 'messaging/entities/message/message';
import MessageEditor from 'messaging/entities/message/view/components/editor/message-editor';
import ReceivedMessage from 'messaging/entities/message/view/components/received-message';
import SentMessage from 'messaging/entities/message/view/components/sent-message';
import NotificationsAPIService from 'notifications/services/notifications-api-service';
import { enqueueSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSession } from 'users/session/session-context';

const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
  color: '#999',
  marginRight: theme.spacing(1)
}));
const OuterContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  height: '100%'
}));
const ForumTabs = styled(Tabs)(({ theme }) => ({
  gridRowStart: 1,
  '& .MuiTabs-flexContainer': {
    borderBottom: `1px solid #aaa`,
    height: '100%'
  }
}));
const ForumTab = styled(Tab)(({ theme }) => ({
  backgroundColor: '#f8f9fd',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  flex: 1,
  '&.Mui-selected': {
    border: `1px solid #aaa`,
    bottom: '-1px',
    borderBottom: 'none'
  }
}));
const TabLabelContainer = styled('div')(({ theme }) => ({
  fontSize: '0.85em',
  maxWidth: '95%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}));
const ForumTabLabel = styled('span')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
}));
const MessagePane = styled('div')(({ theme }) => ({
  containerType: 'inline-size',
  flex: 1,
  margin: theme.spacing(1, 0),
  overflowY: 'auto',
  position: 'relative',
  scrollbarGutter: 'stable',
  width: '100%'
}));
const MessageContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#f8f9fd',
  border: '1px solid #aaa',
  borderTop: 'none',
  flex: 1,
  flexDirection: 'column',
  display: 'inline-grid',
  gridRowStart: 2,
  height: '100%',
  minHeight: '350px',
  paddingLeft: theme.spacing(2),
  width: '100%'
}));
const EmptyMessage = styled(Typography)(({ theme }) => ({
  alignContent: 'center',
  alignItems: 'center',
  color: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  fontSize: '1.33em',
  height: '100%',
  justifyContent: 'center',
  textAlign: 'center',
  width: '100%'
}));
const BackToTopChipContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  height: 0,
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
  zIndex: 1
}));
const BackToTopChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  bottom: '20px',
  boxShadow: '0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px 0 rgb(0 0 0 / 20%)',
  color: 'white',
  position: 'absolute',
  '&:hover': {
    backgroundColor: theme.palette.primary.main
  }
}));
const EditorContainer = styled('div')(({ theme }) => ({
  gridRowStart: 3,
  width: '100%'
}));

export type UserAvatarMap = {
  [userId: string]: string,
};

type ChatWindowProps = {
  className?: string,
  forums: Forum[],
  autoFocus?: boolean,
  locked?: boolean,
  lockedMessage?: string,
  onClose?: () => void,
  onLeaveConversation?: () => void
}

export default function ChatWindow(props: Readonly<ChatWindowProps>) {
  const {
    className,
    forums,
    autoFocus,
    locked,
    lockedMessage,
    onClose,
    onLeaveConversation
  } = props;
  if(!forums?.length) throw new Error('No forums provided to ChatWindow');

  const bottomRef = useRef<HTMLDivElement>(null);
  const messagePaneRef = useRef<HTMLDivElement>(null);

  const [activeForum, setActiveForum] = useState<Forum>(forums[0]);
  const [activeForumMessages, setActiveForumMessages] = useState<Message[]>([]);
  const [activeForumSubscribers, setActiveForumSubscribers] = useState<Individual[]>([]);
  const [activeForumAvatars, setActiveForumAvatars] = useState<UserAvatarMap>({});

  const [loading, setLoading] = useState(false);
  const [backToTopVisible, setBackToTopVisible] = useState(false);
  const { openDialog, closeAllDialogs } = useDialog();

  const session = useSession();

  const handleWindowScrolled = (_event: any) => {
    if (!messagePaneRef?.current) return;
    const scrollPercent = Math.round(
      messagePaneRef?.current.scrollTop /
      (messagePaneRef?.current.scrollHeight - messagePaneRef?.current.clientHeight) * 100.0
    );
    setBackToTopVisible(scrollPercent < 99);
  }

  const throttledScrollEventHandler = _.throttle(
    handleWindowScrolled,
    Constants.backToTopThrottleDelay
  );

  useEffect(() => {
    let abortController = new AbortController();
    if (forums) {    
      setActiveForum(forums[0]);
      updateChat(forums[0], abortController);
    }
    window.setTimeout(() => {
      messagePaneRef?.current?.addEventListener('scroll', throttledScrollEventHandler);
    }, 1000);
    return () => {
      abortController.abort();
      abortController = new AbortController();
    }
  }, []);

  useEffect(() => {
    let abortController = new AbortController();
    updateChat(forums[0], abortController);
    return () => {
      abortController.abort();
      abortController = new AbortController();
    }
  }, [forums]);

  useEffect(() => {
    let abortController = new AbortController();
    updateChat(activeForum, abortController);
    return () => {
      abortController.abort();
      abortController = new AbortController();
    }
  }, [activeForum])

  async function updateChat(activeForum: Forum, abortController: AbortController) {
    setLoading(true);
    setActiveForum(activeForum);
    await loadSubscribers(abortController);
    await loadMessages(abortController);

    setLoading(false);
    scrollToBottom();
  }

  const loadMessages = async (abortController: AbortController) => {
    if (!activeForum)
      return;

    try {
      const messages = await new MessagingAPIService(session)
        .getMessagesByForum(activeForum, abortController);
      setActiveForumMessages(messages);

      await new NotificationsAPIService(session).markNotificationGroupAsSeen({
        topicCategory: "Messaging.Message",
        topicIds: messages.filter(message => !!message.id).map(message => message.id?.toString() ?? '')
      });
    } catch (err: any) {
      if (err instanceof CanceledError) return;
      console.error(err);
      enqueueSnackbar('Failed to get messages', { variant: 'error' });
    }
  }

  const loadSubscribers = async (abortController: AbortController) => {
    if (!activeForum)
      return;

    try {
      let userAvatars: UserAvatarMap = {};
      let subscribers = await new MessagingAPIService(session)
        .getForumSubscriberInfo(activeForum);
      for (let subscriber of subscribers) {
        if (subscriber.profile?.avatarId && !userAvatars[subscriber.id.value]) {
          try {
            const service = new IndividualAPIService(session);
            const avatarData = await service.getIndividualAvatar(subscriber.id, abortController);
            if (avatarData) {
              userAvatars[subscriber.id.value] = avatarData;
            }
          } catch (err: any) {
            if (err instanceof CanceledError) return;
            console.warn(err);
          }
        }
      }

      setActiveForumSubscribers(subscribers);
      setActiveForumAvatars(userAvatars);
    } catch (err: any) {
      if (err instanceof CanceledError) return;
      console.error(err);
      enqueueSnackbar('Failed to get subscribers', { variant: 'error' });
    }
  }

  async function handleMessageReceived(newMessage: Message) {
    const deserializedMessage = Object.assign(new MessageAPIResponse(), newMessage).deserialize();

    if (deserializedMessage.senderId?.isEqualTo(session.user?.id)) return;
    if (!activeForum || deserializedMessage?.forum?.id?.value !== activeForum.id?.value) return;
    if (activeForumMessages.some(m => m.id?.isEqualTo(deserializedMessage.id))) return;

    setActiveForumMessages(prevMessages => [...prevMessages, deserializedMessage]);
    scrollToBottom();
  }

  async function handleMessageUpdated(updatedMessage: Message) {
    console.warn("A message was updated but there's no handler for it yet");
  }

  async function handleMessageDeleted(deletedMessage: Message) {
    if (!activeForumMessages)
      return;

    const messages = [...activeForumMessages];

    const targetMessage = messages.find((message: Message) => {
      return message.id?.isEqualTo(deletedMessage.id);
    });

    if (!targetMessage)
      return;

    targetMessage.content = "<i>Message Deleted By Sender</i>";

    setActiveForumMessages(messages);
  }

  const scrollToBottom = () => {
    window.setTimeout(() => {
      bottomRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: "nearest",
        inline: "nearest"
      });
    }, 500);
  }

  const handleSentMessage = async (newMessage: Message) => {
    if (activeForumMessages.some(m => m.id?.isEqualTo(newMessage.id))) return;

    setActiveForumMessages(prevMessages => [...prevMessages, newMessage]);
    scrollToBottom();
  }

  function handleAddSubscriberPressed(): void {
    const component = (
      <AddSubscriber
        forum={activeForum}
        onClosed={closeAllDialogs}
      />);
    openDialog({ component: component, title: 'Add People to Chat' });
  }

  function handleLeaveConversationPressed(): void {
    onLeaveConversation && onLeaveConversation();
    closeAllDialogs();
  }

  return (
    <React.Fragment>
      <SignalRConnection
        hubName={HubName.Messaging}
        signals={[
          { signal: SignalName.MessageReceived, handler: handleMessageReceived },
          { signal: SignalName.MessageUpdated, handler: handleMessageUpdated },
          { signal: SignalName.MessageDeleted, handler: handleMessageDeleted }
        ] as MessagingSignalType[]}
      />
      <OuterContainer className={className}>
        {activeForum && (
          <ForumTabs
            value={activeForum?.id?.toString()}
            TabIndicatorProps={{
              style: {
                backgroundColor: 'transparent'
              }
            }}
            textColor="primary">
            {forums?.map((forum: Forum) => (
              forum?.subscriberIds?.length > 1 && (
                <ForumTab
                  key={forum.id?.toString()}
                  value={forum.id?.toString()}
                  onClick={() => setActiveForum(forum)}
                  style={{ maxWidth: `calc((100%/${forums.length}) - 48px)` }}
                  label={
                    <TabLabelContainer>
                      {onClose && (
                        <ForumTabLabel>
                          {forum.name ?? 'Messages'}
                          <Tooltip title='Close Chat'>
                            <span>
                              <IconButton
                                size='small'
                                onClick={() => onClose()}>
                                <CloseIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </ForumTabLabel>
                      )}
                      {!onClose && (forum.name ?? 'Messages')}
                    </TabLabelContainer>
                  }
                />
              )
            ))}
            <Subscribers
              subscribers={activeForumSubscribers}
              forum={activeForum}
              avatars={activeForumAvatars}
              onAddSubscriber={handleAddSubscriberPressed}
              onLeaveConversation={handleLeaveConversationPressed}
            />
          </ForumTabs>
        )}
        <MessageContainer>
          <MessagePane ref={messagePaneRef}>
            {loading && (
              <EmptyMessage>
                <LoadingIndicator size={25} /> Loading...
              </EmptyMessage>
            )}
            {!loading && (
              <>
                {activeForumMessages.length < 1 && (
                  <EmptyMessage>
                    {locked ?
                      lockedMessage ?? 'Chat was locked by the moderator' :
                      'No Messages'}
                  </EmptyMessage>
                )}
                {!locked && activeForumMessages.map((message: Message) => {
                  if (session.user && message.userIsSender(session.user)) {
                    return (
                      <SentMessage
                        session={session}
                        key={message.id?.toString()}
                        message={message}
                      />
                    )
                  } else return (
                    <ReceivedMessage
                      session={session}
                      key={message.id?.toString()}
                      sender={
                        activeForumSubscribers.find((subscriber: Individual) => {
                          return subscriber.userId?.isEqualTo(message.senderId);
                        })
                      }
                      avatars={activeForumAvatars}
                      message={message}
                    />
                  )
                }
                )}
                {locked &&
                  <EmptyMessage>
                    {lockedMessage ?? 'Chat was locked by the moderator'}
                  </EmptyMessage>
                }
              </>
            )}
            <div ref={bottomRef} />
          </MessagePane>
          {!loading && (
            <BackToTopChipContainer>
              <Grow in={backToTopVisible}>
                <BackToTopChip
                  label="Jump to bottom"
                  icon={<ArrowDownwardIcon style={{ color: 'white' }} />}
                  onClick={() => bottomRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: "nearest",
                    inline: "nearest"
                  })}
                />
              </Grow>
            </BackToTopChipContainer>
          )}
        </MessageContainer>
        <EditorContainer>
          <MessageEditor
            forum={activeForum}
            autoFocus={autoFocus}
            onFormattingToggled={scrollToBottom}
            onAttachmentAdded={scrollToBottom}
            onMessageSent={handleSentMessage}
            locked={locked}
          />
        </EditorContainer>
      </OuterContainer>
    </React.Fragment>
  )
}
