import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from "@microsoft/signalr";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";
import InfoIcon from "@mui/icons-material/Info";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RateReviewIcon from "@mui/icons-material/RateReview";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import UndoIcon from "@mui/icons-material/Undo";
import WorkIcon from "@mui/icons-material/Work";
import {
  Badge,
  IconButton,
  Portal,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import {
  ConfirmResponse,
  useConfirmDialog,
} from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import { CanceledError } from "axios";
import Loader from "common/components/loader";
import LoadingButton from "common/components/loading-button";
import * as Constants from "common/helpers/constants";
import { HubName, SignalName } from "common/helpers/signalr-connection";
import { JsonPatchOperation } from "common/services/api/attorney-hub-api-service";
import AHBoolean from "common/values/boolean/boolean";
import Date from "common/values/date/date";
import Guid from "common/values/guid/guid";
import Percent from "common/values/percent/percent";
import _ from "lodash";
import Individual from "marketplace/entities/individual/individual";
import ViewIndividualProfile from "marketplace/values/individual-profile/view/view-individual-profile";
import MessagingAPIService from "messaging/api/messaging-api-service";
import Forum from "messaging/entities/forum/forum";
import MessageAPIResponse from "messaging/entities/message/api/response-contracts/message-api-response";
import Message from "messaging/entities/message/message";
import Topic from "messaging/values/topic";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import reactElementToJSXString from "react-element-to-jsx-string";
import Session from "users/session/session";
import { useSession } from "users/session/session-context";
import ProposalAPIService from "work/entities/proposal/api/proposal-api-service";
import ProposalReviewerAPIRequest from "work/entities/proposal/api/request-contracts/proposal-reviewer-api-request";
import Proposal, {
  FreelyPatchableFields,
  ProposalField,
} from "work/entities/proposal/proposal";
import {
  Audience,
  TopicContext,
} from "work/entities/proposal/proposal-forum-topic-context";
import { FeeScheduleCategoryRedline } from "work/entities/proposal/redlining/fee-schedule-redline/fee-schedule-redline";
import FieldRedline from "work/entities/proposal/redlining/field-redline";
import ProposalRedline from "work/entities/proposal/redlining/proposal-redline";
import { RedlineAction, RedlineChange } from "work/entities/proposal/redlining/redline-change";
import ChangeTraverser from "work/entities/proposal/redlining/view/change-traverser";
import ConflictsTab from "work/entities/proposal/redlining/view/tabs/conflicts-tab";
import DetailsTab from "work/entities/proposal/redlining/view/tabs/details-tab";
import DiscountTab from "work/entities/proposal/redlining/view/tabs/discount-tab";
import FeeScheduleTab from "work/entities/proposal/redlining/view/tabs/fee-schedule-tab";
import PoliciesTab from "work/entities/proposal/redlining/view/tabs/policies-tab";
import TeamTab from "work/entities/proposal/redlining/view/tabs/team-tab";
import {
  ForumContext,
  getForumForField,
} from "work/entities/proposal/utils/comment-utils";
import Comments from "work/entities/proposal/view/comments/comments";
import {
  HumanReadableProposalFieldName,
  ProposalFieldName,
} from "work/values/constants";
import ProjectDescription from "work/values/project-description/project-description";
import ProjectName from "work/values/project-name/project-name";
import ProposalReviewer from "work/values/proposal-reviewer";
import WorkDocument from "work/values/work-document/work-document";
import ReviewerSelector from "work/view/components/reviewer-selector";

const Header = styled("section")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingBottom: theme.spacing(1),
  position: "sticky",
  top: "0px",
  zIndex: 10,
}));
const TabsContainer = styled(Tabs)(({ theme }) => ({
  "&.MuiTabs-root": {
    overflow: "hidden",
  },
}));
const Content = styled("section")(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "row",
}));
const TabContent = styled("section")(({ theme }) => ({
  alignItems: "stretch",
  flexDirection: "column",
  display: "flex",
  flex: 1,
  margin: theme.spacing(2, 0),
}));
const RequiredFieldText = styled(Typography)(({ theme }) => ({
  color: "#D00",
  marginBottom: theme.spacing(1),
}));
const ActionsContainer = styled("section")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  backgroundColor: theme.palette.background.default,
  bottom: "0px",
  display: "flex",
  flexDirection: "row",
  paddingBottom: theme.spacing(2.5),
  position: "sticky",
  width: "100%",
  zIndex: 10,
}));
const ProposalActions = styled("section")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 0,
    flexWrap: "nowrap",
  },
  alignContent: "center",
  alignItems: "center",
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(15rem, 1fr))",
  gap: theme.spacing(1),
  minHeight: "64px",
  width: "100%",
}));
const ChangeNavigationContainer = styled('section')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  },
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
  width: '100%'
}));
const NavTab = styled(Tab)(({ theme }) => ({
  minWidth: "fit-content",
  paddingLeft: 0,
}));
const ProposalActionButton = styled(LoadingButton)(({ theme }) => ({
  minWidth: theme.spacing(24),
  whiteSpace: "nowrap",
  width: "100%",
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.26) !important",
    backgroundColor: "rgba(0, 0, 0, 0.12) !important",
  },
}));
const DialogTab = styled(Tab)(({ theme }) => ({
  "&.MuiTab-root": {
    overflow: "visible",
  },
}));
const TabBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: "-8px",
    top: "-8px",
  },
}));
const MessageButtons = styled("section")(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
const ButtonContainer = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(2),
}));
const MessageButton = styled(IconButton)(({ theme }) => ({
  paddingBottom: 0,
}));
const MessageButtonLabel = styled(Typography)(({ theme }) => ({
  fontSize: "0.6em",
}));
const SidePanel = styled(Drawer)(({ theme }) => ({
  position: "fixed",
  zIndex: theme.zIndex.modal + 1,
}));
const SidePanelContainer = styled("div")(({ theme }) => ({
  flex: 1,
  height: "100%",
}));
const SidePanelContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  padding: theme.spacing(2),
  width: "400px",
}));
const TitleBar = styled("div")<{ view: "comments" | undefined }>(
  ({ theme, view }) => ({
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingBottom: theme.spacing(1),
  })
);

export enum ProposalTab {
  Details,
  Team,
  FeeSchedule,
  Conflicts,
  Policies,
  Discount,
}

enum ProposalPanel {
  Comments = "comments"
}

type ProposalRedlineProps = {
  proposalId: Guid;
  panel?: ProposalPanel;
  disableEditing?: boolean;
  isReviewing?: boolean;
  onDirtyChange?: (isDirty: boolean) => void;
  onRevisedProposalReceived?: (revisedProposal: Proposal) => void;
};

export default function ProposalRedlineDialog(
  props: Readonly<ProposalRedlineProps>
) {
  const {
    proposalId,
    isReviewing,
    onDirtyChange,
    onRevisedProposalReceived,
  } = props;

  const [disableEditing, setDisableEditing] = React.useState<boolean>(
    props.disableEditing ?? false
  );
  const [activeTab, setActiveTab] = React.useState<ProposalTab>(
    ProposalTab.Details
  );
  const [currentCommentField, setCurrentCommentField] = React.useState<
    ProposalFieldName | undefined
  >();
  const [currentCommentFieldId, setCurrentCommentFieldId] = React.useState<
    Guid | undefined
  >();
  const [sidePanelTitle, setSidePanelTitle] = React.useState<
    string | undefined
  >();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingComments, setIsLoadingComments] =
    React.useState<boolean>(false);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [isCommentSaving, setIsCommentSaving] = React.useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = React.useState<boolean>(false);
  const [isManagingReviewers, setIsManagingReviewers] =
    React.useState<boolean>(false);
  const [isApprovingReview, setIsApprovingReview] =
    React.useState<boolean>(false);
  const [isSidePanelOpen, setIsSidePanelOpen] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isFormValid, setIsFormValid] = React.useState<boolean>(true);
  const [canSubmitRevision, setCanSubmitRevision] =
    React.useState<boolean>(false);
  const [reviewerSelectorAnchor, setReviewerSelectorAnchor] =
    React.useState<HTMLButtonElement>();
  const [currentProposal, setCurrentProposal] = React.useState<Proposal>();
  const [freelyPatchableFields, setFreelyPatchableFields] =
    React.useState<FreelyPatchableFields>();
  const [activeReviewField, setActiveReviewField] =
    React.useState<ProposalField>();

  const [sidePanelView, setSidePanelView] = React.useState<
    ProposalPanel | undefined
  >();
  const [commentForums, setCommentForums] = React.useState<Forum[]>([]);
  const [currentComments, setCurrentComments] = React.useState<Message[]>([]);
  const [pendingComments, setPendingComments] = React.useState<Message[]>([]);
  const [currentCommenters, setCurrentCommenters] = React.useState<
    Individual[]
  >([]);

  const [isStale, setIsStale] = React.useState<boolean>(false);
  const [revisedProposal, setRevisedProposal] = React.useState<Proposal>();
  const [commentSignalRConn, setCommentSignalRConn] =
    React.useState<HubConnection>();
  const [proposalSignalRConn, setProposalSignalRConn] =
    React.useState<HubConnection>();

  const session = useSession();
  const { openDialog, closeAllDialogs } = useDialog();
  const confirm = useConfirmDialog();

  useEffect(() => {
    let abortController = new AbortController();

    initSignalRConnections();
    initProposal(abortController);

    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, []);

  useEffect(() => {
    const tabForActiveReviewField =
      getTabForActiveReviewField(activeReviewField);
    if (tabForActiveReviewField !== activeTab) {
      setActiveTab(tabForActiveReviewField ?? activeTab);
    }
  }, [activeReviewField]);

  useEffect(() => {
    setCommentSignalREventHandler(commentSignalRConn);
  }, [
    commentSignalRConn,
    currentCommentField,
    currentComments,
    commentForums,
    activeTab,
  ]);

  useEffect(() => {
    setProposalSignalREventHandler(proposalSignalRConn);
  }, [proposalSignalRConn, currentProposal]);

  function getTabForActiveReviewField(field?: ProposalField): ProposalTab | undefined {
    if (!field) return undefined;
    switch (field.name) {
      case ProposalFieldName.Name:
      case ProposalFieldName.Description:
      case ProposalFieldName.ResponseDueBy:        
      case ProposalFieldName.StartDate:
      case ProposalFieldName.EndDate:
        return ProposalTab.Details;

      case ProposalFieldName.TeamRestriction:
      case ProposalFieldName.Team:
        return ProposalTab.Team;

      case ProposalFieldName.FeeSchedule:
        return ProposalTab.FeeSchedule;

      case ProposalFieldName.WaiveConflictsCheck:
      case ProposalFieldName.Conflicts:
        return ProposalTab.Conflicts;

      case ProposalFieldName.ClientPolicies:
      case ProposalFieldName.VendorPolicies:
        return ProposalTab.Policies;

      case ProposalFieldName.Discount:
        return ProposalTab.Discount;
      default:
        return undefined;
    }
  }

  async function initSignalRConnections() {
    const newCommentsConnection = await createHubConnection(HubName.Messaging);
    setCommentSignalRConn(newCommentsConnection);
    const newProposalConnection = await createHubConnection(HubName.Proposals);
    setProposalSignalRConn(newProposalConnection);
  }

  async function createHubConnection(hubName: HubName): Promise<HubConnection> {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${Constants.apiBaseUrl}/${hubName}`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () =>
          Session.loadFromStorage(() => {}).authToken?.value ?? "",
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();

    if (
      newConnection &&
      newConnection.state === HubConnectionState.Disconnected
    ) {
      newConnection.start().catch((err) => {
        console.error("Failed to connect to SignalR: ", err);
      });
    }
    return newConnection;
  }

  function setCommentSignalREventHandler(connection?: HubConnection) {
    if (!connection) return;

    connection.off(SignalName.MessageReceived);
    connection.on(SignalName.MessageReceived, (message: any) => {
      handleCommentReceived(message);
    });
  }

  function setProposalSignalREventHandler(connection?: HubConnection) {
    if (!connection) return;

    connection.off(SignalName.ProposalCreated);
    connection.on(SignalName.ProposalCreated, (proposal: any) => {
      handleProposalReceived(proposal);
    });
    connection.off(SignalName.ProposalUpdated);
    connection.on(SignalName.ProposalUpdated, (proposal: any) => {
      handleProposalReceived(proposal);
    });
  }

  async function handleCommentReceived(newMessage: Message) {
    if (isLoadingComments) return;

    //the SignalR Hub event handler suggests Message but we're getting a MessageAPIResponse...
    const apiMessage = Object.assign(new MessageAPIResponse(), newMessage);
    if (apiMessage.senderId?.value === session.user?.id?.value) return;
    if (apiMessage.forum?.entityClass !== "Work.Proposal") return;
    if (apiMessage.forum?.entityId !== proposalId.value) return;

    const comment = apiMessage.deserialize();

    const forum = commentForums.find((forum) =>
      forum.topic?.isEqualTo(comment.forum.topic)
    );
    let updatedForums = [...commentForums];
    if (!forum) {
      updatedForums = [...commentForums, comment.forum];
      setCommentForums(updatedForums);
    }

    if (currentCommentField || currentCommentFieldId) {
      loadCommentsForField(
        currentCommentField,
        currentCommentFieldId,
        false,
        updatedForums
      );
    }
  }

  async function handleProposalReceived(newProposal: Proposal) {
    if (!newProposal.id) return;
    if (newProposal.id.value !== proposalId.value) return;

    setDisableEditing(true);

    if (newProposal.status?.toLowerCase().includes("rejected")) {
      setRevisedProposal(undefined);
    } else {
      const revision = await getRevisedProposal();
      setRevisedProposal(revision ?? currentProposal);
    }
    setIsStale(true);
    setIsFormDirty(false);
  }

  async function getRevisedProposal(): Promise<Proposal | undefined> {
    if (!currentProposal?.id) return;
    try {
      const proposalService = new ProposalAPIService(session);
      const revisions = await proposalService.getProposalRevisions(
        currentProposal.id
      );
      if (revisions.length === 0) return;
      if (revisions.some((revision) => !revision.createdDate)) {
        console.warn("Revision created date is missing");
        return;
      }
      revisions.sort((a, b) => {
        const createdDateA = a.createdDate?.value ?? moment();
        const createdDateB = b.createdDate?.value ?? moment();
        return createdDateA.diff(createdDateB);
      });
      return revisions[0];
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Error getting proposal revision", { variant: "error" });
    }
  }

  async function initProposal(abortController: AbortController) {
    try {
      setIsLoading(true);
      const proposalService = new ProposalAPIService(session);
      const proposalDetails = await proposalService.getProposalById(
        proposalId,
        abortController
      );

      setCurrentProposal(proposalDetails);
      let fields: FreelyPatchableFields | undefined;
      if (session.context?.viewingAsVendor) {
        fields = {
          reviewers: proposalDetails.vendorReviewers,
          feeScheduleTemplateIds: proposalDetails.vendorFeeScheduleTemplateIds,
          teamTemplateIds: proposalDetails.vendorTeamTemplateIds,
        };
      } else {
        fields = {
          reviewers: proposalDetails.clientReviewers,
          feeScheduleTemplateIds: proposalDetails.clientFeeScheduleTemplateIds,
          teamTemplateIds: proposalDetails.clientTeamTemplateIds,
        };
      }
      setFreelyPatchableFields(fields);
      setIsFormValid(true);
      setCanSubmitRevision(Boolean(proposalDetails?.redline?.isResolved));
      await initForums(abortController);

      setActiveReviewField(proposalDetails.redline?.fieldsPendingReview[0]);
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
    } finally {
      if (!abortController.signal.aborted) {
        setIsLoading(false);
      }
    }
  }

  async function initForums(abortController: AbortController) {
    try {
      const messageService = new MessagingAPIService(session);
      const returnedForums = await messageService.getForums(
        "Work.Proposal",
        proposalId,
        "any",
        abortController
      );
      const filteredCommentForums = returnedForums.filter((forum) => {
        try {
          if (!forum.topic?.context) return false;
          const contextJson: ForumContext = JSON.parse(forum.topic?.context);
          return contextJson.audience.includes("Review");
        } catch (error: any) {
          return false;
        }
      });

      setCommentForums(filteredCommentForums);
    } catch (error) {
      if (error instanceof CanceledError) {
        console.warn("Canceled api call");
        return;
      }
      console.error(error);
    }
  }

  async function saveRedlining() {
    if (!currentProposal) return;

    setIsSaving(true);
    let updatedProposal = await currentProposal.saveRedlining();
    setCurrentProposal(updatedProposal);
  }

  async function saveChangedFields(updatedFields?: FreelyPatchableFields) {
    if (!currentProposal?.id || !updatedFields) return;

    let payload: JsonPatchOperation[] = [];

    if (session.context?.viewingAsVendor) {
      if (
        !_.isEqual(currentProposal.vendorReviewers, updatedFields.reviewers)
      ) {
        payload.push({
          op: "replace",
          path: "/vendorReviewers",
          value: updatedFields.reviewers.map(
            (reviewer) => new ProposalReviewerAPIRequest(reviewer)
          ),
        });
      }
      if (
        !_.isEqual(
          currentProposal.vendorTeamTemplateIds,
          updatedFields.teamTemplateIds
        )
      ) {
        payload.push({
          op: "replace",
          path: "/vendorTeamTemplateIds",
          value: updatedFields.teamTemplateIds.map((id) => id.value),
        });
      }
      if (
        !_.isEqual(
          currentProposal.vendorFeeScheduleTemplateIds,
          updatedFields.feeScheduleTemplateIds
        )
      ) {
        payload.push({
          op: "replace",
          path: "/vendorFeeScheduleTemplateIds",
          value: updatedFields.feeScheduleTemplateIds.map((id) => id.value),
        });
      }
    } else {
      if (
        !_.isEqual(currentProposal.clientReviewers, updatedFields.reviewers)
      ) {
        payload.push({
          op: "replace",
          path: "/clientReviewers",
          value: updatedFields.reviewers.map(
            (reviewer) => new ProposalReviewerAPIRequest(reviewer)
          ),
        });
      }
      if (
        !_.isEqual(
          currentProposal.clientTeamTemplateIds,
          updatedFields.teamTemplateIds
        )
      ) {
        payload.push({
          op: "replace",
          path: "/clientTeamTemplateIds",
          value: updatedFields.teamTemplateIds.map((id) => id.value),
        });
      }
      if (
        !_.isEqual(
          currentProposal.clientFeeScheduleTemplateIds,
          updatedFields.feeScheduleTemplateIds
        )
      ) {
        payload.push({
          op: "replace",
          path: "/clientFeeScheduleTemplateIds",
          value: updatedFields.feeScheduleTemplateIds.map((id) => id.value),
        });
      }
    }

    const proposalApiService = new ProposalAPIService(session);
    let updatedProposal = await proposalApiService.patchProposal(
      currentProposal.id,
      payload
    );
    setCurrentProposal(updatedProposal);
  }

  function handleTabChange(
    _event: React.ChangeEvent<{}> | null,
    newTab: ProposalTab
  ) {
    setActiveTab(newTab);
  }

  function handlePrevNextClicked(direction: "previous" | "next") {
    const targetTab = direction === "previous" ? activeTab - 1 : activeTab + 1;
    handleTabChange(null, targetTab ?? activeTab);
  }

  async function handleSaveClicked() {
    try {
      setIsSaving(true);
      await saveComments();
      if (!disableEditing) {
        await saveRedlining();
        await saveChangedFields(freelyPatchableFields);
      }
      onDirtyChange?.(false);
      setIsFormDirty(false);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Failed to save proposal", { variant: "error" });
    } finally {
      setIsSaving(false);
    }
  }

  function handleManageReviewersClicked(
    event: React.MouseEvent<HTMLButtonElement>
  ) {
    setReviewerSelectorAnchor(event.currentTarget);
    setIsManagingReviewers(true);
    setIsManagingReviewers(false);
  }

  async function handleSubmitClicked() {
    if (!currentProposal) return;

    try {
      setIsSubmitting(true);

      if (currentProposal.redline?.revisionsAccepted) {
        const approveResponse = await confirm({
          title: "Approve Proposal?",
          message:
            "Doing so will approve the proposal and send it to any other parties for their approval.",
          okButtonText: "Approve",
        });

        if (approveResponse === ConfirmResponse.Cancel) return;

        await currentProposal.approve(session.user?.id);
      } else {
        const submitResponse = await confirm({
          title: "Send Requested Changes?",
          message:
            "Doing so will send all your requested changes for review by the other party.",
          okButtonText: "Send",
        });

        if (submitResponse === ConfirmResponse.Cancel) return;

        await saveComments();
        await currentProposal.requestRevision(session);
      }

      closeAllDialogs();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(`${error}`, { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  }

  async function handleAcceptClicked() {
    if (!currentProposal) return;

    const acceptResponse = await confirm({
      title: "Accept All Changes?",
      message: "This will mark all changes as accepted.",
      okButtonText: "Accept",
    });

    if (acceptResponse === ConfirmResponse.Cancel) return;

    const updatedRedline = currentProposal.redline?.clone();
    if (!updatedRedline) return;
    updatedRedline.accept();
    handleProposalRedlineChanged(updatedRedline);
  }

  async function handleRejectClicked() {
    if (!currentProposal) return;

    const rejectResponse = await confirm({
      title: "Reject All Changes?",
      message: "This will mark all changes as rejected.",
      okButtonText: "Reject",
    });

    if (rejectResponse === ConfirmResponse.Cancel) return;

    const updatedRedline = currentProposal.redline?.clone();
    if (!updatedRedline) return;
    updatedRedline.reject();
    handleProposalRedlineChanged(updatedRedline);
  }

  async function handleEndNegotiationClicked() {
    if (!currentProposal) return;

    try {
      const endResponse = await confirm({
        title: "End Negotiation?",
        message: "Doing so will end proposal negotations.",
        okButtonText: "End Negotiation",
      });

      if (endResponse === ConfirmResponse.Cancel) return;

      setIsSubmitting(true);

      await currentProposal.reject(session.user?.id);

      closeAllDialogs();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Failed to reject proposal", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  }

  async function handleCancelClicked() {
    if (!currentProposal) return;

    try {
      const rejectResponse = await confirm({
        title: "Cancel Proposal?",
        message: "Doing so will end proposal negotations.",
      });

      if (rejectResponse === ConfirmResponse.Cancel) return;

      setIsSubmitting(true);

      await currentProposal.cancel(session.user?.id);

      closeAllDialogs();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Failed to cancel proposal", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  }

  async function handleHireClicked() {
    if (!currentProposal) return;

    try {
      const hireResponse = await confirm({
        title: "Hire?",
        message: "Doing so will hire the selected vendor.",
        okButtonText: "Hire",
      });

      if (hireResponse === ConfirmResponse.Cancel) return;

      setIsSubmitting(true);

      await currentProposal.hire(session.user?.id);

      closeAllDialogs();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Failed to hire vendor", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  }

  async function handleUndoChangesClicked() {
    if (!currentProposal) return;

    const undoResponse = await confirm({
      title: "Undo All?",
      message: "Doing so will revert all changes.",
      okButtonText: "Undo All",
    });

    if (undoResponse === ConfirmResponse.Cancel) return;

    const updatedProposal = currentProposal.redline?.clone();
    if (!updatedProposal) return;
    updatedProposal.undo();
    handleProposalRedlineChanged(updatedProposal);
  }

  function shouldDisableTabs(): boolean {
    return !isFormValid || isLoading || isSaving || isManagingReviewers;
  }

  function renderRedlineChange(redlineChange: RedlineChange) {
    const changes = redlineChange.textChanges;
    if (!changes || changes.length === 0) return;
    const resolved = redlineChange.isResolved;

    let nodes = [];
    if (!changes || changes.length === 0) return;

    for (let changeIndex = 0; changeIndex < changes.length; changeIndex++) {
      if (
        changes?.[changeIndex - 1]?.isModified &&
        changes[changeIndex].isModified
      ) {
        continue;
      }

      const value = changes[changeIndex]?.diff.value;
      const nextValue = changes[changeIndex + 1]?.diff.value;

      if (
        changes[changeIndex].isModified &&
        changes[changeIndex + 1]?.isModified
      ) {
        const node = (
          <span key={Guid.generate().value} className="change-group">
            <span className={`removed ${resolved ? "resolved" : ""}`}>
              {value}
            </span>
            <span className={`added ${resolved ? "resolved" : ""}`}>
              {nextValue}
            </span>
          </span>
        );
        nodes.push(node);
        continue;
      }

      let className = "unchanged";
      if (changes[changeIndex].isAdded) className = "added";
      if (changes[changeIndex].isRemoved) className = "removed";
      if (resolved) className += " resolved";
      const node = (
        <span key={Guid.generate().value} className={className}>
          {value}
        </span>
      );
      nodes.push(node);
    }

    return (
      <span key={Guid.generate().value} className="comment-redline">
        {nodes.map((node) => node)}
      </span>
    );
  }

  function handleProposalRedlineChanged(
    newProposalRedline: ProposalRedline,
    traversalFieldOverride?: ProposalField | null
  ) {
    if (!currentProposal?.redline) {
      throw new Error("Current proposal redline is missing");
    }
    const updatedProposal = currentProposal.updateRedline(newProposalRedline);

    setCurrentProposal(updatedProposal);
    generateChangeAutoComments(newProposalRedline);
    setIsFormDirty(true);
    setCanSubmitRevision(newProposalRedline?.isResolved ?? false);
    onDirtyChange?.(true);
    if(traversalFieldOverride === null){
      return;
    }
    const nextFieldToReview =
      traversalFieldOverride ?? newProposalRedline.nextFieldPendingReview;
    setActiveReviewField(nextFieldToReview);
  }

  function generateChangeAutoComments(newProposalRedline: ProposalRedline) {
    if (!currentProposal) return;

    let updatedPendingComments = [];
    let updatedCurrentComments = [...currentComments];
    updatedCurrentComments = updatedCurrentComments.filter((currentComment) => {
      return !pendingComments.some((pendingComment) => pendingComment.id?.value === currentComment.id?.value);
    });

    for (let redlineChange of newProposalRedline.sessionHistory ?? []) {
      const action = redlineChange.action;
      const field = redlineChange.field;
      const fieldId = redlineChange.fieldId;
      const isResolved = redlineChange.isResolved;

      if (!action) continue;
      if(isResolved && action === RedlineAction.UndoEdit) continue; 

      let fieldToUse = field;
      const redlineComment = renderRedlineChange(redlineChange);

      if (!redlineComment) {
        console.warn("could not generate auto-comment for field", {
          redline: newProposalRedline,
          action: action,
          field: fieldToUse,
          fieldId: fieldId,
        });
        continue;
      }

      const commentRedlineString = reactElementToJSXString(redlineComment, {
        tabStop: 0,
      });
      const cleanedCommentRedlineString = commentRedlineString
        .replaceAll("\n", " ")
        .replaceAll(/{'\s*'}/g, "");

      const commentText = `
        <span className="action ${action?.actionDescription.toLowerCase()}">${
        action?.actionDescription
      }</span>:
        ${cleanedCommentRedlineString}
      `;

      if (redlineComment) {
        const comment = postComment(commentText, false, fieldToUse, null);
        if (!comment) continue;
        if (currentCommentField === fieldToUse) {
          updatedCurrentComments.push(comment);
        }
        updatedPendingComments.push(comment);
      }
    }

    setPendingComments(updatedPendingComments);
    setCurrentComments(updatedCurrentComments);
  }

  function handleTeamTemplatesUpdated(templateIds: Guid[]) {
    if (!freelyPatchableFields) return;
    let updatedFields = { ...freelyPatchableFields };
    updatedFields.teamTemplateIds = templateIds;
    setFreelyPatchableFields(updatedFields);
    setIsFormDirty(true);
    onDirtyChange?.(true);
  }

  function handleFeeScheduleTemplatesUpdated(templateIds: Guid[]) {
    if (!freelyPatchableFields) return;
    let updatedFields = { ...freelyPatchableFields };
    updatedFields.feeScheduleTemplateIds = templateIds;
    setFreelyPatchableFields(updatedFields);
    setIsFormDirty(true);
    onDirtyChange?.(true);
  }

  function getTabLabel(tab: ProposalTab) {
    if (!currentProposal?.redline) return;
    let tabFields: Array<
      | FieldRedline<ProjectName>
      | FieldRedline<ProjectDescription>
      | FieldRedline<Date>
      | FieldRedline<Individual>
      | FeeScheduleCategoryRedline
      | FieldRedline<AHBoolean>
      | FieldRedline<WorkDocument>
      | FieldRedline<Percent>
      | undefined
    > = [];

    switch (tab) {
      case ProposalTab.Details:
        tabFields = [
          currentProposal.redline.name,
          currentProposal.redline.description,
          currentProposal.redline.responseDueBy,
          currentProposal.redline.startDate,
          currentProposal.redline.endDate,
        ];
        break;
      case ProposalTab.Team:
        tabFields = [...currentProposal.redline.team.redlines];
        break;
      case ProposalTab.FeeSchedule:
        tabFields = [...currentProposal.redline.feeSchedule.redlines];
        break;
      case ProposalTab.Conflicts:
        tabFields = [
          ...(currentProposal.redline.conflictsDocuments.redlines ?? []),
          currentProposal.redline.conflictsCheckWaived,
        ];
        break;
      case ProposalTab.Policies:
        tabFields = [
          ...(currentProposal?.redline?.clientPolicyDocuments.redlines ?? []),
          ...(currentProposal?.redline?.vendorPolicyDocuments.redlines ?? []),
        ];
        break;
      case ProposalTab.Discount:
        tabFields = [currentProposal?.redline?.discount];
        break;
      default:
        break;
    }

    const numUnresolved = tabFields.filter(
      (field) => !field?.isResolved
    ).length;
    let numDeferredFees = 0;
    if (tab === ProposalTab.FeeSchedule) {
      numDeferredFees =
        currentProposal?.redline?.feeSchedule.redlines.filter(
          (redline) => !redline.currentEntry?.fee && !redline.isNewlyAdded
        ).length ?? 0;
    }
    let unresolvedConflictsWaiver = false;
    if (tab === ProposalTab.Conflicts) {
      unresolvedConflictsWaiver =
        !currentProposal?.redline?.conflictsCheckWaived.currentEntry &&
        currentProposal?.redline?.conflictsDocuments.redlines.length === 0;
    }
    const tooltipTitle = (
      <>
        {numUnresolved > 0 && (
          <div>{`${numUnresolved} unresolved field${
            numUnresolved > 1 ? "s" : ""
          }`}</div>
        )}
        {numDeferredFees > 0 && (
          <div>{`${numDeferredFees} deferred fee${
            numDeferredFees > 1 ? "s" : ""
          }`}</div>
        )}
        {unresolvedConflictsWaiver && (
          <div>Must have conflicts document or waive conflicts check</div>
        )}
      </>
    );

    return (
      <TabBadge
        color="error"
        variant="standard"
        badgeContent={
          <Tooltip title={tooltipTitle} placement="bottom">
            <span>
              {Number(
                numUnresolved +
                  numDeferredFees +
                  (unresolvedConflictsWaiver ? 1 : 0)
              ).toString()}
            </span>
          </Tooltip>
        }
        invisible={
          isLoading ||
          (numUnresolved === 0 &&
            numDeferredFees === 0 &&
            !unresolvedConflictsWaiver)
        }
      >
        {ProposalTab[tab].toString()}
      </TabBadge>
    );
  }

  async function handleToggleSidePanel(
    panel?: ProposalPanel,
    field?: ProposalFieldName,
    fieldId?: Guid,
    sidePanelTitle?: string
  ) {
    setCurrentCommentField(field);
    setCurrentCommentFieldId(fieldId);
    setSidePanelTitle(sidePanelTitle);
    setSidePanelView(panel);

    if (!panel) {
      setIsSidePanelOpen(false);
      adjustDialogPosition(true);
      return;
    }

    if (
      isSidePanelOpen &&
      panel === sidePanelView &&
      field === currentCommentField &&
      ((!fieldId && !currentCommentFieldId) ||
        fieldId?.isEqualTo(currentCommentFieldId))
    ) {
      setIsSidePanelOpen(false);
      adjustDialogPosition(true);
      return;
    }

    setIsSidePanelOpen(true);
    adjustDialogPosition(false);
    await loadCommentsForField(field, fieldId);
  }

  async function loadCommentsForField(
    field?: ProposalFieldName,
    fieldId?: Guid,
    showLoader: boolean = true,
    forums?: Forum[]
  ) {
    setIsLoadingComments(showLoader);

    const matchingForums: Forum[] = [];

    // Get comments from forums that match the field and, optionally, fieldId
    for (const forum of forums ?? commentForums) {
      if (!forum.topic?.context) continue;

      try {
        const contextJSON: ForumContext = JSON.parse(forum.topic.context);

        if (
          contextJSON.field &&
          contextJSON.field.toLowerCase() === field?.toLowerCase() &&
          ((!fieldId && !contextJSON.fieldId) ||
            contextJSON.fieldId?.value === fieldId?.value)
        ) {
          matchingForums.push(forum);
        }
      } catch (error: any) {
        continue;
      }
    }

    const forumComments = await loadCommentsForForums(matchingForums);
    const forumCommenters = await loadCommenterInfoForForums(matchingForums);

    for (const pendingComment of pendingComments) {
      if (!pendingComment.forum.topic?.context) continue;

      try {
        const contextJSON: ForumContext = JSON.parse(
          pendingComment.forum.topic.context
        );

        if (
          contextJSON.field?.toLowerCase() === field?.toLowerCase() &&
          ((!fieldId && !contextJSON.fieldId) ||
            contextJSON.fieldId?.value === fieldId?.value)
        ) {
          forumComments.push(pendingComment);
        }
      } catch (error: any) {
        continue;
      }
    }

    setCurrentComments(forumComments);
    setCurrentCommenters(forumCommenters);
    setIsLoadingComments(false);
  }

  async function loadCommentsForForums(forums: Forum[]): Promise<Message[]> {
    const comments: Message[] = [];
    const messageService = new MessagingAPIService(session);
    for (const forum of forums) {
      try {
        comments.push(...(await messageService.getMessagesByForum(forum)));
      } catch (error) {
        console.error(error);
        continue;
      }
    }

    return comments.sort((a, b) => {
      if (!a.publishedOn && !b.publishedOn) return 0;
      if (!a.publishedOn) return -1;
      if (!b.publishedOn) return 1;
      return a.publishedOn.diff(b.publishedOn);
    });
  }

  async function loadCommenterInfoForForums(
    forums: Forum[]
  ): Promise<Individual[]> {
    const commenters: Individual[] = [];
    const messageService = new MessagingAPIService(session);
    for (const forum of forums) {
      try {
        commenters.push(
          ...(await messageService.getForumSubscriberInfo(forum))
        );
      } catch (error) {
        console.error(error);
        continue;
      }
    }

    return commenters;
  }

  function adjustDialogPosition(panelOpen: boolean) {
    for (const dialog of document.getElementsByClassName("MuiDialog-root")) {
      dialog.setAttribute(
        "style",
        `padding-right: ${
          panelOpen ? "0px" : "400px"
        }; transition: padding-right 225ms;`
      );
    }
  }

  function handleUserCommentPosted(
    commentText: string,
    isExternal: boolean,
    field?: ProposalFieldName,
    fieldId?: Guid
  ) {
    const newComment = postComment(commentText, isExternal, field, fieldId);
    if (!newComment) return;

    const updatedCurrentComments = [...currentComments];
    if (currentCommentField === field) updatedCurrentComments.push(newComment);
    setCurrentComments(updatedCurrentComments);

    const updatedPendingComments = [...pendingComments];
    updatedPendingComments.push(newComment);
    setPendingComments(updatedPendingComments);
  }

  function handleCommentEdited(
    messageId: Guid,
    editedText: string,
    isExternal: boolean,
    publishedOn?: moment.Moment
  ) {
    if (!currentProposal?.id || !session.user) return;

    const { audience, subscriberIds } = getCommentAudienceAndSubscriberIds(
      isExternal,
      currentProposal,
      session
    );
    const context = new TopicContext(
      audience,
      currentCommentField ?? ProposalFieldName.General,
      currentCommentFieldId
    );
    let forum = getCommentForumFromContext(context);

    if (!forum) {
      forum = createForum(currentProposal.id, context, subscriberIds);
      if (!forum) {
        console.error("Failed to create forum for edited comment");
        return;
      }
    }

    const editedComment = Message.draft(
      forum,
      session.user,
      editedText,
      undefined,
      undefined,
      messageId
    );
    editedComment.publishedOn = publishedOn ?? moment();
    editedComment.markedForEdit = true;

    // Add or replace the edited comment in the pending comments
    let updatedPendingComments = [...pendingComments];
    const existingCommentIndex = updatedPendingComments.findIndex((c) =>
      c.id?.isEqualTo(messageId)
    );
    if (existingCommentIndex >= 0) {
      updatedPendingComments[existingCommentIndex] = editedComment;
    } else {
      updatedPendingComments.push(editedComment);
    }
    setPendingComments(updatedPendingComments);

    //update the matching current comment with the edited comment
    let updatedCurrentComments = [...currentComments];
    const existingCurrentCommentIndex = updatedCurrentComments.findIndex((c) =>
      c.id?.isEqualTo(messageId)
    );
    if (existingCurrentCommentIndex >= 0) {
      updatedCurrentComments[existingCurrentCommentIndex] = editedComment;
      setCurrentComments(updatedCurrentComments);
    }

    setIsFormDirty(true);
    onDirtyChange?.(true);
  }

  async function handleCommentDeleted(messageId: Guid) {
    if (!currentProposal?.id || !session.user) return;

    let updatedPendingComments = [...pendingComments];
    let updatedCurrentComments = [...currentComments];

    const deletedCurrentIndex = updatedCurrentComments.findIndex((c) => c.id?.isEqualTo(messageId));
    if (deletedCurrentIndex < 0) return;

    const deletedComment = updatedCurrentComments[deletedCurrentIndex];

    if (deletedComment.isDeleted) {
      deletedComment.setUndeleted();
    } else {
      deletedComment.setDeleted();
    }

    const deletedPendingIndex = updatedPendingComments.findIndex((c) => c.id?.isEqualTo(messageId));
    if (deletedPendingIndex >= 0) {
      if (deletedComment.isDeleted) {
        updatedPendingComments[deletedPendingIndex] = deletedComment;
      } else if(!deletedComment.markedForCreation && !deletedComment.markedForEdit) {
        //remove the pending undone deleted comment because it wasn't edited or created during the session
        updatedPendingComments = updatedPendingComments.filter((c) => !c.id?.isEqualTo(messageId));
      }
    } else {
      //this is a new delete request, add to action to pending comments
      updatedPendingComments.push(deletedComment);
    }
    setPendingComments(updatedPendingComments);

    if (deletedCurrentIndex >= 0) {
      updatedCurrentComments[deletedCurrentIndex] = deletedComment;
      setCurrentComments(updatedCurrentComments);
    }
    
    setIsFormDirty(true);
    onDirtyChange?.(true);
  }

  async function handleCommentReadToggled(messageId: Guid) {
    if (!currentProposal?.id || !session.user) return;

    let updatedPendingComments = [...pendingComments];
    let updatedCurrentComments = [...currentComments];

    const commentCurrentIndex = updatedCurrentComments.findIndex((c) => c.id?.isEqualTo(messageId));
    if (commentCurrentIndex < 0) {
      console.error("Comment not found in current comments");
      return;
    }

    const comment = updatedCurrentComments[commentCurrentIndex];
    if(comment.senderId?.isEqualTo(session.user.id)) {
      console.warn("User cannot mark their own comment as read/unread");
      return;
    }

    if((!comment.markedForReadReceipt && !comment.isReadByUser(session.user?.id)) || comment.markedForUnread) {
      comment.setRead();
    } else {
      comment.setUnread();
    }

    updatedCurrentComments[commentCurrentIndex] = comment;
    setCurrentComments(updatedCurrentComments);

    const messagePendingIndex = pendingComments.findIndex((c) => c.id?.isEqualTo(messageId));
    if (messagePendingIndex >= 0) {
      updatedPendingComments = updatedPendingComments.filter((c) => !c.id?.isEqualTo(messageId));
    } else {
      updatedPendingComments.push(comment);
    }
    setPendingComments(updatedPendingComments);

    setIsFormDirty(true);
    onDirtyChange?.(true);
  }

  function postComment(
    commentText: string,
    isExternal: boolean,
    field?: ProposalFieldName | null,
    fieldId?: Guid | null
  ): Message | undefined {
    if (!currentProposal?.id || !session.user) return;

    const { audience, subscriberIds } = getCommentAudienceAndSubscriberIds(
      isExternal,
      currentProposal,
      session
    );
    const context = new TopicContext(
      audience,
      field ?? ProposalFieldName.General,
      fieldId ?? undefined
    );
    let forum = getCommentForumFromContext(context);

    if (!forum) {
      forum = createForum(currentProposal.id, context, subscriberIds);
      if (!forum) {
        console.error("Failed to create forum");
        return;
      }
    }

    const newComment = Message.draft(
      forum,
      session.user,
      commentText,
      undefined,
      undefined,
      Guid.generate()
    );
    newComment.publishedOn = moment();
    setIsFormDirty(true);
    onDirtyChange?.(true);

    return newComment;
  }

  async function saveComments(): Promise<void> {
    if (!session.user) return;

    try {
      setIsCommentSaving(true);
      const messageService = new MessagingAPIService(session);
      const response = await messageService.createBulkMessages(pendingComments);
      const numFailedComments = response.reduce(
        (acc, res) => acc + (res.issues.length > 0 ? 1 : 0),
        0
      );
      if (numFailedComments > 0) {
        enqueueSnackbar(`Failed to save ${numFailedComments} comment(s)`, {
          variant: "error",
        });
      }

      // Update the comments and forums with the newly-created ones
      let updatedCommentForums = commentForums.filter((f) => f.id);
      let updatedCurrentComments = [...currentComments];
      for (const comment of response) {
        const existingCommentForum = updatedCommentForums.find((f) =>
          f.topic?.isEqualTo(comment.forum.topic)
        );
        if (!existingCommentForum) {
          updatedCommentForums.push(comment.forum);
        }
        const currentCommentIndex = updatedCurrentComments.findIndex((c) =>
          c.id?.isEqualTo(comment.id)
        );
        if (currentCommentIndex >= 0) {
          updatedCurrentComments[currentCommentIndex] = comment;
        }
      }

      setCommentForums(updatedCommentForums);

      updatedCurrentComments = updatedCurrentComments.filter((c) => !c.markedForDeletion);    
      setCurrentComments(updatedCurrentComments);

      // Remove pending comments that were successfully saved and those locally deleted
      let updatedPendingComments = [...pendingComments.filter((c) => !c.markedForDeletion && !c.isDeleted)];
      for (const comment of pendingComments) {
        const responseComment = response.find((res) =>
          res.id?.isEqualTo(comment.id)
        );
        if (responseComment?.issues.length === 0) {
          updatedPendingComments = updatedPendingComments.filter(
            (c) => !c.id?.isEqualTo(comment.id)
          );
        }
      }
      setPendingComments(updatedPendingComments);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to save comments", { variant: "error" });
    } finally {
      setIsCommentSaving(false);
    }
  }

  function getCommentAudienceAndSubscriberIds(
    isExternal: boolean,
    proposal: Proposal,
    session: Session
  ): { audience: Audience; subscriberIds: Guid[] } {
    let audience: Audience;
    const subscriberIds: Guid[] = [];
    if (session.user?.id) subscriberIds.push(session.user.id);

    if (isExternal) {
      audience = Audience.AllReviewers;

      if (proposal.creator?.userId) subscriberIds.push(proposal.creator.userId);

      if (proposal.client?.userId) subscriberIds.push(proposal.client.userId);
      subscriberIds.push(
        ...proposal.clientReviewers.map((reviewer) => reviewer.userId)
      );

      if (proposal.team?.leader?.userId)
        subscriberIds.push(proposal.team.leader.userId);
      subscriberIds.push(
        ...proposal.vendorReviewers.map((reviewer) => reviewer.userId)
      );
    } else if (session.context?.viewingAsVendor) {
      audience = Audience.VendorReviewers;

      if (proposal.team?.leader?.userId)
        subscriberIds.push(proposal.team.leader.userId);
      subscriberIds.push(
        ...proposal.vendorReviewers.map((reviewer) => reviewer.userId)
      );
    } else {
      audience = Audience.ClientReviewers;

      if (proposal.client?.userId) subscriberIds.push(proposal.client.userId);
      subscriberIds.push(
        ...proposal.clientReviewers.map((reviewer) => reviewer.userId)
      );
    }
    return {
      audience,
      subscriberIds: _.uniqBy(subscriberIds, (id) => id.value),
    };
  }

  function getCommentForumFromContext(
    context: TopicContext
  ): Forum | undefined {
    for (const forum of commentForums) {
      if (!forum.topic?.context) continue;
      try {
        const forumContextJSON: any = JSON.parse(forum.topic.context);
        const forumAudience: Audience = forumContextJSON.audience;
        const forumFieldName: string = forumContextJSON.field;
        if (
          forumAudience === context.audience &&
          forumFieldName &&
          forumFieldName.toLowerCase() === context.field?.toLowerCase()
        ) {
          if (!context.fieldId) {
            return forum;
          }
          if (context.fieldId) {
            const forumFieldId: Guid = new Guid(forumContextJSON.fieldId);
            if (forumFieldId.isEqualTo(context.fieldId)) return forum;
          }
        }
      } catch (error) {
        continue;
      }
    }
  }

  function createForum(
    proposalId: Guid,
    context: TopicContext,
    subscriberIds: Guid[]
  ): Forum | undefined {
    if (!session.user) return;

    const topic = new Topic(
      "Work.Proposal",
      proposalId,
      JSON.stringify(context.toJSON())
    );
    return new Forum(
      `Proposal ${context.audience} ${context.field.toString()} comment thread`,
      topic,
      subscriberIds
    );
  }

  function handleViewCommenterProfile(individualId?: Guid) {
    if (!individualId) return;

    openDialog({
      component: (
        <ViewIndividualProfile
          individualId={individualId}
        />
      ),
      titleStyle: {
        position: "absolute",
        right: 0,
        top: 0,
      },
      contentSxProps: {
        display: "flex",
        overflowX: "hidden",
      },
      MuiProps: {
        maxWidth: "lg",
        fullWidth: true,
      },
    });
    setTimeout(() => adjustDialogPosition(false), 500);
  }

  async function handleApproveReviewClicked() {
    if (!currentProposal) throw new Error("No proposal to approve");

    try {
      const response = await confirm({
        title: "Approve Review",
        message: "Are you sure you want to approve this review?",
        okButtonText: "Approve",
      });

      if (response === ConfirmResponse.Cancel) return;

      setIsApprovingReview(true);
      const proposalService = new ProposalAPIService(session);
      await proposalService.giveReviewerApproval(currentProposal);
      closeAllDialogs();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Failed to approve review", { variant: "error" });
    } finally {
      setIsApprovingReview(false);
    }
  }

  async function handleProposalReviewersChanged(reviewers: ProposalReviewer[]) {
    if (!freelyPatchableFields) return;
    let updatedFields = { ...freelyPatchableFields };
    updatedFields.reviewers = reviewers;
    setFreelyPatchableFields(updatedFields);

    try {
      setIsSaving(true);
      await saveChangedFields(updatedFields);
    } catch (error) {
      console.error("Failed to save reviewers");
      enqueueSnackbar("Failed to save reviewers", { variant: "error" });
    } finally {
      setIsSaving(false);
    }
  }

  function hasUserApprovedReview(): boolean {
    let reviewers: ProposalReviewer[] = [];

    if (session.context?.viewingAsVendor) {
      reviewers = currentProposal?.vendorReviewers ?? [];
    } else {
      reviewers = currentProposal?.clientReviewers ?? [];
    }

    return reviewers.some(
      (reviewer) =>
        reviewer.userId.isEqualTo(session.user?.id) && reviewer.dateApproved
    );
  }

  function getSidePanelTitle(): string {
    if (sidePanelTitle) return `${sidePanelTitle} Comments`;
    return `${
      HumanReadableProposalFieldName[
        currentCommentField ?? ProposalFieldName.General
      ]
    } Comments`;
  }

  function handleProposalUpdatedClicked() {
    if (!currentProposal?.id) return;
    if (!revisedProposal?.id) {
      closeAllDialogs();
      return;
    }

    onRevisedProposalReceived?.(revisedProposal);
  }

  async function handleTeamMemberQuitProposal(memberId: Guid): Promise<void> {
    if (!currentProposal?.id) return;
    if (!memberId.isEqualTo(session.user?.id)) return;

    try {
      const service = new ProposalAPIService(session);
      await service.quitProposalTeam(currentProposal.id);

      const commentText = `${session.user?.fullName} has quit the proposal team`;
      const comment = postComment(commentText, true, ProposalFieldName.Team);
      if (!comment) throw new Error("Failed to post team quit comment");
      const updatedPendingComments = [...pendingComments];
      updatedPendingComments.push(comment);
      setPendingComments(updatedPendingComments);

      enqueueSnackbar("You have quit the proposal team", {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to quit proposal team", { variant: "error" });
    }
  }

  return (
    <>
      {isStale && (
        <ActionsContainer>
          <ProposalActionButton
            variant="contained"
            color={revisedProposal ? "info" : "error"}
            startIcon={<InfoIcon />}
            loading={false}
            onClick={handleProposalUpdatedClicked}
          >
            {`This proposal has been ${
              revisedProposal ? "updated" : "canceled"
            }, click to ${revisedProposal ? "refresh" : "exit"}`}
          </ProposalActionButton>
        </ActionsContainer>
      )}
      <Header>
        <TabsContainer
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          value={activeTab}
          onChange={handleTabChange}
        >
          <NavTab
            disabled={shouldDisableTabs() || activeTab === ProposalTab.Details}
            icon={<NavigateBeforeIcon fontSize="large" />}
            onClick={async () => handlePrevNextClicked("previous")}
          />
          <DialogTab
            value={ProposalTab.Details}
            disabled={shouldDisableTabs()}
            label={getTabLabel(ProposalTab.Details)}
          />
          <DialogTab
            value={ProposalTab.Team}
            disabled={shouldDisableTabs()}
            label={getTabLabel(ProposalTab.Team)}
          />
          <DialogTab
            value={ProposalTab.FeeSchedule}
            disabled={shouldDisableTabs()}
            label={getTabLabel(ProposalTab.FeeSchedule)}
          />
          <DialogTab
            value={ProposalTab.Conflicts}
            disabled={shouldDisableTabs()}
            label={getTabLabel(ProposalTab.Conflicts)}
          />
          <DialogTab
            value={ProposalTab.Policies}
            disabled={shouldDisableTabs()}
            label={getTabLabel(ProposalTab.Policies)}
          />
          <DialogTab
            value={ProposalTab.Discount}
            disabled={shouldDisableTabs()}
            label={getTabLabel(ProposalTab.Discount)}
          />
          <NavTab
            disabled={shouldDisableTabs() || activeTab === ProposalTab.Discount}
            icon={<NavigateNextIcon fontSize="large" />}
            onClick={async () => handlePrevNextClicked("next")}
          />
        </TabsContainer>
      </Header>
      <Content>
        <TabContent>
          {isLoading && <Loader />}
          {!isLoading &&
            currentProposal?.redline &&
            currentProposal.details?.client && (
              <>
                {!isFormValid && (
                  <RequiredFieldText>
                    Please fill out all required fields.
                  </RequiredFieldText>
                )}
                <DetailsTab
                  isOpen={activeTab === ProposalTab.Details}
                  proposalRedline={currentProposal.redline}
                  activeReviewField={activeReviewField}
                  commentForums={commentForums}
                  disableEditing={disableEditing}
                  onProposalRedlineChange={handleProposalRedlineChanged}
                  onTraverseToNewField={setActiveReviewField}
                  onCommentsClicked={(
                    field: ProposalFieldName,
                    fieldId?: Guid,
                    title?: string
                  ) => {
                    handleToggleSidePanel(
                      ProposalPanel.Comments,
                      field,
                      fieldId,
                      title
                    );
                  }}
                  clientName={currentProposal.details.client.getFullName()}
                />
                <TeamTab
                  isOpen={activeTab === ProposalTab.Team}
                  proposalRedline={currentProposal.redline}
                  activeReviewField={activeReviewField}
                  commentForums={commentForums}
                  disableEditing={disableEditing}
                  onProposalRedlineChange={handleProposalRedlineChanged}
                  onTraverseToNewField={setActiveReviewField}
                  onCommentsClicked={(
                    field: ProposalFieldName,
                    fieldId?: Guid,
                    title?: string
                  ) => {
                    handleToggleSidePanel(
                      ProposalPanel.Comments,
                      field,
                      fieldId,
                      title
                    );
                  }}
                  teamLeader={currentProposal.details?.team?.leader}
                  onTeamTemplatesUpdated={handleTeamTemplatesUpdated}
                  onTeamMemberQuitProposal={handleTeamMemberQuitProposal}
                />
                <FeeScheduleTab
                  isOpen={activeTab === ProposalTab.FeeSchedule}
                  proposalRedline={currentProposal.redline}
                  activeReviewField={activeReviewField}
                  commentForums={commentForums}
                  disableEditing={disableEditing}
                  onProposalRedlineChange={handleProposalRedlineChanged}
                  onCommentsClicked={(
                    field: ProposalFieldName,
                    fieldId?: Guid,
                    sidePanelTitle?: string
                  ) => {
                    handleToggleSidePanel(
                      ProposalPanel.Comments,
                      field,
                      fieldId,
                      sidePanelTitle
                    );
                  }}
                  onFeeScheduleTemplatesUpdated={
                    handleFeeScheduleTemplatesUpdated
                  }
                />
                <ConflictsTab
                  isOpen={activeTab === ProposalTab.Conflicts}
                  proposalRedline={currentProposal.redline}
                  activeReviewField={activeReviewField}
                  commentForums={commentForums}
                  disableEditing={disableEditing}
                  onProposalRedlineChange={handleProposalRedlineChanged}
                  onTraverseToNewField={setActiveReviewField}
                  onCommentsClicked={(
                    field: ProposalFieldName,
                    fieldId?: Guid,
                    sidePanelTitle?: string
                  ) => {
                    handleToggleSidePanel(
                      ProposalPanel.Comments,
                      field,
                      fieldId,
                      sidePanelTitle
                    );
                  }}
                />
                <PoliciesTab
                  isOpen={activeTab === ProposalTab.Policies}
                  proposalRedline={currentProposal.redline}
                  activeReviewField={activeReviewField}
                  commentForums={commentForums}
                  disableEditing={disableEditing}
                  onProposalRedlineChange={handleProposalRedlineChanged}
                  onTraverseToNewField={setActiveReviewField}
                  onCommentsClicked={(
                    field: ProposalFieldName,
                    fieldId?: Guid,
                    sidePanelTitle?: string
                  ) => {
                    handleToggleSidePanel(
                      ProposalPanel.Comments,
                      field,
                      fieldId,
                      sidePanelTitle
                    );
                  }}
                />
                <DiscountTab
                  isOpen={activeTab === ProposalTab.Discount}
                  proposalRedline={currentProposal.redline}
                  activeReviewField={activeReviewField}
                  commentForums={commentForums}
                  disableEditing={disableEditing}
                  onProposalRedlineChange={handleProposalRedlineChanged}
                  onTraverseToNewField={setActiveReviewField}
                  onCommentsClicked={(
                    field: ProposalFieldName,
                    fieldId?: Guid,
                    sidePanelTitle?: string
                  ) => {
                    handleToggleSidePanel(
                      ProposalPanel.Comments,
                      field,
                      fieldId,
                      sidePanelTitle
                    );
                  }}
                />
              </>
            )}
        </TabContent>
      </Content>
      <ChangeNavigationContainer>
        {currentProposal?.redline && (
          <ChangeTraverser
            redline={currentProposal.redline}
            currentField={activeReviewField}
            onTraverseToNewField={setActiveReviewField}
          />
        )}
      </ChangeNavigationContainer>
      <ActionsContainer>
        <ProposalActions>
          {(!disableEditing || isReviewing) && (
            <ProposalActionButton
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              loading={isSaving}
              disabled={
                isSubmitting ||
                isLoading ||
                isSaving ||
                isManagingReviewers ||
                !isFormValid ||
                !isFormDirty
              }
              onClick={handleSaveClicked}
            >
              Save
            </ProposalActionButton>
          )}
          {isReviewing && (
            <ProposalActionButton
              variant="contained"
              color="success"
              startIcon={<CheckIcon />}
              loading={isSaving}
              disabled={
                isLoading ||
                isSaving ||
                isApprovingReview ||
                isManagingReviewers ||
                !isFormValid ||
                hasUserApprovedReview()
              }
              onClick={handleApproveReviewClicked}
            >
              {hasUserApprovedReview() ? "Approved" : "Approve"}
            </ProposalActionButton>
          )}
          {!disableEditing && (
            <>
              <ProposalActionButton
                variant="contained"
                color="primary"
                startIcon={<RateReviewIcon />}
                loading={false}
                disabled={
                  isLoading ||
                  isSaving ||
                  isManagingReviewers ||
                  reviewerSelectorAnchor !== undefined ||
                  !isFormValid ||
                  !currentProposal?.id ||
                  disableEditing
                }
                onClick={handleManageReviewersClicked}
              >
                Manage Reviewers
              </ProposalActionButton>
              <ReviewerSelector
                freelyPatchableFields={freelyPatchableFields}
                popoverAnchor={reviewerSelectorAnchor}
                onPopoverClose={() => {
                  setReviewerSelectorAnchor(undefined);
                  setIsManagingReviewers(false);
                }}
                onChange={handleProposalReviewersChanged}
              />
              {currentProposal?.supersedes &&
                !currentProposal?.userCanHire(session.user) && (
                  <>
                    <ProposalActionButton
                      variant="contained"
                      color="success"
                      startIcon={<CheckIcon />}
                      loading={false}
                      disabled={
                        isSubmitting ||
                        isLoading ||
                        isSaving ||
                        isManagingReviewers ||
                        reviewerSelectorAnchor !== undefined ||
                        !isFormValid ||
                        !currentProposal?.id ||
                        currentProposal.creator?.userId.isEqualTo(
                          session.user?.id
                        ) ||
                        !currentProposal.userCanApprove(session.user) ||
                        currentProposal.redline?.isResolved
                      }
                      onClick={handleAcceptClicked}
                    >
                      Accept All
                    </ProposalActionButton>
                    <ProposalActionButton
                      variant="contained"
                      color="error"
                      startIcon={<CloseIcon />}
                      loading={false}
                      disabled={
                        isSubmitting ||
                        isLoading ||
                        isSaving ||
                        isManagingReviewers ||
                        reviewerSelectorAnchor !== undefined ||
                        !isFormValid ||
                        !currentProposal?.id ||
                        currentProposal.creator?.userId.isEqualTo(
                          session.user?.id
                        ) ||
                        !currentProposal.userCanReject(session.user) ||
                        currentProposal.redline?.isResolved
                      }
                      onClick={handleRejectClicked}
                    >
                      Reject All
                    </ProposalActionButton>
                  </>
                )}
              {currentProposal?.creator?.userId.isEqualTo(session.user?.id) && (
                <ProposalActionButton
                  variant="contained"
                  color="error"
                  startIcon={<CloseIcon />}
                  loading={false}
                  disabled={
                    isSubmitting ||
                    isLoading ||
                    isSaving ||
                    isManagingReviewers ||
                    reviewerSelectorAnchor !== undefined ||
                    !currentProposal?.id ||
                    isReviewing
                  }
                  onClick={handleCancelClicked}
                >
                  Cancel Proposal
                </ProposalActionButton>
              )}
              <Tooltip title="Submit to other party">
                <span>
                  <ProposalActionButton
                    variant="contained"
                    color="primary"
                    startIcon={<SendIcon />}
                    loading={isSubmitting}
                    disabled={
                      isSubmitting ||
                      isLoading ||
                      isSaving ||
                      isManagingReviewers ||
                      !isFormValid ||
                      !canSubmitRevision ||
                      (!currentProposal?.userCanApprove(session.user) &&
                        isReviewing)
                    }
                    onClick={handleSubmitClicked}
                  >
                    {`Submit ${
                      currentProposal?.userCanApprove(session.user) &&
                      currentProposal?.redline?.revisionsAccepted
                        ? "Approval"
                        : "Changes"
                    }`}
                  </ProposalActionButton>
                </span>
              </Tooltip>
              {currentProposal?.userCanHire(session.user) && (
                <ProposalActionButton
                  variant="contained"
                  color="success"
                  startIcon={<WorkIcon />}
                  loading={false}
                  disabled={
                    isSubmitting ||
                    isLoading ||
                    isSaving ||
                    isManagingReviewers ||
                    reviewerSelectorAnchor !== undefined ||
                    !isFormValid ||
                    !currentProposal?.id ||
                    isReviewing
                  }
                  onClick={handleHireClicked}
                >
                  Hire
                </ProposalActionButton>
              )}
              {currentProposal?.redline?.canBeUndone && (
                <ProposalActionButton
                  variant="contained"
                  color="secondary"
                  startIcon={<UndoIcon />}
                  loading={false}
                  disabled={
                    isSubmitting ||
                    isLoading ||
                    isSaving ||
                    isManagingReviewers
                  }
                  onClick={handleUndoChangesClicked}
                >
                  Undo Changes
                </ProposalActionButton>
              )}
              {!currentProposal?.creator?.userId.isEqualTo(
                session.user?.id
              ) && (
                <ProposalActionButton
                  variant="text"
                  color="error"
                  startIcon={<BlockIcon />}
                  loading={false}
                  disabled={
                    isSubmitting ||
                    isLoading ||
                    isSaving ||
                    isManagingReviewers ||
                    reviewerSelectorAnchor !== undefined ||
                    !isFormValid ||
                    !currentProposal?.id ||
                    isReviewing
                  }
                  onClick={handleEndNegotiationClicked}
                >
                  End Negotiation
                </ProposalActionButton>
              )}
            </>
          )}
        </ProposalActions>
        <MessageButtons>
          <ButtonContainer>
            <MessageButton
              size="medium"
              color="primary"
              onClick={() => {
                handleToggleSidePanel(
                  ProposalPanel.Comments,
                  ProposalFieldName.General
                );
              }}
            >
              <Badge
                variant="dot"
                color="secondary"
                overlap="circular"
                invisible={
                  !getForumForField(
                    ProposalFieldName.General,
                    undefined,
                    commentForums
                  )
                }
              >
                <CommentIcon fontSize="medium" />
              </Badge>
            </MessageButton>
            <MessageButtonLabel variant="button" color="primary">
              Comments
            </MessageButtonLabel>
          </ButtonContainer>
        </MessageButtons>
        <Portal>
          <SidePanel open={isSidePanelOpen} anchor="right" variant="persistent">
            <SidePanelContainer>
              <SidePanelContent>
                <TitleBar view={sidePanelView}>
                  <Typography variant="h5">{getSidePanelTitle()}</Typography>
                  <IconButton onClick={() => handleToggleSidePanel()}>
                    <CloseIcon />
                  </IconButton>
                </TitleBar>
                {sidePanelView === "comments" && (
                  <>
                    {isLoadingComments ? (
                      <Loader />
                    ) : (
                      <Comments
                        proposal={currentProposal}
                        field={currentCommentField}
                        fieldId={currentCommentFieldId}
                        activeComments={currentComments}
                        pendingComments={pendingComments}
                        commenters={currentCommenters}
                        isSaving={isCommentSaving}
                        onCommentPosted={handleUserCommentPosted}
                        onCommentEdited={handleCommentEdited}
                        onCommentReadToggled={handleCommentReadToggled}
                        onCommentDeleted={handleCommentDeleted}
                        onViewProfile={handleViewCommenterProfile}
                      />
                    )}
                  </>
                )}
              </SidePanelContent>
            </SidePanelContainer>
          </SidePanel>
        </Portal>
      </ActionsContainer>
    </>
  );
}
