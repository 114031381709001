import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Checkbox,
  FormControlLabel,
  InputBaseComponentProps,
  TextField,
} from "@mui/material";
import { green, lightBlue } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Currency from "common/components/currency";
import { Currency as CurrencyType } from "common/values/currency/currency";
import Money from "common/values/money/money";
import React, { forwardRef } from "react";
import TextChange from "work/entities/proposal/redlining/_diff/text-change";
import FeeRedline from "work/entities/proposal/redlining/fee-redline/fee-redline";
import Fee from "work/values/fee/fee";

const DiffContainer = styled("div")(({ theme }) => ({
  width: "100%",
}));
const DiffResult = styled("span")<{
  isdeferred?: boolean;
  isnewlyadded?: boolean;
  change?: TextChange;
  resolved?: boolean;
}>(({ isdeferred, isnewlyadded, change, resolved, theme }) => ({
  backgroundColor: (function () {
    if (resolved) return "unset";
    if (change?.isAdded) return green[300];
  })(),
  color: (function () {
    if (isnewlyadded) return lightBlue[700];
    if (isdeferred && !isnewlyadded) return theme.palette.error.main;
    return theme.palette.text.primary;
  })(),
  textDecoration: (function () {
    if (change?.isRemoved) return "line-through";
  })(),
  textDecorationColor: (function () {
    if (change?.isRemoved && resolved) return lightBlue[700];
    if (change?.isRemoved) return theme.palette.error.main;
  })(),
}));
const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
}));
const InputContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  "&.MuiInputBase-input": {
    height: "unset",
  },
}));
const ChangeContainer = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
}));
const DiffHeader = styled(Typography)(({ theme }) => ({
  color: lightBlue[500],
  display: "inline-block",
  fontSize: "0.75rem",
  lineHeight: 1,
  minWidth: theme.spacing(13.5),
}));
const PerSymbol = styled("span")(({ theme }) => ({
  fontSize: "1.5em",
  margin: theme.spacing(0, 1),
}));
const FeeRate = styled("span")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
}));
const FixedFeeLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
}));
const FeeInput = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(1),
}));

export interface DiffFeeProps extends InputBaseComponentProps {
  className?: string;
  feeRedline: FeeRedline | null;
  isNewlyAdded: boolean;
  showTextInput?: boolean;
  onFeeInputChange?: (fee: Fee) => void;
  onTextInputSubmit?: () => void;
  onTextInputCancel?: () => void;
}

const DiffFee = forwardRef<HTMLInputElement, DiffFeeProps>((props, ref) => {
    const {
      className,
      feeRedline,
      isNewlyAdded,
      showTextInput,
      onClick,
      onFeeInputChange,
      onTextInputSubmit,
      onTextInputCancel
    } = props;

    const [currentFeeRate, setCurrentFeeRate] = React.useState<Money | undefined>(feeRedline?.currentEntry ? feeRedline?.currentEntry?.rate : undefined);
    const [currentFeeUnit, setCurrentFeeUnit] = React.useState<string>(feeRedline?.currentEntry ? feeRedline?.currentEntry?.unit ?? "" : "");
    const [isFixedFee, setIsFixedFee] = React.useState<boolean>(feeRedline?.currentEntry ? feeRedline?.currentEntry?.unit === null : true);


    const handleFixedFeeChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setIsFixedFee((prevValue) => !prevValue);
      let updatedFee: Fee;
      if (event.target.checked) {
        updatedFee = new Fee(currentFeeRate ?? new Money(0, CurrencyType.USD), null);
      } else {
        updatedFee = new Fee(currentFeeRate ?? new Money(0, CurrencyType.USD), currentFeeUnit);
      }
      onFeeInputChange?.(updatedFee);
    };

    const handleFeeUnitChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      let updatedFee: Fee;
      if (isFixedFee) {
        updatedFee = new Fee(currentFeeRate ?? new Money(0, CurrencyType.USD), null);
      } else {
        setCurrentFeeUnit(event.target.value);
        updatedFee = new Fee(currentFeeRate ?? new Money(0, CurrencyType.USD), event.target.value);
      }

      onFeeInputChange?.(updatedFee);
    };

    const handleFeeRateChange = (currency: CurrencyType, amount: number) => {
      setCurrentFeeRate(new Money(amount, currency ?? CurrencyType.USD));
      const updatedFee = new Fee(
        new Money(amount, currency ?? CurrencyType.USD),
        currentFeeUnit,
      );
      onFeeInputChange?.(updatedFee);
    };

    const getChangeKey = (change: TextChange): string => {
      let changeType: string;
      if (change.isAdded) {
        changeType = "added";
      } else if (change.isRemoved) {
        changeType = "removed";
      } else {
        changeType = "unchanged";
      }
      return `${change?.diff.value}_${changeType}`;
    };

    const canSubmit: boolean =
      (currentFeeRate && isFixedFee) ||
      (currentFeeRate && currentFeeUnit && !isFixedFee) ||
      false;

    return (
      <TextContainer>
        <input ref={ref} type="hidden" />
        <InputContainer className={className}>
          {!showTextInput && (
            <DiffContainer onClick={onClick}>
              {feeRedline?.changes.map((change: TextChange) => {
                return (
                  <DiffResult
                    key={getChangeKey(change)}
                    isdeferred={feeRedline.currentEntry === null}
                    isnewlyadded={isNewlyAdded}
                    change={change}
                    resolved={feeRedline.isResolved}
                  >
                    {change?.diff.value}
                  </DiffResult>
                );
              })}
            </DiffContainer>
          )}
          {showTextInput && (
            <>
              <ChangeContainer>
                <DiffHeader variant="overline">previously:</DiffHeader>
                {feeRedline?.originalEntry?.toString() ?? "Deferred"}
              </ChangeContainer>
              <ChangeContainer>
                <DiffHeader variant="overline">your changes:</DiffHeader>
                {feeRedline?.changes.map((change: TextChange) => {
                  return (
                    <DiffResult
                      isdeferred={feeRedline?.currentEntry === null}
                      isnewlyadded={isNewlyAdded}
                      key={getChangeKey(change)}
                      change={change}
                      resolved
                    >
                      {change?.diff.value}
                    </DiffResult>
                  );
                })}
              </ChangeContainer>
              <FeeInput>
                <FeeRate>
                  <Currency
                    size="small"
                    label="Amount"
                    variant="standard"
                    value={currentFeeRate}
                    onChange={handleFeeRateChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" && canSubmit) {
                        onTextInputSubmit?.();
                      }
                      if (event.key === "Escape") {
                        onTextInputCancel?.();
                      }
                    }}
                  />
                  {!isFixedFee && (
                    <>
                      <PerSymbol>/</PerSymbol>
                      <TextField
                        label="Unit"
                        size="small"
                        variant="standard"
                        style={{ minWidth: "50px" }}
                        value={currentFeeUnit}
                        onChange={handleFeeUnitChange}
                        onKeyDown={(event) => {
                          if (event.key === "Enter" && canSubmit) {
                            onTextInputSubmit?.();
                          }
                          if (event.key === "Escape") {
                            onTextInputCancel?.();
                          }
                        }}
                      />
                    </>
                  )}
                </FeeRate>
                <FixedFeeLabel
                  label="Fixed"
                  labelPlacement="end"
                  control={
                    <Checkbox
                      size="small"
                      edge="start"
                      color="primary"
                      icon={<CheckBoxOutlineBlankIcon />}
                      checked={isFixedFee}
                      onChange={handleFixedFeeChange}
                      onKeyDown={(event) => {
                        if (event.key === "Enter" && canSubmit) {
                          onTextInputSubmit?.();
                        }
                        if (event.key === "Escape") {
                          onTextInputCancel?.();
                        }
                      }}
                    />
                  }
                />
              </FeeInput>
            </>
          )}
        </InputContainer>
      </TextContainer>
    );
  }
);

export default DiffFee;
