import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditNoteIcon from "@mui/icons-material/EditNote";
import {
  BaseTextFieldProps,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import DiffFee from "work/entities/proposal/redlining/_diff/view/diff-fee";
import { FeeType } from "work/entities/proposal/redlining/fee-redline/fee-redline";
import { FeeScheduleCategoryRedline } from "work/entities/proposal/redlining/fee-schedule-redline/fee-schedule-redline";
import Fee from "work/values/fee/fee";

const RedlineTextBox = styled(TextField)(({ theme }) => ({
  minWidth: "8rem",
  "& .MuiTypography-root": {
    height: "unset",
  },
  width: "min-content",
}));
const TextInputButtons = styled("div")(({ theme }) => ({
  alignSelf: "flex-end",
  display: "flex",
  marginBottom: theme.spacing(1.5),
}));
const ActionButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
}));

export interface FeeRedlineInputProps extends BaseTextFieldProps {
  categoryRedline: FeeScheduleCategoryRedline;
  showTextInput?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  hideUndoButton?: boolean;
  hideAcceptRejectButtons?: boolean;
  onFeeChange?: (newFee: Fee) => void;
}

export default function FeeRedlineInput(props: Readonly<FeeRedlineInputProps>) {
  const { categoryRedline, readOnly, onFeeChange, ...otherProps } = props;

  const [showTextInput, setShowTextInput] = React.useState<boolean>(
    props.showTextInput ?? false
  );
  const [currentFee, setCurrentFee] = React.useState<FeeType | undefined>(
    categoryRedline?.feeRedline?.currentEntry
  );

  function handleSubmitEdit() {
    if (!(currentFee instanceof Fee)) return;
    setShowTextInput(false);
    onFeeChange?.(currentFee);
  }

  function renderTextInputButtons() {
    if (readOnly) return null;
    return (
      <TextInputButtons>
        <Tooltip title="Save Change">
          <span>
            <IconButton
              size="small"
              disabled={!categoryRedline.feeRedline}
              onClick={handleSubmitEdit}
            >
              <CheckIcon color="primary" fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Cancel">
          <span>
            <IconButton size="small" onClick={() => setShowTextInput(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </TextInputButtons>
    );
  }

  function renderActionButtons() {
    if (readOnly) return null;
    return (
      <>
        {!showTextInput && !readOnly && (
          <Tooltip title="Edit">
            <span>
              <ActionButton onClick={() => setShowTextInput(true)}>
                <EditNoteIcon />
              </ActionButton>
            </span>
          </Tooltip>
        )}
        {showTextInput && renderTextInputButtons()}
      </>
    );
  }

  return (
    <RedlineTextBox
      {...otherProps}
      variant={props.variant ?? "outlined"}
      margin={props.margin ?? "normal"}
      slotProps={{
        htmlInput: {
          isNewlyAdded: categoryRedline.currentEntry && !categoryRedline.revisedEntry,
          feeRedline: categoryRedline.feeRedline,
          showTextInput: showTextInput,
          onFeeInputChange: setCurrentFee,
          onTextInputSubmit: handleSubmitEdit,
          onTextInputCancel: () => setShowTextInput(false),
          onClick: () => setShowTextInput(true),
        },
        input: {
          inputComponent: DiffFee,
          disableUnderline: true,
          endAdornment: renderActionButtons(),
        },
        inputLabel: {
          shrink: categoryRedline.feeRedline !== undefined
        }
      }}
    />
  );
}
