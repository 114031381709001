import Guid from "common/values/guid/guid"
import TextChange, { TextChangeCollection } from "work/entities/proposal/redlining/_diff/text-change";
import { ProposalFieldName } from "work/values/constants"

export class RedlineChange {
    field: ProposalFieldName | null;
    fieldId: Guid | null;
    isResolved: boolean;
    action: RedlineAction;
    textChanges?: TextChange[]

    constructor(field?: ProposalFieldName, fieldId?: Guid, isResolved?: boolean, action?: RedlineAction, textChanges?: TextChange[]) {
        this.field = field ?? null;
        this.fieldId = fieldId ?? null;
        this.isResolved = isResolved ?? false;
        this.action = action ?? RedlineAction.Edit;
        this.textChanges = textChanges ?? [];
    }

    static fromObject(object: any): RedlineChange {
        const redlineChange = new RedlineChange();
        redlineChange.field = object.field;
        redlineChange.fieldId = object.fieldId ? Guid.fromObject(object.fieldId) ?? null : null;
        redlineChange.isResolved = object.isResolved;
        redlineChange.action = RedlineAction.fromObject(object.action);
        redlineChange.textChanges = object.textChanges.map((change: any) => TextChange.fromObject(change));
        return redlineChange;
    }

    public toJSON(): object {
        return {
            field: this.field,
            fieldId: this.fieldId?.toJSON(),
            isResolved: this.isResolved,
            action: this.action.toString(),
            textChanges: this.textChanges?.map(change => change.toJSON())
        }
    }
}

export class RedlineAction {
    // The present tense of the action MUST match the readonly property name
    static readonly Accept: RedlineAction = new RedlineAction('Accept', 'Accepted');
    static readonly Reject: RedlineAction = new RedlineAction('Reject', 'Rejected');
    static readonly UndoEdit: RedlineAction = new RedlineAction('UndoEdit', 'Undid edit');
    static readonly UndoAccept: RedlineAction = new RedlineAction('UndoAccept', 'Undid acceptance of');
    static readonly UndoReject: RedlineAction = new RedlineAction('UndoReject', 'Undid rejection of');
    static readonly Edit: RedlineAction = new RedlineAction('Edit', 'Edited');
    static readonly ReEdit: RedlineAction = new RedlineAction('ReEdit', 'Re-edited');
    static readonly Add: RedlineAction = new RedlineAction('Add', 'Added');
    static readonly Remove: RedlineAction = new RedlineAction('Remove', 'Removed');

    private constructor(private readonly presentTense: string, public readonly actionDescription: string) { }

    toString() {
        return this.presentTense;
    }

    public static fromObject(obj: keyof typeof RedlineAction): RedlineAction {
        const newAction = RedlineAction[obj] as RedlineAction;
        return newAction;
    }
}