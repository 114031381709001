import * as Constants from 'common/helpers/constants'
import { HubConnectionBuilder, LogLevel, HttpTransportType, HubConnectionState } from '@microsoft/signalr';
import { useEffect } from "react";
import UserNetworkConnection from "users/entities/user-network-connection/user-network-connection";
import NetworkInvitationInfo from "users/entities/user-network-invitation/network-invitation-info";
import { useSession } from "users/session/session-context";
import Session from 'users/session/session';


export function RealtimeNetworking() {
    const session = useSession();
    const authToken = session.authToken?.value ?? new Session().authToken?.value ?? '';

    const signalRConnection = new HubConnectionBuilder()
        .withUrl(
            `${Constants.apiBaseUrl}/networkingHub`,
            {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
                accessTokenFactory: () => authToken
            }
        )
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();

    async function startSignalR() {
        if (signalRConnection.state !== HubConnectionState.Disconnected) return;

        try {
            await signalRConnection?.start();
        } catch (err: any) {
            if (!err.message.includes('Failed to start the HttpConnection before stop() was called')) {
                console.error('Failed to start signalR connection. Reason: ', err);
                setTimeout(startSignalR, 5000);
            }
        }
    }

    signalRConnection.onclose(error => {
        console.debug('Realtime Networking SignalR connection closed. Reason: ', error);
        startSignalR();
    });

    signalRConnection.onreconnecting(error => {
        console.debug('Re-establishing connection to Realtime Networking Signalr hub. Reason: ', error);
    });

    signalRConnection.onreconnected(connectionId => {
        console.debug(`Realtime Networking SignalR connection reestablished. Connected with connectionId "${connectionId}".`);
    });

    const networkingSignalHandlers = [
        {
            signal: "NetworkConnectionCreated",
            handler: (connection: UserNetworkConnection) => {
                if (!session.user?.id)
                    return;
                session.addNetworkConnection(connection.toProfileConnection(session.user.id));
            }
        },
        {
            signal: "NetworkConnectionUpdated",
            handler: (connection: UserNetworkConnection) => {
                if (!session.user?.id)
                    return;
                session.updateNetworkConnection(connection.toProfileConnection(session.user.id));
            }
        },
        {
            signal: "NetworkConnectionDeleted",
            handler: (connection: UserNetworkConnection) => session.removeNetworkConnection(connection.id)
        },
        {
            signal: "NetworkInvitationCreated",
            handler: (invitation: NetworkInvitationInfo) => session.addNetworkInvitation(invitation)
        },
        {
            signal: "NetworkInvitationUpdated",
            handler: (invitation: NetworkInvitationInfo) => session.updateNetworkInvitation(invitation)
        },
        {
            signal: "NetworkInvitationDeleted",
            handler: (invitation: NetworkInvitationInfo) => session.removeNetworkInvitation(invitation.id)
        }
    ];

    for (let handler of networkingSignalHandlers) {
        signalRConnection.on(handler.signal, (notification) => {
            handler.handler(notification);
        });
    }

    useEffect(() => {
        if (!session.isLoggedIn) return;

        startSignalR();

        return () => {
            if (signalRConnection.state !== HubConnectionState.Connected) return;
            signalRConnection.stop();
        };
    }, []);


    return null;
}