import Guid from "common/values/guid/guid";
import FeeRedline, {
  FeeType,
} from "work/entities/proposal/redlining/fee-redline/fee-redline";
import FieldRedline from "work/entities/proposal/redlining/field-redline";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import {
  HumanReadableProposalFieldName,
  ProposalFieldName,
} from "work/values/constants";
import FeeScheduleBillingCode from "work/values/fee-schedule-billing-code/fee-schedule-billing-code";
import FeeScheduleCategoryDescription from "work/values/fee-schedule-category-description/fee-schedule-category-description";
import FeeScheduleCategoryName from "work/values/fee-schedule-category-name/fee-schedule-category-name";
import {
  RedlineAction,
  RedlineChange,
} from "work/entities/proposal/redlining/redline-change";
import TextChange, {
  TextChangeCollection,
} from "work/entities/proposal/redlining/_diff/text-change";
import { Change, diffLines } from "diff";
import _ from "lodash";
import FeeSchedule from "work/values/fee-schedule/fee-schedule";
import { red } from "@mui/material/colors";
export class FeeScheduleCategoryRedline {
  private _field: ProposalFieldName = ProposalFieldName.FeeSchedule;
  private _fieldId: Guid;
  private _originalEntry: FeeScheduleCategory | null;
  private _revisedEntry: FeeScheduleCategory | null;
  private _currentEntry: FeeScheduleCategory | null | undefined;
  private _sessionHistory: RedlineChange[] = [];
  private _originalRedline: FeeScheduleCategoryRedline;
    private _textChangeCollection: TextChangeCollection =
    new TextChangeCollection();

  nameRedline?: FieldRedline<FeeScheduleCategoryName>;
  descriptionRedline?: FieldRedline<FeeScheduleCategoryDescription>;
  billingCodeRedline?: FieldRedline<FeeScheduleBillingCode>;
  feeRedline?: FeeRedline;

  constructor(
    fieldId: Guid,
    originalEntry: FeeScheduleCategory | null,
    revisedEntry: FeeScheduleCategory | null,
    originalRedline?: FeeScheduleCategoryRedline
  ) {
    this._fieldId = fieldId;
    this._originalEntry = originalEntry;
    this._revisedEntry = revisedEntry;
    this._originalRedline = originalRedline ?? this;

    this.refreshSubRedlineValues();
    this.updateDiff();
    this.resetCurrentEntry();
  }

  public get field(): ProposalFieldName {
    return this._field;
  }
  public get fieldId(): Guid {
    return this._fieldId;
  }

  public get label(): string {
    return HumanReadableProposalFieldName[this._field];
  }

  public get originalEntry(): FeeScheduleCategory | null {
    return this._originalEntry;
  }
  public get revisedEntry(): FeeScheduleCategory | null {
    return this._revisedEntry;
  }
  public get currentEntry(): FeeScheduleCategory | null | undefined {
    return this._currentEntry;
  }

  public get changes(): TextChange[] {
    return this._textChangeCollection.changes;
  }

  public get originalRedline(): FeeScheduleCategoryRedline {
    return this._originalRedline;
  }
  public set originalRedline(original: FeeScheduleCategoryRedline) {
    this._originalRedline = original;
  }

  public get sessionHistory(): RedlineChange[] {
    return this._sessionHistory;
  }

  public get isAdded(): boolean {
    return (
      (this.originalEntry === null && this.revisedEntry !== null) ||
      (this.revisedEntry === null && this.currentEntry !== null)
    );
  }
  public get isNewlyAdded(): boolean {
    return Boolean(
      this._currentEntry && !this.wasRedlined && !this._revisedEntry
    );
  }

  public get isRemoved(): boolean {
    return (
      (this.originalEntry !== null && this.revisedEntry === null) ||
      (this.revisedEntry !== null && this.currentEntry === null)
    );
  }
  public get isNewlyRemoved(): boolean {
    return this.revisedEntry !== null && this.currentEntry === null;
  }

  public get isRevised(): boolean {
    return this._currentEntry !== undefined && !this.isAccepted;
  }
  public get isNewlyRevised(): boolean {
    return Boolean(
      this._currentEntry && !this.wasRedlined && this._revisedEntry
    );
  }

  public get wasRedlined(): boolean {
    if (this._originalEntry === null && this._revisedEntry === null) {
      return false;
    } else if (this._revisedEntry === null) {
      return true;
    } else {
      return !this.revisedEntry?.isEqualTo(this.originalEntry);
    }
  }

  public get isResolved(): boolean {
    return this._currentEntry !== undefined;
  }
  public get canBeUndone(): boolean {
    return (this.wasRedlined && this.isResolved) || this.isRevised;
  }

  public get isAccepted(): boolean {
    if (this._currentEntry === null && this._revisedEntry === null) {
      return true;
    }
    return this._currentEntry?.isEqualTo(this._revisedEntry) ?? false;
  }
  public get isRejected(): boolean {
    return this.isResolved && this.wasRedlined && !this.isAccepted;
  }

  public edit(
    newCategory: FeeScheduleCategory | null
  ): FeeScheduleCategoryRedline {
    const newRedline = this.clone();
    newRedline._sessionHistory = [];

    newRedline._currentEntry = newCategory;

    newRedline.refreshSubRedlineValues();
    newRedline.updateDiff();

    const parent = new TextChangeCollection();
    let previous: string;
    let next: string;
    if (this._originalRedline._currentEntry !== undefined) {
      previous = this._originalRedline._revisedEntry?.toString() ?? "";
      next = this._originalRedline._currentEntry?.toString() ?? "";
    } else {
      previous = this._originalRedline._originalEntry?.toString() ?? "";
      next = this._originalRedline._revisedEntry?.toString() ?? "";
    }
    const diff = diffLines(previous, next);

    let action = RedlineAction.Edit;
    if (
      !this._originalRedline.currentEntry?.isEqualTo(
        this._originalRedline.revisedEntry
      )
    ) {
      action = RedlineAction.ReEdit;
    }
    const redlineChange = new RedlineChange(
      this._field,
      this._fieldId,
      true,
      action,
      diff.map((change) => new TextChange(parent, change))
    );
    newRedline._sessionHistory.push(redlineChange);
    return newRedline;
  }

  public remove(): FeeScheduleCategoryRedline {
    if (this._currentEntry === null) {
      console.warn("can't remove a redline that hasn't been modified");
      return this.clone();
    }

    const newRedline = this.clone();
    newRedline._sessionHistory = [];

    newRedline._currentEntry = null;
    newRedline.refreshSubRedlineValues();
    newRedline.updateDiff();
    const redlineChange = new RedlineChange(
      ProposalFieldName.FeeSchedule,
      this._fieldId,
      true,
      RedlineAction.Remove,
      this._originalRedline._textChangeCollection.changes
    );

    newRedline._sessionHistory.push(redlineChange);
    return newRedline;
  }

  public accept(): FeeScheduleCategoryRedline {
    const newRedline = this.clone();
    newRedline._sessionHistory = [];

    newRedline._currentEntry = this._revisedEntry;

    newRedline.refreshSubRedlineValues();
    newRedline.updateDiff();

    if(!this._originalRedline.isAccepted){
    const redlineChange = new RedlineChange(
      ProposalFieldName.FeeSchedule,
      this._fieldId,
      false,
      RedlineAction.Accept,
      this._textChangeCollection.changes
    );
    newRedline._sessionHistory.push(redlineChange);
  }
    return newRedline;
  }

  public reject(): FeeScheduleCategoryRedline {
    const newRedline = this.clone();
    newRedline._sessionHistory = [];

    newRedline._currentEntry = this._originalEntry;
    newRedline.refreshSubRedlineValues();
    newRedline.updateDiff();

    if (!this._originalRedline.isRejected) {
      const redlineChange = new RedlineChange(
        this._field,
        this._fieldId ?? undefined,
        false,
        RedlineAction.Reject,
        this._textChangeCollection.changes
      );
      newRedline._sessionHistory.push(redlineChange);
    }
    return newRedline;
  }

  public undo(): FeeScheduleCategoryRedline {
    if (this._currentEntry === undefined) {
      console.warn("can't undo a redline that hasn't been modified");
      return this.clone();
    }

    const newRedline = this.clone();
    newRedline._sessionHistory = [];

    newRedline.resetCurrentEntry();
    newRedline.updateDiff();

    let action: RedlineAction | undefined;
    let changes = this._originalRedline._textChangeCollection.changes;
    let resolved = true;
    if (this._originalRedline.wasRedlined && this._originalRedline.isAccepted) {
      action = RedlineAction.UndoAccept;
      changes = this.getInitialChanges(changes);
      resolved = false;
    } else if (
      this._originalRedline.wasRedlined &&
      this._originalRedline.isRejected
    ) {
      action = RedlineAction.UndoReject;
      changes = this.getInitialChanges(changes);
      resolved = false;
    } else if (this._originalRedline.isResolved) {
      action = RedlineAction.UndoEdit;
  }

  if (action) {
    const redlineChange = new RedlineChange(
      this._field,
      this._fieldId ?? undefined,
      resolved,
      action,
      changes
    );
    newRedline._sessionHistory.push(redlineChange);
  }
  return newRedline;
}

  private getInitialChanges(changes: TextChange[]) {
    changes = new Array<TextChange>();
    const previous = this._originalRedline._originalEntry?.toString() ?? "";
    const next = this._originalRedline._revisedEntry?.toString() ?? "";
    const diff = diffLines(previous, next);

    diff.forEach((change: Change) => {
      changes.push(new TextChange(this._textChangeCollection, change));
    });
    return changes;
  }

  public clearSessionHistory() {
    this._sessionHistory = [];
  }

  public clone(originalRedline?: FeeScheduleCategoryRedline): FeeScheduleCategoryRedline {
    const clone = FeeScheduleCategoryRedline.fromObject(
      this.toJSON(),
      originalRedline ?? this._originalRedline ?? this
    );
    return clone;
  }

  public toJSON(): any {
    return {
      _field: this._field,
      _fieldId: this._fieldId.value,
      _originalEntry: this._originalEntry
        ? this._originalEntry.toJSON()
        : this._originalEntry,
      _revisedEntry: this._revisedEntry
        ? this._revisedEntry.toJSON()
        : this._revisedEntry,
      _currentEntry: this._currentEntry
        ? this._currentEntry.toJSON()
        : this._currentEntry,
      _sessionHistory: this._sessionHistory.map((change) => change.toJSON()),
    };
  }

  public static fromObject(
    obj: any,
    originalRedline?: FeeScheduleCategoryRedline
  ): FeeScheduleCategoryRedline {
    const fieldId = Guid.fromObject(obj._fieldId);
    if (!fieldId) {
      throw new Error("FieldId is required");
    }

    const originalEntry = obj._originalEntry
      ? FeeScheduleCategory.Prototype.fromObject(obj._originalEntry)
      : null;
    const revisedEntry = obj._revisedEntry
      ? FeeScheduleCategory.Prototype.fromObject(obj._revisedEntry)
      : null;
    const newCategoryRedline = new FeeScheduleCategoryRedline(
      fieldId,
      originalEntry,
      revisedEntry,
      originalRedline
    );
    if (obj._currentEntry === null) {
      newCategoryRedline._currentEntry = null;
    } else if (obj._currentEntry) {
      newCategoryRedline._currentEntry = FeeScheduleCategory.Prototype.fromObject(
        obj._currentEntry
      );
    }
    const sessionHistory = new Array<RedlineChange>();
    if (obj._sessionHistory) {
      obj._sessionHistory.forEach((change: any) => {
        sessionHistory.push(RedlineChange.fromObject(change));
      });
    }
    newCategoryRedline.refreshSubRedlineValues();
    newCategoryRedline.updateDiff();
    newCategoryRedline._sessionHistory = sessionHistory

    return newCategoryRedline;
  }

  private resetCurrentEntry() {
    if (this._originalEntry === null && this.revisedEntry === null) {
      this._currentEntry = null;
    } else if (
      this._revisedEntry &&
      this._originalEntry &&
      this._revisedEntry.isEqualTo(this._originalEntry)
    ) {
      this._currentEntry = this._originalEntry;
    } else {
      this._currentEntry = undefined;
    }
  }

  private updateDiff() {
    this._textChangeCollection = new TextChangeCollection();
    if (
      this._originalEntry === null &&
      this._revisedEntry === null &&
      !this._currentEntry
    ) {
      return;
    }

    let previous: string;
    let next: string;
    if (this._currentEntry !== undefined) {
      previous = this._revisedEntry?.toString() ?? "";
      next = this._currentEntry?.toString() ?? "";
    } else {
      previous = this._originalEntry?.toString() ?? "";
      next = this._revisedEntry?.toString() ?? "";
    }

    const diff = diffLines(previous, next);

    diff.forEach((change: Change) => {
      this._textChangeCollection.changes.push(
        new TextChange(this._textChangeCollection, change)
      );
    });
  }

  private refreshSubRedlineValues() {
    this.refreshNameRedline();
    this.refreshDescriptionRedline();
    this.refreshBillingCodeRedline();
    this.refreshFeeRedline();
  }

  private refreshFeeRedline() {
    let originalFee: FeeType = false;
    let revisedFee: FeeType = false;
    let currentFee: FeeType | undefined = undefined;

    if (this._originalEntry?.fee === null) {
      originalFee = null;
    } else if (this._originalEntry?.fee) {
      originalFee = this._originalEntry.fee;
    }

    if (this._revisedEntry?.fee === null) {
      revisedFee = null;
    } else if (this._revisedEntry?.fee) {
      revisedFee = this._revisedEntry.fee;
    }

    if (this._currentEntry === null) {
      currentFee = false;
    } else if (this._currentEntry?.fee === null) {
      currentFee = null;
    } else if (this._currentEntry?.fee) {
      currentFee = this._currentEntry.fee;
    }

    this.feeRedline = new FeeRedline(originalFee, revisedFee);
    this.feeRedline = this.feeRedline.updateFee(currentFee);
  }

  private refreshBillingCodeRedline() {
    this.billingCodeRedline = new FieldRedline<FeeScheduleBillingCode>(
      FeeScheduleBillingCode.Prototype,
      ProposalFieldName.FeeSchedule,
      null,
      this._originalEntry?.billingCode ?? null,
      this._revisedEntry?.billingCode ?? null
    );
    if (this._currentEntry === null) {
      this.billingCodeRedline = this.billingCodeRedline.remove();
    } else if (this._currentEntry) {
      this.billingCodeRedline = this.billingCodeRedline.edit(
        this._currentEntry.billingCode
      );
    }
  }

  private refreshDescriptionRedline() {
    this.descriptionRedline = new FieldRedline<FeeScheduleCategoryDescription>(
      FeeScheduleCategoryDescription.Prototype,
      ProposalFieldName.FeeSchedule,
      null,
      this._originalEntry?.description ?? null,
      this._revisedEntry?.description ?? null,
      undefined,
      true
    );
    if (this._currentEntry === null) {
      this.descriptionRedline = this.descriptionRedline.remove();
    } else if (this._currentEntry) {
      this.descriptionRedline = this.descriptionRedline.edit(
        this._currentEntry.description
      );
    }
  }

  private refreshNameRedline() {
    this.nameRedline = new FieldRedline<FeeScheduleCategoryName>(
      FeeScheduleCategoryName.Prototype,
      ProposalFieldName.FeeSchedule,
      null,
      this._originalEntry?.name ?? null,
      this._revisedEntry?.name ?? null
    );
    if (this._currentEntry === null) {
      this.nameRedline = this.nameRedline.remove();
    } else if (this._currentEntry) {
      this.nameRedline = this.nameRedline.edit(this._currentEntry.name);
    }
  }
}

export class FeeScheduleRedline {
  private _sessionHistory: RedlineChange[] = [];
  private _lastEntryUpdated: Guid | undefined;
  protected _redlines: FeeScheduleCategoryRedline[] = [];

  constructor(
    originalEntries: FeeScheduleCategory[],
    revisedEntries: FeeScheduleCategory[]
  ) {
    originalEntries.forEach((originalCategory) => {
      let categoryRedline: FeeScheduleCategoryRedline;

      let modifiedEntry = revisedEntries.find((revisedCategory) =>
        revisedCategory.id?.isEqualTo(originalCategory.id)
      );
      if (modifiedEntry !== undefined) {
        categoryRedline = new FeeScheduleCategoryRedline(
          originalCategory.id,
          originalCategory,
          modifiedEntry
        );
      } else {
        categoryRedline = new FeeScheduleCategoryRedline(
          originalCategory.id,
          originalCategory,
          null
        );
      }
      this._redlines.push(categoryRedline);
    });

    const addedCategories = revisedEntries.filter(
      (revisedCategory) =>
        !originalEntries.find((originalCategory) =>
          originalCategory.id?.isEqualTo(revisedCategory.id)
        )
    );
    addedCategories.forEach((addedCategory) => {
      this._redlines.push(
        new FeeScheduleCategoryRedline(addedCategory.id, null, addedCategory)
      );
    });
  }

  public get field(): ProposalFieldName {
    return ProposalFieldName.FeeSchedule;
  }

  public get redlines(): FeeScheduleCategoryRedline[] {
    return this._redlines;
  }

  public get sessionHistory(): RedlineChange[] {
    return [
      ...this._sessionHistory,
      ...this._redlines.flatMap((redline) => redline.sessionHistory),
    ];
  }

  public get isRevised(): boolean {
    return this._redlines.some((redlineCategory) => redlineCategory.isRevised);
  }

  public get wasRedlined(): boolean {
    return this._redlines.some(
      (redlineCategory) => redlineCategory.wasRedlined
    );
  }

  public get isResolved(): boolean {
    return this._redlines.every(
      (redlineCategory) => redlineCategory.isResolved
    );
  }
  public get canBeUndone(): boolean {
    return this._redlines.some(
      (redlineCategory) => redlineCategory.canBeUndone
    );
  }

  public get isAccepted(): boolean {
    return this._redlines.every(
      (redlineCategory) => redlineCategory.isAccepted
    );
  }

  public get isEmpty(): boolean {
    return this._redlines.every(
      (redlineCategory) => redlineCategory.currentEntry === null
    );
  }

  public get currentIds(): Guid[] {
    return this._redlines
      .map((redline) => redline.currentEntry?.id)
      .filter((id) => id !== undefined) as Guid[];
  }

  public get lastEntryUpdated(): Guid | undefined {
    return this._lastEntryUpdated;
  }

  public clearSessionHistory() {
    this._sessionHistory = [];
    this._redlines.map((redline) => redline.clearSessionHistory());
  }

  public addEntry(category: FeeScheduleCategory): FeeScheduleRedline {
    const newFeeScheduleRedline = this.clone();
    const existingCategoryIndex = newFeeScheduleRedline._redlines.findIndex(
      (redlineCategory) => redlineCategory.fieldId.isEqualTo(category.id)
    );
    if (existingCategoryIndex >= 0) {
      newFeeScheduleRedline._redlines[existingCategoryIndex] =
        newFeeScheduleRedline._redlines[existingCategoryIndex].edit(category);
    } else {
      let newCategoryRedline = new FeeScheduleCategoryRedline(
        category.id,
        null,
        null
      );
      newCategoryRedline = newCategoryRedline.edit(category);
      newCategoryRedline.clearSessionHistory();

      newFeeScheduleRedline._redlines.push(newCategoryRedline);
      newFeeScheduleRedline._lastEntryUpdated = category.id;

      newFeeScheduleRedline._sessionHistory.push(
        new RedlineChange(
          ProposalFieldName.FeeSchedule,
          category.id,
          true,
          RedlineAction.Add,
          []
        )
      );
    }
    return newFeeScheduleRedline;
  }

  public getMatchingCategoryRedline(
    category: FeeScheduleCategory
  ): FeeScheduleCategoryRedline | null {
    if (!category.name) return null;
    return (
      this._redlines.find(
        (redlineCategory) =>
          redlineCategory.fieldId.isEqualTo(category.id) ||
          [
            redlineCategory.originalEntry?.name.toString(),
            redlineCategory.revisedEntry?.name.toString(),
            redlineCategory.currentEntry?.name.toString(),
          ]
            .filter((name) => name)
            .includes(category.name?.toString())
      ) ?? null
    );
  }

  public replaceEntryById(
    fieldId: Guid,
    category: FeeScheduleCategory
  ): FeeScheduleRedline {
    const newFeeScheduleRedline = this.clone();
    const targetIndex = newFeeScheduleRedline._redlines.findIndex(
      (existingCategory) => existingCategory.fieldId?.isEqualTo(fieldId)
    );
    if (targetIndex === -1)
      throw new Error("Could not find category to update");
    const targetCategory = newFeeScheduleRedline._redlines[targetIndex];
    newFeeScheduleRedline._redlines[targetIndex] =
      targetCategory.edit(category);
    newFeeScheduleRedline._lastEntryUpdated = fieldId;
    return newFeeScheduleRedline;
  }

  public removeCategory(category: FeeScheduleCategory): FeeScheduleRedline {
    if (!category) throw new Error("No id provided");
    const newFeeScheduleRedline = this.clone();
    const targetIndex = newFeeScheduleRedline._redlines.findIndex(
      (categoryRedline) => categoryRedline.fieldId.isEqualTo(category.id)
    );
    if (targetIndex === -1) throw new Error("Category not found");
    const targetCategoryRedline = newFeeScheduleRedline._redlines[targetIndex];

    if (
      targetCategoryRedline.isNewlyAdded &&
      targetCategoryRedline.isResolved
    ) {
      newFeeScheduleRedline._redlines.splice(targetIndex, 1);
    } else {
      newFeeScheduleRedline._redlines[targetIndex] =
        targetCategoryRedline.remove();
      newFeeScheduleRedline._lastEntryUpdated = category.id;
    }

    return newFeeScheduleRedline;
  }

  public removeAll(): FeeScheduleRedline {
    let newRedline = this.clone();
    for (const redline of this._redlines) {
      newRedline = newRedline.removeEntryByFieldId(redline.fieldId);
    }
    return newRedline;
  }
  public removeEntryByFieldId(categoryId: Guid): FeeScheduleRedline {
    if (!categoryId) throw new Error("No id provided");
    const newFeeScheduleRedline = this.clone();
    const targetIndex = newFeeScheduleRedline._redlines.findIndex((category) =>
      category.fieldId?.isEqualTo(categoryId)
    );
    if (targetIndex === -1) throw new Error("Category not found");
    const targetCategoryRedline = newFeeScheduleRedline._redlines[targetIndex];

    if (
      targetCategoryRedline.isNewlyAdded &&
      targetCategoryRedline.isResolved
    ) {
      newFeeScheduleRedline._redlines.splice(targetIndex, 1);
    } else {
      newFeeScheduleRedline._redlines[targetIndex] =
        targetCategoryRedline.remove();
      newFeeScheduleRedline._lastEntryUpdated = categoryId;
    }

    return newFeeScheduleRedline;
  }

  public acceptAll(): FeeScheduleRedline {
    let newRedline = this.clone();
    for (const redline of this._redlines) {
      newRedline = newRedline.acceptRedlineById(redline.fieldId);
    }
    return newRedline;
  }
  public acceptRedlineById(feeScheduleCategoryId: Guid): FeeScheduleRedline {
    const newFeeScheduleRedline = this.clone();
    const target = newFeeScheduleRedline._redlines.find((redlineFee) =>
      redlineFee.fieldId.isEqualTo(feeScheduleCategoryId)
    );

    if (target) {
      const index = newFeeScheduleRedline._redlines.indexOf(target);
      newFeeScheduleRedline._redlines[index] = target.accept();
      newFeeScheduleRedline._lastEntryUpdated = feeScheduleCategoryId;
    }
    return newFeeScheduleRedline;
  }

  public rejectAll(): FeeScheduleRedline {
    let newRedline = this.clone();
    for (const redline of this._redlines) {
      newRedline = newRedline.acceptRedlineById(redline.fieldId);
    }
    return newRedline;
  }
  public rejectRedlineById(feeScheduleCategoryId: Guid): FeeScheduleRedline {
    const newFeeScheduleRedline = this.clone();
    const target = newFeeScheduleRedline._redlines.find((redlineFee) =>
      redlineFee.fieldId.isEqualTo(feeScheduleCategoryId)
    );

    if (target) {
      const index = newFeeScheduleRedline._redlines.indexOf(target);
      newFeeScheduleRedline._redlines[index] = target.reject();
      newFeeScheduleRedline._lastEntryUpdated = feeScheduleCategoryId;
    }
    return newFeeScheduleRedline;
  }

  public undoAll(): FeeScheduleRedline {
    let newRedline = this.clone();
    for (const redline of this._redlines) {
      if (redline.canBeUndone) {
        newRedline = newRedline.undoRedlineById(redline.fieldId);
      }
    }
    return newRedline;
  }
  public undoRedlineById(feeScheduleCategoryId: Guid): FeeScheduleRedline {
    const newFeeScheduleRedline = this.clone();
    newFeeScheduleRedline._sessionHistory = [];

    const target = newFeeScheduleRedline._redlines.find((redlineFee) =>
      redlineFee.fieldId.isEqualTo(feeScheduleCategoryId)
    );

    if (target && !target.isNewlyAdded) {
      const index = newFeeScheduleRedline._redlines.indexOf(target);
      newFeeScheduleRedline._redlines[index] = target.undo();
      newFeeScheduleRedline._lastEntryUpdated = feeScheduleCategoryId;
    } else if (target?.isNewlyAdded) {
      newFeeScheduleRedline._redlines.splice(
        newFeeScheduleRedline._redlines.indexOf(target),
        1
      );
      const redlineChange = new RedlineChange(
        ProposalFieldName.FeeSchedule,
        feeScheduleCategoryId,
        false,
        RedlineAction.Remove,
        target.changes
      );
      this._sessionHistory.push(redlineChange);
    }
    return newFeeScheduleRedline;
  }

  public updateRedline(
    newFeeScheduleCategoryRedline: FeeScheduleCategoryRedline
  ): FeeScheduleRedline {
    const newFeeScheduleRedline = this.clone();
    const targetCategory = newFeeScheduleRedline._redlines.find(
      (existingCategory) =>
        existingCategory.fieldId?.isEqualTo(
          newFeeScheduleCategoryRedline.fieldId
        )
    );
    if (!targetCategory) {
      console.warn("Could not find category to update");
      return newFeeScheduleRedline;
    }
    const targetIndex = newFeeScheduleRedline._redlines.findIndex(
      (existingCategory) =>
        existingCategory.fieldId?.isEqualTo(
          newFeeScheduleCategoryRedline.fieldId
        )
    );
    newFeeScheduleRedline._redlines[targetIndex] =
      newFeeScheduleCategoryRedline;
    newFeeScheduleRedline._lastEntryUpdated =
      newFeeScheduleCategoryRedline.fieldId;
    return newFeeScheduleRedline;
  }

  public static fromArrayObject(arrayObject: any[]) {
    const feeScheduleRedline = new FeeScheduleRedline([], []);
    arrayObject.forEach((redlineCategory: any) => {
      const redlineInstance =
        FeeScheduleCategoryRedline.fromObject(redlineCategory);
      if (redlineInstance) feeScheduleRedline._redlines.push(redlineInstance);
    });
    return feeScheduleRedline;
  }

  public clone() : FeeScheduleRedline {
    const jsonCategories = this._redlines.map((category) => category.toJSON());
    const newFeeScheduleRedline = FeeScheduleRedline.fromArrayObject(jsonCategories);
    for(const categoryRedline of newFeeScheduleRedline._redlines) {
      categoryRedline.originalRedline = this._redlines.find(
        (originalRedline) => 
          originalRedline.fieldId?.isEqualTo(categoryRedline.fieldId))?.originalRedline || categoryRedline.originalRedline;
    }
    return newFeeScheduleRedline;
  }

  public toJSON(): any {
    const categoriesJson = [];
    for (const category of this._redlines) {
      categoriesJson.push(category.toJSON());
    }
    return categoriesJson;
  }
}
