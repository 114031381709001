import GetAppIcon from "@mui/icons-material/GetApp";
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { downloadFile } from "common/helpers/utils";
import DocumentAPIService from "documents/entities/document/api/document-api-service";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useSession } from "users/session/session-context";
import Proposal from "work/entities/proposal/proposal";
import { ProposalTab } from "work/entities/proposal/redlining/view/proposal-redline-dialog";
import WorkDocument from "work/values/work-document/work-document";

const ListContainer = styled("div")(({ theme }) => ({
  display: "grid",
  columnGap: theme.spacing(2),
  gridTemplateColumns: "1fr 1fr",
  width: "100%",
}));
const ConflictsSubheader = styled(ListSubheader)(({ theme }) => ({
  fontSize: "1.4em",
}));

type ConflictsTabProps = {
  activeTab: ProposalTab;
  proposal?: Proposal;
};

export default function ConflictsTab(props: Readonly<ConflictsTabProps>) {
  const { 
    activeTab, 
    proposal 
  } = props;

  const session = useSession();

  useEffect(() => {
    if (activeTab !== ProposalTab.Conflicts) return;

    let abortController = new AbortController();

    return () => {
      abortController.abort();
      abortController = new AbortController();
    }
  }, [proposal, activeTab]);


  /**
   * Handles when a conflicts document is downloaded
   * @param   {WorkDocument} document The document to download {@link WorkDocument}
   * @returns {Promise<void>}
   */
  async function handleDownloadConflicts(
    document: WorkDocument
  ): Promise<void> {
    try {
      const documentApiService = new DocumentAPIService(session);
      const conflictsTemplate = await documentApiService.downloadDocument(
        document.id
      );
      downloadFile(conflictsTemplate);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Failed to download conflicts document. Please try again",
        { variant: "error" }
      );
    }
  }

  if (activeTab !== ProposalTab.Conflicts) return null;

  return proposal && (
    <ListContainer>
      <List>
        <ConflictsSubheader>Conflicts</ConflictsSubheader>
        {proposal?.conflictsCheckWaived && (
          <ListItem>
            <ListItemText primary="Conflicts Waived" />
          </ListItem>
        )}
        {proposal.conflictsDocuments.length === 0 && (
          <ListItem>
            <ListItemText primary="None" />
          </ListItem>
        )}
        {(proposal.conflictsDocuments.length ?? 0) > 0 && (
          <>
            {proposal.conflictsDocuments.map((document: WorkDocument) => (
              <ListItem key={document.id.value}>
                <ListItemText primary={document.name?.value} />
                
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() => handleDownloadConflicts(document)}
                    >
                      <GetAppIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
              </ListItem>
            ))}
          </>
        )}
      </List>
    </ListContainer>
  );
}
