import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import {
  Avatar,
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
  Switch,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EntityMemberAPIService from "legal-entities/entities/entity-member/api/entity-member-api-service";
import EntityMember from "legal-entities/entities/entity-member/entity-member";
import LegalEntity from "legal-entities/entities/legal-entity/legal-entity";
import { debounce } from "lodash";
import IndividualAvatar from "marketplace/view/individual-avatar";
import React, { ChangeEvent, useEffect } from "react";
import { useSession } from "users/session/session-context";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";
import ProposalReviewer from "work/values/proposal-reviewer";
import { FreelyPatchableFields } from "work/entities/proposal/proposal";
import { CanceledError } from "axios";
import IndividualAPIService from "marketplace/entities/individual/api/individual-api-service";
import Name from "common/values/name/name";
import Guid from "common/values/guid/guid";
import EntityName from "legal-entities/values/entity-name/entity-name";
import EmailAddress from "common/values/email-address/email-address";

const PopoverContainer = styled("div")(({ theme }) => ({
  minWidth: "22rem",
  padding: theme.spacing(2),
  textAlign: "center",
  width: "min-content",
}));
const SearchField = styled(TextField)(({ theme }) => ({
  width: "100%",
})) as typeof TextField;
const LoaderProgress = styled(CircularProgress)(({ theme }) => ({
  display: "inline-block",
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(4),
}));
const ListContainer = styled(List)(({ theme }) => ({
  maxHeight: "20rem",
  overflowX: "hidden",
  overflowY: "auto",
}));
const MessageInput = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(1.6),
  marginTop: 0,
}));
const ButtonContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gap: theme.spacing(1),
  gridTemplateColumns: "1fr 1fr",
  width: "100%",
}));
const SelectedIcon = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));
const EditingSwitch = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: "0.7em",
  },
  marginRight: 0,
}));

type ReviewerSelectorProps = {
  proposalBuilder?: ProposalBuilder;
  freelyPatchableFields?: FreelyPatchableFields;
  popoverAnchor?: HTMLButtonElement;
  onChange?: (reviewers: ProposalReviewer[]) => void;
  onPopoverClose?: () => void;
};

export default function ReviewerSelector(
  props: Readonly<ReviewerSelectorProps>
) {
  const {
    proposalBuilder,
    freelyPatchableFields,
    popoverAnchor,
    onChange,
    onPopoverClose,
  } = props;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [reviewers, setReviewers] = React.useState<EntityMember[]>([]);
  const [reviewerResults, setReviewerResults] = React.useState<EntityMember[]>(
    []
  );
  const [selectedReviewers, setSelectedReviewers] = React.useState<
    EntityMember[]
  >([]);
  const [selectedEditors, setSelectedEditors] = React.useState<EntityMember[]>(
    []
  );
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [customMessage, setCustomMessage] = React.useState<string>("");

  const session = useSession();

  useEffect(() => {
    if (!popoverAnchor) return;

    let abortController = new AbortController();
    getReviewers(abortController);
    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, [popoverAnchor]);

  async function getReviewers(abortController: AbortController) {
    setIsLoading(true);

    try {
      const entityMemberService = new EntityMemberAPIService(session);
      const entityMemberInfo =
        await entityMemberService.getLegalEntityMembersUserInfo(
          new LegalEntity(session.entities?.[0].entityId),
          abortController
        );
      const teamMemberIds = proposalBuilder?.currentSpec.workAgreement?.team?.memberUserIds ?? [];
      const individualService = new IndividualAPIService(session);
      const teamMembers = await individualService.getUsersProfileInfo(
        teamMemberIds.filter((id) => id !== undefined),
        abortController
      );
      teamMembers.forEach((member) => {
        const entityMember = entityMemberInfo.find((e) =>
          e.userId.isEqualTo(member.userId)
        );
        if (!entityMember) {
          let memberInfo = {
            ...member,
            email:
              member.profile?.email ?? new EmailAddress("unknown@example.com"),
            entityId: member.entityId ?? Guid.generate(),
            entityName: new EntityName(
              member.company?.profile?.name ?? "Unknown"
            ),
            individualId: member.id,
            isAdmin: false,
            isOfficer: false,
            isActive: true,
            memberId: member.id,
            name: new Name(member.profile?.firstName, member.profile?.lastName),
            userId: member.userId ?? undefined,
          };
          const newEntityMember = new EntityMember(memberInfo);
          entityMemberInfo.push(newEntityMember);
        }
      });

      // Do not include current user
      let reviewerPool = entityMemberInfo.filter(
        (reviewer) => reviewer.userId.value !== session.user?.id?.value
      );
      let selected: EntityMember[] = [];
      let editors: EntityMember[] = [];

      let currentReviewers: ProposalReviewer[] = [];
      if (session.context?.viewingAsVendor) {
        currentReviewers = proposalBuilder?.currentSpec.vendorReviewers ?? [];
      } else {
        currentReviewers = proposalBuilder?.currentSpec.clientReviewers ?? [];
      }

      if (currentReviewers.length === 0) {
        currentReviewers = freelyPatchableFields?.reviewers ?? [];
      }

      selected =
        reviewerPool.filter((reviewer) => {
          return currentReviewers?.some((r) =>
            r.userId.isEqualTo(reviewer.userId)
          );
        }) ?? [];
      editors =
        reviewerPool.filter((reviewer) => {
          return currentReviewers?.some(
            (r) => r.userId.isEqualTo(reviewer.userId) && r.canEdit
          );
        }) ?? [];

      setSelectedReviewers(selected);
      setSelectedEditors(editors);
      setReviewers(reviewerPool);
      setReviewerResults(reviewerPool);
    } catch (err) {
      if (err instanceof CanceledError) return;
      console.error(err);
    }
    setIsLoading(false);
  }

  function handleMenuClose() {
    setIsDirty(false);
    setReviewerResults(reviewers);
    setSelectedReviewers([]);
    setSelectedEditors([]);
    setSearchTerm("");
    setCustomMessage("");
    onPopoverClose?.();
  }

  const debouncedSearch = debounce(
    (value) =>
      setReviewerResults(
        reviewers.filter(
          (r) =>
            r.name.toString().toLowerCase().includes(value.toLowerCase()) ||
            r.email.toString().toLowerCase().includes(value.toLowerCase())
        )
      ),
    100
  );

  function search(value: string) {
    setSearchTerm(value);

    if (value === "") {
      setReviewerResults(reviewers);
      return;
    }

    debouncedSearch(value);
  }

  function saveChanges() {
    const proposalReviewers = selectedReviewers.map((reviewer) => {
      return new ProposalReviewer(
        reviewer.userId,
        !!selectedEditors.find((editor: EntityMember) =>
          editor.userId.isEqualTo(reviewer.userId)
        ),
        customMessage
      );
    });

    onChange?.(proposalReviewers);

    handleMenuClose();
  }

  function handleReviewerSelected(
    event: React.MouseEvent<HTMLDivElement>,
    reviewer: EntityMember
  ) {
    event.stopPropagation();

    const alreadySelected = selectedReviewers.some((r) =>
      r.userId.isEqualTo(reviewer.userId)
    );

    if (!alreadySelected) {
      setSelectedReviewers((prevReviewers) => [...prevReviewers, reviewer]);
    } else {
      setSelectedReviewers((prevReviewers) =>
        prevReviewers.filter((r) => r.userId.value !== reviewer.userId.value)
      );
      setSelectedEditors((prevEditors) =>
        prevEditors.filter((r) => r.userId.value !== reviewer.userId.value)
      );
    }

    setIsDirty(true);
  }

  function handleEditorSelected(
    event: ChangeEvent<HTMLInputElement>,
    reviewer: EntityMember
  ) {
    const alreadySelected = selectedReviewers.some((r) =>
      r.userId.isEqualTo(reviewer.userId)
    );
    const alreadyEditing = selectedEditors.some((r) =>
      r.userId.isEqualTo(reviewer.userId)
    );

    if (!alreadyEditing) {
      setSelectedEditors((prevEditors) => [...prevEditors, reviewer]);
      if (!alreadySelected) {
        setSelectedReviewers((prevReviewers) => [...prevReviewers, reviewer]);
      }
    } else {
      setSelectedEditors((prevEditors) =>
        prevEditors.filter((r) => r.userId.value !== reviewer.userId.value)
      );
    }

    setIsDirty(true);
  }

  function getMemberType(reviewer: EntityMember) {
    if (
      proposalBuilder?.currentSpec.workAgreement?.team?.leader.userId.isEqualTo(
        reviewer.userId
      )
    ) {
      return "Team Leader";
    }
    if (
      proposalBuilder?.currentSpec.workAgreement?.team?.memberUserIds.some(
        (id) => id.isEqualTo(reviewer.userId)
      )
    ) {
      return "Team Member";
    }
    return "Coworker";
  }

  return (
    <Popover
      anchorEl={popoverAnchor}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={popoverAnchor !== undefined}
      onClose={handleMenuClose}
    >
      <PopoverContainer>
        <SearchField
          placeholder="Search for reviewer"
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchTerm !== "" && (
                    <IconButton
                      size="small"
                      onClick={async () => {
                        setSearchTerm("");
                        search("");
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            },
          }}
          value={searchTerm}
          onChange={(event) => search(event.target.value)}
        />
        {isLoading && <LoaderProgress size={50} />}
        {!isLoading && (
          <ListContainer dense>
            {reviewerResults.length < 1 && (
              <ListItem>
                <ListItemText>No Reviewers Found</ListItemText>
              </ListItem>
            )}
            {reviewerResults.length > 0 &&
              reviewerResults.map((reviewer: EntityMember) => {
                const isSelected = selectedReviewers?.some((r) =>
                  r.userId.isEqualTo(reviewer.userId)
                );
                const isEditor = selectedEditors?.some((r) =>
                  r.userId.isEqualTo(reviewer.userId)
                );

                return (
                  <ListItemButton
                    disableGutters
                    key={reviewer.userId.value}
                    selected={isSelected}
                    onClick={(event) => handleReviewerSelected(event, reviewer)}
                  >
                    <ListItemAvatar>
                      {isSelected ? (
                        <SelectedIcon>
                          <DoneIcon />
                        </SelectedIcon>
                      ) : (
                        <IndividualAvatar
                          avatarId={reviewer.avatarId}
                          individualId={reviewer.individualId}
                          session={session}
                        />
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={reviewer.name.toString()}
                      secondary={getMemberType(reviewer)}
                    />
                    <ListItemSecondaryAction>
                      <EditingSwitch
                        label={isEditor ? "full edit" : "comment only"}
                        labelPlacement="bottom"
                        control={
                          <Switch
                            checked={isEditor}
                            color="primary"
                            onChange={(event) =>
                              handleEditorSelected(event, reviewer)
                            }
                            onClick={(event) => event.stopPropagation()}
                            size="small"
                          />
                        }
                      />
                    </ListItemSecondaryAction>
                  </ListItemButton>
                );
              })}
          </ListContainer>
        )}
        <MessageInput
          multiline
          variant="outlined"
          minRows={3}
          maxRows={6}
          fullWidth
          label="Message (optional)"
          value={customMessage}
          onChange={(event) => setCustomMessage(event.target.value)}
        />
        <ButtonContainer>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SendIcon />}
            disabled={!isDirty}
            onClick={saveChanges}
          >
            Send
          </Button>
          <Button variant="contained" color="primary" onClick={handleMenuClose}>
            {isDirty ? "Cancel" : "Close"}
          </Button>
        </ButtonContainer>
      </PopoverContainer>
    </Popover>
  );
}
