import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import UndoIcon from "@mui/icons-material/Undo";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Tooltip
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ConfirmResponse, useConfirmDialog } from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import Guid from "common/values/guid/guid";
import {
  createMRTColumnHelper,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Forum from "messaging/entities/forum/forum";
import { enqueueSnackbar } from "notistack";
import FeeRedlineInput from "work/entities/proposal/redlining/fee-redline/view/fee-redline-input";
import {
  FeeScheduleCategoryRedline,
  FeeScheduleRedline,
} from "work/entities/proposal/redlining/fee-schedule-redline/fee-schedule-redline";
import FieldRedline from "work/entities/proposal/redlining/field-redline";
import TextRedlineInput from "work/entities/proposal/redlining/view/redline-field-inputs/text-redline-input";

import { ProposalField } from "work/entities/proposal/proposal";
import { getForumForField } from "work/entities/proposal/utils/comment-utils";
import { ProposalFieldName } from "work/values/constants";
import FeeScheduleBillingCode from "work/values/fee-schedule-billing-code/fee-schedule-billing-code";
import FeeScheduleCategoryDescription from "work/values/fee-schedule-category-description/fee-schedule-category-description";
import FeeScheduleCategoryName from "work/values/fee-schedule-category-name/fee-schedule-category-name";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import FeeScheduleCategoryForm from "work/values/fee-schedule-category/view/fee-schedule-category-form";
import Fee from "work/values/fee/fee";

const ActionsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(1),
  justifyContent: "flex-end",
  marginBottom: theme.spacing(2),
}));
const TableContainer = styled(Box)(({ theme }) => ({
  border: "1px solid #eee",
  marginTop: theme.spacing(2),
  maxWidth: theme.spacing(200),
}));
const RedlineText = styled(TextRedlineInput)(({ theme }) => ({
  margin: 0,
  minWidth: "unset",
  width: "100%",
}));
const ActionButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
}));

type FeeScheduleRedlineSelectionProps = {
  className?: string;
  feeScheduleRedline: FeeScheduleRedline;
  disableEditing?: boolean;
  commentForums: Forum[];
  activeReviewField: ProposalField | undefined;
  onCommentsClicked: (categoryId?: Guid, name?: string) => void;
  onCategoryClicked?: (categoryId: Guid) => void;
  onFeeScheduleRedlineChange: (
    newRedline: FeeScheduleRedline,
    traversalFieldOverride?: ProposalField | null
  ) => void;
};

const FeeScheduleRedlineSelection = (props: Readonly<FeeScheduleRedlineSelectionProps>) => {
  const {
    className,
    feeScheduleRedline,
    commentForums,
    disableEditing,
    activeReviewField,
    onCommentsClicked,
    onCategoryClicked,
    onFeeScheduleRedlineChange
  } = props;

  const confirm = useConfirmDialog();
  const { openDialog, closeDialog } = useDialog();

  function handleRemoveClicked(categoryId: Guid) {
    const newRedline = feeScheduleRedline.removeEntryByFieldId(categoryId);
    onFeeScheduleRedlineChange(
      newRedline,
      {
        name: ProposalFieldName.FeeSchedule,
        id: categoryId
      }
    );
  }

  function renderNameRedline(rowData: FeeScheduleCategoryRedline) {
    const categoryRedline = Object.assign(new FeeScheduleCategoryRedline(rowData.fieldId, null, null), rowData);
    if (!categoryRedline.nameRedline) {
      console.error("Category Redline Name is malformed", categoryRedline);
      return;
    }
    return categoryRedline.isAccepted && categoryRedline.isRemoved ? "Removed" : (
      <RedlineText
        variant="standard"
        size="small"
        margin="none"
        hideLabel
        showPlaceholder
        hideUndoButton={true}
        hideAcceptRejectButtons={true}
        fullWidth
        originalTextFieldRedline={categoryRedline.nameRedline}
        onTextFieldRedlineChange={(
          newTextRedline: FieldRedline<FeeScheduleCategoryName>,
          traversalFieldOverride?: ProposalField | null
        ) => {
          const duplicateName = feeScheduleRedline.redlines.find((redline) => {
            if (!redline.nameRedline) {
              console.error("Category Redline Name is malformed", redline);
              return false;
            }
            return redline.nameRedline.currentEntry === newTextRedline.currentEntry;
          });
          if (duplicateName) {
            enqueueSnackbar("A category with the same name already exists", { variant: "warning" });
            return;
          }

          const existingCategory = categoryRedline.currentEntry ?? categoryRedline.revisedEntry;
          if (!existingCategory || !newTextRedline.currentEntry) {
            console.error("category redline is malformed", categoryRedline);
            return;
          }
          const updatedCategory = new FeeScheduleCategory(
            newTextRedline.currentEntry,
            existingCategory.description,
            existingCategory.fee,
            existingCategory.billingCode,
            existingCategory.id
          );

          const newRedline = feeScheduleRedline.replaceEntryById(updatedCategory.id, updatedCategory);
          onFeeScheduleRedlineChange(
            newRedline,
            traversalFieldOverride
          )
        }}
        readOnly={disableEditing || categoryRedline.isRemoved}
        commentForums={commentForums}
        hideCommentsButton
      />
    );
  }

  function renderDescriptionRedline(rowData: FeeScheduleCategoryRedline) {
    const categoryRedline = Object.assign(new FeeScheduleCategoryRedline(rowData.fieldId, null, null), rowData);
    if (!categoryRedline.descriptionRedline) {
      console.error("Category Redline description is malformed", categoryRedline);
      return;
    }
    return (
      <RedlineText
        variant="standard"
        size="small"
        margin="none"
        fullWidth
        hideLabel
        showPlaceholder
        hideUndoButton={true}
        hideAcceptRejectButtons={true}
        originalTextFieldRedline={categoryRedline.descriptionRedline}
        onTextFieldRedlineChange={async (
          newTextRedline: FieldRedline<FeeScheduleCategoryDescription>,
          traversalFieldOverride?: ProposalField | null
        ) => {
          const existingCategory = categoryRedline.currentEntry ?? categoryRedline.revisedEntry;
          if (!existingCategory || !newTextRedline.currentEntry) {
            console.error("category redline is malformed", categoryRedline);
            return;
          }
          const updatedCategory = new FeeScheduleCategory(
            existingCategory.name,
            newTextRedline.currentEntry,
            existingCategory.fee,
            existingCategory.billingCode,
            existingCategory.id
          );

          const newRedline = feeScheduleRedline.replaceEntryById(updatedCategory.id, updatedCategory);
          onFeeScheduleRedlineChange(
            newRedline,
            traversalFieldOverride
          )
        }}
        readOnly={disableEditing || categoryRedline.isRemoved}
        commentForums={commentForums}
        hideCommentsButton
      />
    );
  }

  function renderFeeRedline(rowData: FeeScheduleCategoryRedline) {
    const categoryRedline = Object.assign(new FeeScheduleCategoryRedline(rowData.fieldId, null, null), rowData);
    if (!categoryRedline.feeRedline) {
      console.error("Category Redline fee is malformed", categoryRedline);
      return;
    }
    return (
      <FeeRedlineInput
        variant="standard"
        size="small"
        margin="none"
        categoryRedline={categoryRedline}
        hideUndoButton={true}
        hideAcceptRejectButtons={true}
        readOnly={disableEditing || categoryRedline.isRemoved}
        onFeeChange={(newFee: Fee) => {
          const existingCategory = categoryRedline.currentEntry ?? categoryRedline.revisedEntry;
          if (!existingCategory) {
            console.error("category redline is malformed", categoryRedline);
            return;
          }
          const updatedCategory = new FeeScheduleCategory(
            existingCategory.name,
            existingCategory.description,
            newFee,
            existingCategory.billingCode,
            existingCategory.id
          );

          const newRedline = feeScheduleRedline.replaceEntryById(updatedCategory.id, updatedCategory);
          onFeeScheduleRedlineChange(newRedline)
        }}
      />
    );
  }

  function renderBillingCodeRedline(rowData: FeeScheduleCategoryRedline) {
    const categoryRedline = Object.assign(new FeeScheduleCategoryRedline(rowData.fieldId, null, null), rowData);
    if (!categoryRedline.billingCodeRedline) {
      console.error("Category Redline billing code is malformed", categoryRedline);
      return;
    }
    return (
      <RedlineText
        variant="standard"
        size="small"
        margin="none"
        fullWidth
        hideLabel
        showPlaceholder
        originalTextFieldRedline={categoryRedline.billingCodeRedline}
        readOnly={disableEditing || categoryRedline.isRemoved}
        hideUndoButton={true}
        hideAcceptRejectButtons={true}
        commentForums={commentForums}
        hideCommentsButton
        onTextFieldRedlineChange={async (
          newTextRedline: FieldRedline<FeeScheduleBillingCode>,
          traversalFieldOverride?: ProposalField | null
        ) => {
          const existingCategory = categoryRedline.currentEntry ?? categoryRedline.revisedEntry;
          if (!existingCategory || !newTextRedline.currentEntry) {
            console.error("category redline is malformed", categoryRedline);
            return;
          }
          const updatedCategory = new FeeScheduleCategory(
            existingCategory.name,
            existingCategory.description,
            existingCategory.fee,
            newTextRedline.currentEntry,
            existingCategory.id
          );

          const newRedline = feeScheduleRedline.replaceEntryById(updatedCategory.id, updatedCategory);
          onFeeScheduleRedlineChange(newRedline, traversalFieldOverride)
        }}
      />
    );
  }

  const columnHelper = createMRTColumnHelper<FeeScheduleCategoryRedline>();
  const columns = [
    columnHelper.accessor(renderNameRedline, {
      header: "name",
      id: "Name",
    }),
    columnHelper.accessor(renderDescriptionRedline, {
      header: "Description",
      id: "Description",
    }),
    columnHelper.accessor(renderFeeRedline, {
      header: "Fee",
      id: "fee",
    }),
    columnHelper.accessor(renderBillingCodeRedline, {
      header: "Billing Code",
      id: "billingCode",
    })
  ];

  /**
   * Handles when the add button is clicked
   */
  function beginAddCategory(): void {
    openDialog({
      title: "Create Fee Schedule Category",
      component: (
        <FeeScheduleCategoryForm
          onCategoryAdded={async (
            newCategory: FeeScheduleCategory
          ) => {
            let newRedline: FeeScheduleRedline;
            if (!newCategory) return;

            const matchingRedline = feeScheduleRedline.getMatchingCategoryRedline(newCategory);
            if (matchingRedline) {
              const response = await confirm({
                title: "Overwrite Category?",
                message: "A category with the same name already exists. Do you want to overwrite it?",
                okButtonText: "Overwrite"
              });

              if (response === ConfirmResponse.Cancel) {
                return;
              }
              newRedline = feeScheduleRedline.replaceEntryById(newCategory.id, newCategory);
            } else {
              newRedline = feeScheduleRedline.addEntry(newCategory);
            }

            onFeeScheduleRedlineChange(
              newRedline,
              {
                name: ProposalFieldName.FeeSchedule,
                id: newCategory.id
              }
            )
          }}
          closeDialog={closeDialog}
        />
      ),
    });
  }

  function renderCommentButton(categoryRedline?: FeeScheduleCategoryRedline) {
    return (
      <Tooltip title="Comments">
        <span>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onCommentsClicked?.(
                categoryRedline?.fieldId,
                categoryRedline?.currentEntry?.name.toString()
                ?? categoryRedline?.revisedEntry?.name.toString()
                ?? categoryRedline?.originalEntry?.name.toString()
                ?? undefined
              );
            }}
          >
            <Badge
              variant="dot"
              color="secondary"
              overlap="circular"
              invisible={
                !getForumForField(
                  ProposalFieldName.FeeSchedule,
                  categoryRedline?.fieldId,
                  commentForums
                )
              }
            >
              <CommentIcon fontSize='medium' color="action" />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  const getCommentIcon = () => (
    <Badge
      variant="dot"
      color="secondary"
      overlap="circular"
      invisible={
        !getForumForField(
          ProposalFieldName.FeeSchedule,
          undefined,
          commentForums
        )
      }
    >
      <CommentIcon fontSize="medium" color="action" />
    </Badge>
  );

  const table = useMaterialReactTable({
    columns,
    data: feeScheduleRedline.redlines,
    enableRowSelection: false,
    enableTableHead: true,
    getRowId: (row) => row.fieldId?.toString() ?? Guid.generate().toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: { right: ['mrt-row-actions'] }
    },
    enableColumnPinning: true,
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    manualPagination: true,
    manualSorting: true,
    enableRowActions: true,
    muiTableProps: ({ table }) => ({
      sx: {
        borderCollapse: table.getRowModel().rows.find(row => 
          row.id === activeReviewField?.id?.toString()) 
          ? "collapse" : "separate"
      }
    }),
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        if (!row.original.fieldId) return;
        onCategoryClicked?.(row.original.fieldId);
      },
      sx: {
        border: row.original.fieldId?.isEqualTo(activeReviewField?.id) ?
          "2px solid #1976d2" : "none"
      }
    }),
    renderTopToolbarCustomActions: () => {
      return (
        <ActionsContainer>
          <Button
            startIcon={<AddIcon />}
            onClick={beginAddCategory}
            variant="outlined"
          >
            Add New Category
          </Button>
          {!feeScheduleRedline.isResolved && (
            <>
              <IconButton
                onClick={() => onFeeScheduleRedlineChange(feeScheduleRedline.acceptAll())}
              >
                <CheckIcon color="success" />
              </IconButton>
              <IconButton
                onClick={() => onFeeScheduleRedlineChange(feeScheduleRedline.rejectAll())}
              >
                <CloseIcon color="error" />
              </IconButton>
            </>
          )}
          {feeScheduleRedline.canBeUndone && (
            <IconButton
              onClick={() => onFeeScheduleRedlineChange(feeScheduleRedline.undoAll(), null)}
            >
              <UndoIcon />
            </IconButton>
          )}
          <IconButton
            onClick={() => onCommentsClicked()}
          >
            {getCommentIcon()}
          </IconButton>
        </ActionsContainer >
      );
    },
    renderRowActions: ({ row }) => {
      const category = Object.assign(new FeeScheduleCategoryRedline(row.original.fieldId, null, null), row.original);

      if (!category) return null;
      if (disableEditing) return renderCommentButton(category);

      return (
        <>
          {category.isResolved && !category.isRemoved && !category.isNewlyAdded && (
            <Tooltip title="Remove Fee">
              <span>
                <ActionButton onClick={() => handleRemoveClicked(category.fieldId)}>
                  <DeleteOutlineIcon />
                </ActionButton>
              </span>
            </Tooltip>
          )}
          {!category.isResolved && (
            <>
              <Tooltip title="Accept Changes">
                <span>
                  <ActionButton
                    onClick={() =>
                      onFeeScheduleRedlineChange(feeScheduleRedline.updateRedline(category.accept()))}
                  >
                    <CheckIcon color="success" />
                  </ActionButton>
                </span>
              </Tooltip>
              <Tooltip title="Reject Changes">
                <span>
                  <ActionButton
                    onClick={() =>
                      onFeeScheduleRedlineChange(feeScheduleRedline.updateRedline(category.reject()))
                    }
                  >
                    <CloseIcon color="error" />
                  </ActionButton>
                </span>
              </Tooltip>
            </>
          )}
          {category.canBeUndone && (
            <Tooltip title="Undo Changes">
              <span>
                <ActionButton onClick={() => onFeeScheduleRedlineChange(
                  feeScheduleRedline.undoRedlineById(category.fieldId),
                  { name: ProposalFieldName.FeeSchedule, id: category.fieldId }
                )}>
                  <UndoIcon />
                </ActionButton>
              </span>
            </Tooltip>
          )}
          {renderCommentButton(category)}
        </>
      );
    },
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
      },
    }),
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        height: "100%",
      },
    },
  });

  return (
    <div className={className}>
      <MaterialReactTable table={table} />
    </div>
  );
};

export default FeeScheduleRedlineSelection;
