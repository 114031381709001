import {
  Avatar,
  CircularProgress, Divider, Link,
  ListItemText, styled, Typography
} from "@mui/material";
import { downloadFile } from "common/helpers/utils";
import { filesize } from "filesize";
import MessagingAPIService from "messaging/api/messaging-api-service";
import Message from "messaging/entities/message/message";
import MessageFileInfo from "messaging/values/attachment/message-file-info";
import { enqueueSnackbar } from "notistack";
import * as React from "react";
import { useEffect } from "react";
import { DefaultExtensionType, defaultStyles, FileIcon } from "react-file-icon";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import Session from "users/session/session";

const SentMessageContainer = styled("div")(({ theme }) => ({
  '@container (min-width: 0px)': {
    maxWidth: '90%'
  },
  '@container (min-width: 600px)': {
    maxWidth: '75%'
  },
  '@container (min-width: 800px)': {
    maxWidth: '50%'
  },
  '@container (min-width: 1000px)': {
    maxWidth: '40%'
  },
  margin: theme.spacing(0, 0, 4, 'auto'),
  width: 'fit-content'
}));
const SentMessageInfo = styled("div")(({ theme }) => ({
  alignItems: "flex-start",
  display: "flex",
  justifyContent: "flex-end",
  marginTop: theme.spacing(0.5),
}));
const MessageSenderName = styled(Typography)(({ theme }) => ({
  margin: 0
}));
const SentMessageTime = styled(Typography)(({ theme }) => ({
  fontSize: '0.8em',
  marginRight: theme.spacing(1.32),
}));
const SentMessageAvatar = styled(Avatar)(({ theme }) => ({
  height: theme.spacing(4),
  marginLeft: theme.spacing(2),
  width: theme.spacing(4)
}));
const SentMessageSubject = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  marginBottom: theme.spacing(1)
}));
const SubjectDivider = styled(Divider)(({ theme }) => ({
  '::before, ::after': {
    borderColor: theme.palette.common.white
  }
}));
const SentMessageAttachment = styled("figure")(({ theme }) => ({
  margin: 0,
  marginTop: theme.spacing(2),
  overflow: "hidden",
  width: "100%",
  "& figcaption": {
    "& .MuiListItemText-primary": {
      fontSize: "0.85em",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& .MuiListItemText-secondary": {
      color: "rgba(255, 255, 255, 0.65)",
      fontSize: "0.8em"
    }
  }
}));
const SentMessageContent = styled('div')(({ theme }) => ({
  backgroundColor: "#3f51b5",
  borderRadius: theme.spacing(2),
  borderBottomRightRadius: 0,
  color: theme.palette.common.white,
  display: "block",
  padding: theme.spacing(3),
  "& > p": {
    margin: 0
  },
  "& a": {
    color: theme.palette.common.white
  },
  "& blockquote": {
    borderLeft: '4px solid #ccc',
    color: '#ccc',
    margin: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  }
}));
const SentMessageLink = styled(Link)(({ theme }) => ({
  color: theme.palette.common.white,
  cursor: "pointer",
  fontSize: theme.spacing(2.2),
  fontWeight: "500",
  textDecoration: "underline",
}));
const FileTypeIconContainer = styled("div")(({ theme }) => ({
  width: theme.spacing(8),
}));
const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
  color: "white",
  display: "flex",
  height: theme.spacing(5),
  width: theme.spacing(5)
}));
const ImageAttachment = styled("img")(({ theme }) => ({
  width: "100%"
}));

type SentMessageProps = {
  session: Readonly<Session>,
  message: Message;
};

export default function SentMessage(props: Readonly<SentMessageProps>) {
  const { session, message } = props;

  const [loadingAttachment, setLoadingAttachment] = React.useState(false);
  const [imageData, setImageData] = React.useState<Blob[]>([]);

  useEffect(() => {
    const hasImagesAttached = message.attachments?.some((attachment: MessageFileInfo) => {
      return attachment.mimeType?.startsWith("image/")
    });

    if (!hasImagesAttached) return;

    loadImages(message);
  }, []);

  async function loadImages(message: Message) {
    setLoadingAttachment(true);

    try {
      const messagingService = new MessagingAPIService(session);
      const abortController = new  AbortController();
      let response = await messagingService.getMessageImages(message, abortController);
      setImageData(response);
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to get message attachment. Please try again.", { variant: "error" });
    } finally {
      setLoadingAttachment(false);
    }
  }

  async function downloadAttachment(message: Message, index: number) {
    try {
      const messagingService = new MessagingAPIService(session);
      const abortController = new  AbortController();
      let response = await messagingService.getMessageAttachmentByIndex(message, index, abortController);
      downloadFile(response, message.attachments[index].name);
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to get message attachment. Please try again.", { variant: "error" });
    }
  }

  return (
    <SentMessageContainer>
      <SentMessageContent>
        <div>
          {(message.subject && message.subject.length > 0) && (
            <SentMessageSubject>
              <SubjectDivider>{message.subject}</SubjectDivider>
            </SentMessageSubject>
          )}
        </div>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          {message.content}
        </ReactMarkdown>
        {message.attachments?.map((attachment: MessageFileInfo, index: number) => (
          <SentMessageAttachment key={attachment.id.value}>
            {loadingAttachment && <LoadingIndicator thickness={2} />}
            {!loadingAttachment && (
              <SentMessageLink onClick={() => downloadAttachment(message, index)}>
                {attachment?.mimeType?.startsWith("image/") ? (
                  <ImageAttachment
                    alt={attachment.name}
                    src={imageData?.[index]?.toString()}
                  />
                ) : (
                  <FileTypeIconContainer onClick={() => downloadAttachment(message, index)}>
                    <FileIcon
                      extension={attachment.name.split(".")[1]}
                      {...defaultStyles[attachment.name.split(".")[1] as DefaultExtensionType]}
                    />
                  </FileTypeIconContainer>
                )}
              </SentMessageLink>
            )}
            <figcaption>
              <ListItemText
                primary={attachment.name}
                secondary={filesize(attachment.fileSize)}
              />
            </figcaption>
          </SentMessageAttachment>
        ))}
      </SentMessageContent>
      <SentMessageInfo>
        <SentMessageTime>
          <time>
            {message.publishedOn?.format('MM/DD/YY hh:mm A')}
          </time>
        </SentMessageTime>
        <MessageSenderName>You</MessageSenderName>
        <SentMessageAvatar />
      </SentMessageInfo>
    </SentMessageContainer>
  );
}
