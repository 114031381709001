import Guid from "common/values/guid/guid";
import Date from "common/values/date/date";
import ProjectDescription from "work/values/project-description/project-description";
import ProjectName from "work/values/project-name/project-name";
import Team from "work/values/team/team";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import EntityClientRepresentative from "work/entities/entity-client-representative/entity-client-representative";
import EntityVendorRepresentative from "work/entities/entity-vendor-representative/entity-vendor-representative";
import WorkDocument from "work/values/work-document/work-document";
import Percent from "common/values/percent/percent";
import AHBoolean from "common/values/boolean/boolean";

export default class WorkAgreement {
    id?: Guid;
    RFPId?: Guid;
    name: ProjectName;
    description?: ProjectDescription;
    client: EntityClientRepresentative;
    vendors: EntityVendorRepresentative[] = [];
    private _team?: Team;
    teamRestricted?: AHBoolean;
    feeSchedule?: FeeScheduleCategory[] = [];
    startDate?: Date;
    endDate?: Date;
    discount: Percent = new Percent(0);

    clientPolicyDocuments: WorkDocument[] = [];
    vendorPolicyDocuments: WorkDocument[] = [];
    conflictsCheckWaived?: AHBoolean;
    conflictsDocuments: WorkDocument[] = [];

    clientTeamTemplateIds: Guid[] = [];
    vendorTeamTemplateIds: Guid[] = [];
    clientFeeScheduleTemplateIds: Guid[] = [];
    vendorFeeScheduleTemplateIds: Guid[] = [];

    hireDate?: Date;
    archiveDate?: Date;

    get team(): Team | undefined {
        return this._team;
    }
    set team(team: Team | undefined) {
        this._team = team;
    }

    constructor(name: ProjectName, client: EntityClientRepresentative) {
        this.name = name;
        this.client = client;
    }

    clone(): WorkAgreement {
        let clonedWorkAgreement = new WorkAgreement(
            this.name.clone(),
            this.client.clone(),
        );

        clonedWorkAgreement.id = this.id?.clone();
        clonedWorkAgreement.RFPId = this.RFPId?.clone();
        clonedWorkAgreement.name = this.name.clone();
        clonedWorkAgreement.description = this.description?.clone();
        clonedWorkAgreement.client = this.client.clone();
        clonedWorkAgreement.vendors = this.vendors.map(vendor => vendor.clone());
        clonedWorkAgreement.team = this.team?.clone();
        clonedWorkAgreement.teamRestricted = this.teamRestricted?.clone();
        clonedWorkAgreement.feeSchedule = this.feeSchedule?.map(feeSchedule => feeSchedule.clone());
        clonedWorkAgreement.startDate = this.startDate?.clone();
        clonedWorkAgreement.endDate = this.endDate?.clone();
        clonedWorkAgreement.discount = this.discount;

        clonedWorkAgreement.clientPolicyDocuments = this.clientPolicyDocuments.map(doc => doc.clone());
        clonedWorkAgreement.vendorPolicyDocuments = this.vendorPolicyDocuments.map(doc => doc.clone());
        clonedWorkAgreement.conflictsDocuments = this.conflictsDocuments.map(doc => doc.clone());
        clonedWorkAgreement.conflictsCheckWaived = this.conflictsCheckWaived;

        clonedWorkAgreement.clientTeamTemplateIds = this.clientTeamTemplateIds.map(id => id.clone());
        clonedWorkAgreement.vendorTeamTemplateIds = this.vendorTeamTemplateIds.map(id => id.clone());
        clonedWorkAgreement.clientFeeScheduleTemplateIds = this.clientFeeScheduleTemplateIds.map(id => id.clone());
        clonedWorkAgreement.vendorFeeScheduleTemplateIds = this.vendorFeeScheduleTemplateIds.map(id => id.clone());

        clonedWorkAgreement.hireDate = this.hireDate?.clone();
        clonedWorkAgreement.archiveDate = this.archiveDate?.clone();

        return clonedWorkAgreement;
    }
}
