import Guid from "common/values/guid/guid";
import Forum from "messaging/entities/forum/forum";
import { ProposalFieldName } from "work/values/constants";

export interface ForumContext {
  audience: string;
  field?: ProposalFieldName;
  fieldId?: Guid;
};

export function getForumForField(
  field: ProposalFieldName,
  fieldId?: Guid,
  commentForums: Forum[] = [],
): Forum | undefined {

  if (!commentForums) return;

  return commentForums.find((forum) => {
    if (!forum.topic?.context) return false;

    const contextJSON: ForumContext = JSON.parse(forum.topic.context);
    if (fieldId) {
      return contextJSON.field === field && contextJSON.fieldId?.value === fieldId.value;
    }
    return contextJSON.field === field && !contextJSON.fieldId;
  });
}
