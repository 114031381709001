import { styled } from "@mui/material/styles";
import Guid from "common/values/guid/guid";
import Percent from "common/values/percent/percent";
import Forum from "messaging/entities/forum/forum";
import { enqueueSnackbar } from "notistack";
import { ProposalField } from "work/entities/proposal/proposal";
import FieldRedline from "work/entities/proposal/redlining/field-redline";
import ProposalRedline from "work/entities/proposal/redlining/proposal-redline";
import RedlinePercentInput from "work/entities/proposal/redlining/view/redline-field-inputs/percent-redline-input";
import { ProposalFieldName } from "work/values/constants";

const ProposalDiscountContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(2, 0, 4, 0),
  maxWidth: "50%",
}));

type DiscountTabProps = {
  isOpen: boolean;
  proposalRedline: ProposalRedline;
  activeReviewField: ProposalField | undefined;
  commentForums: Forum[];
  disableEditing?: boolean;
  onProposalRedlineChange: (
    newRedline: ProposalRedline,
    traversalFieldOverride?: ProposalField | null
  ) => void;
  onTraverseToNewField: (fieldInfo: ProposalField | undefined) => void;
  onCommentsClicked: (
    field: ProposalFieldName,
    id?: Guid,
    title?: string
  ) => void;
};

const DiscountTab = (props: Readonly<DiscountTabProps>) => {
  const {
    isOpen,
    proposalRedline,
    activeReviewField,
    commentForums,
    disableEditing,
    onProposalRedlineChange,
    onTraverseToNewField,
    onCommentsClicked,
  } = props;

  if (!isOpen) return null;

  return (
    proposalRedline && (
      <ProposalDiscountContainer>
        <RedlinePercentInput
          focused={activeReviewField?.name === ProposalFieldName.Discount}
          disabled={disableEditing}
          originalPercentFieldRedline={proposalRedline.discount}
          onCommentsClicked={() =>
            onCommentsClicked(ProposalFieldName.Discount)
          }
          commentForums={commentForums}
          onPercentFieldRedlineChange={(
            updatedRedline: FieldRedline<Percent>,
            traversalFieldOverride?: ProposalField | null
          ) => {
            try {
              onProposalRedlineChange(
                proposalRedline.updateDiscountRedline(updatedRedline),
                traversalFieldOverride
              );
            } catch (error) {
              console.error("Error updating discount", error);
              enqueueSnackbar("Error updating discount", { variant: "error" });
            }
          }}
          onTraverseIn={() => {
            onTraverseToNewField({ name: ProposalFieldName.Discount });
          }}
        />
      </ProposalDiscountContainer>
    )
  );
};

export default DiscountTab;
