import Guid from "common/values/guid/guid";
import { Change, diffLines, diffWords } from "diff";
import _ from "lodash";
import TextChange, {
  TextChangeCollection,
} from "work/entities/proposal/redlining/_diff/text-change";
import {
  RedlineAction,
  RedlineChange,
} from "work/entities/proposal/redlining/redline-change";
import {
  HumanReadableProposalFieldName,
  ProposalFieldName,
} from "work/values/constants";

export interface IRedlineableField {
  isEqualTo(other: any): boolean;
  fromObject(obj: object | string | boolean | number): IRedlineableField | null;
  toJSON(): object | string | boolean | number;
  clone(): IRedlineableField;
  replacesId?: Guid;
}

export interface IComparableFieldEntryWithId extends IRedlineableField {
  id: Guid;
}

export type RedlineSubField = {
  textChangeCollection: TextChangeCollection;
  diffWords: boolean;
};

export default class FieldRedline<T extends IRedlineableField> {
  private _prototype: T;
  private _field: ProposalFieldName;
  private _fieldId: Guid | null;
  private _originalEntry: T | null;
  private _revisedEntry: T | null;
  private _currentEntry: T | null | undefined;
  private _sessionHistory: RedlineChange[] = [];
  private _diffWords: boolean;
  private _originalRedline: FieldRedline<T>;

  private _textChangeCollection: TextChangeCollection =
    new TextChangeCollection();
  private _subFieldTextChangeCollection: Map<keyof T, RedlineSubField> =
    new Map();

  constructor(
    prototype: T,
    field: ProposalFieldName,
    fieldId: Guid | null,
    originalEntry: T | null,
    revisedEntry: T | null,
    changes?: TextChangeCollection,
    diffWords: boolean = false,
    originalRedline?: FieldRedline<T>
  ) {
    this._prototype = prototype;
    this._field = field;
    this._fieldId = fieldId;
    this._originalEntry = originalEntry;
    this._revisedEntry = revisedEntry;
    this._diffWords = diffWords;
    this._originalRedline = originalRedline ?? this;

    if (changes) {
      this._textChangeCollection = changes;
    } else {
      this.updateDiff();
    }
    this.resetCurrentEntry();
  }

  public get field(): ProposalFieldName {
    return this._field;
  }
  public get fieldId(): Guid | null {
    return this._fieldId;
  }

  public get label(): string {
    return HumanReadableProposalFieldName[this._field];
  }

  public get originalEntry(): T | null {
    return this._originalEntry;
  }
  public get revisedEntry(): T | null {
    return this._revisedEntry;
  }
  public get currentEntry(): T | null | undefined {
    return this._currentEntry;
  }

  public get changes(): TextChange[] {
    return this._textChangeCollection.changes;
  }

  public get originalRedline(): FieldRedline<T> {
    return this._originalRedline;
  }
  public set originalRedline(original: FieldRedline<T>) {
    this._originalRedline = original;
  }

  set changes(newChanges: TextChange[]) {
    this._textChangeCollection.changes = newChanges;
  }

  public get textChangeCollection(): TextChangeCollection {
    return this._textChangeCollection;
  }
  public set textChangeCollection(newCollection: TextChangeCollection) {
    this._textChangeCollection = newCollection;
  }

  public get sessionHistory(): RedlineChange[] {
    return this._sessionHistory;
  }

  public get isAdded(): boolean {
    return (
      (this.originalEntry === null && this.revisedEntry !== null) ||
      (this.revisedEntry === null && !!this.currentEntry)
    );
  }
  public get isNewlyAdded(): boolean {
    return Boolean(
      this._currentEntry && !this.wasRedlined && !this._revisedEntry
    );
  }

  public get isRemoved(): boolean {
    if (this.currentEntry) return false;
    return (
      (this.revisedEntry !== null && this.currentEntry === null) ||
      (this.originalEntry !== null && this.revisedEntry === null)
    );
  }
  public get isNewlyRemoved(): boolean {
    return this.revisedEntry !== null && this.currentEntry === null;
  }

  public get isRevised(): boolean {
    return this._currentEntry !== undefined && !this.isAccepted;
  }
  public get isNewlyRevised(): boolean {
    return Boolean(
      this._currentEntry && !this.wasRedlined && this._revisedEntry
    );
  }

  public get wasRedlined(): boolean {
    if (this._originalEntry === null && this._revisedEntry === null) {
      return false;
    } else if (this._revisedEntry === null) {
      return true;
    } else {
      return !this.revisedEntry?.isEqualTo(this.originalEntry);
    }
  }

  public get isResolved(): boolean {
    return this._currentEntry !== undefined;
  }
  public get canBeUndone(): boolean {
    return (this.wasRedlined && this.isResolved) || this.isRevised;
  }

  public get isAccepted(): boolean {
    if (this._currentEntry === null && this._revisedEntry === null) {
      return true;
    }
    return this._currentEntry?.isEqualTo(this._revisedEntry) ?? false;
  }
  public get isRejected(): boolean {
    return this.isResolved && this.wasRedlined && !this.isAccepted;
  }

  public registerSubField(fieldName: keyof T, diffWords: boolean = false) {
    if (this._subFieldTextChangeCollection.has(fieldName)) return;
    this._subFieldTextChangeCollection.set(fieldName, {
      textChangeCollection: new TextChangeCollection(),
      diffWords,
    });
  }
  public getSubFieldTextChanges(fieldName: keyof T): TextChangeCollection {
    const subFieldChanges =
      this._subFieldTextChangeCollection.get(fieldName)?.textChangeCollection;
    if (!subFieldChanges) throw new Error("Subfield not registered");
    return subFieldChanges;
  }

  public add(newEntry: T): FieldRedline<T> {
    const newRedline = this.clone();
    newRedline._currentEntry = newEntry;
    newRedline.updateDiff();
    newRedline._sessionHistory = [];

    if (newEntry?.isEqualTo(this._originalRedline.currentEntry)) {
      return newRedline;
    }

    let action = RedlineAction.Add;
    let changes = newRedline._textChangeCollection.changes;
    if (this._originalRedline.currentEntry) {
      action = RedlineAction.ReEdit;
      changes = this._originalRedline._textChangeCollection.changes;
    }
    const redlineChange = new RedlineChange(
      this._field,
      this._fieldId ?? undefined,
      true,
      action,
      changes
    );
    newRedline._sessionHistory.push(redlineChange);
    return newRedline;
  }

  public edit(newEntry: T | null): FieldRedline<T> {
    const newRedline = this.clone();
    newRedline._sessionHistory = [];

    newRedline._currentEntry = newEntry;

    newRedline.updateDiff();

    let action = RedlineAction.Edit;
    if (
      !this._originalRedline.currentEntry?.isEqualTo(
        this._originalRedline.revisedEntry
      )
    ) {
      action = RedlineAction.ReEdit;
    }
    const redlineChange = new RedlineChange(
      this._field,
      this._fieldId ?? undefined,
      this.isResolved,
      action,
      this._originalRedline._textChangeCollection.changes
    );
    newRedline._sessionHistory.push(redlineChange);

    return newRedline;
  }

  public remove(): FieldRedline<T> {
    if (this._currentEntry === null) {
      console.warn("can't remove a redline that hasn't been modified");
      return this.clone();
    }

    const newRedline = this.clone();
    newRedline._sessionHistory = [];

    newRedline._currentEntry = null;
    newRedline.updateDiff();
    const redlineChange = new RedlineChange(
      this._field,
      this._fieldId ?? undefined,
      true,
      RedlineAction.Remove,
      this._originalRedline._textChangeCollection.changes
    );

    newRedline._sessionHistory.push(redlineChange);
    return newRedline;
  }

  public accept(): FieldRedline<T> {
    const newRedline = this.clone();
    newRedline._sessionHistory = [];

    newRedline._currentEntry = newRedline._revisedEntry;

    newRedline.updateDiff();

    if (!this._originalRedline.isAccepted) {
      const redlineChange = new RedlineChange(
        this._field,
        this._fieldId ?? undefined,
        false,
        RedlineAction.Accept,
        this._textChangeCollection.changes
      );
      newRedline._sessionHistory.push(redlineChange);
    }
    return newRedline;
  }

  public reject(): FieldRedline<T> {
    const newRedline = this.clone();
    newRedline._sessionHistory = [];

    newRedline._currentEntry = newRedline._originalEntry;
    newRedline.updateDiff();

    if (!this._originalRedline.isRejected) {
      const redlineChange = new RedlineChange(
        this._field,
        this._fieldId ?? undefined,
        false,
        RedlineAction.Reject,
        this._textChangeCollection.changes
      );
      newRedline._sessionHistory.push(redlineChange);
    }
    return newRedline;
  }

  public undo(): FieldRedline<T> {
    if (this._currentEntry === undefined) {
      console.warn("can't undo a redline that hasn't been modified");
      return this.clone();
    }

    const newRedline = this.clone();
    newRedline._sessionHistory = [];

    newRedline.resetCurrentEntry();
    newRedline.updateDiff();

    let action: RedlineAction | undefined;
    let changes = this._originalRedline._textChangeCollection.changes;
    let resolved = true;
    if (this._originalRedline.wasRedlined && this._originalRedline.isAccepted) {
      action = RedlineAction.UndoAccept;
      changes = this.getInitialChanges(changes);
      resolved = false;
    } else if (
      this._originalRedline.wasRedlined &&
      this._originalRedline.isRejected
    ) {
      action = RedlineAction.UndoReject;
      changes = this.getInitialChanges(changes);
      resolved = false;
    } else if (this._originalRedline.isResolved) {
      action = RedlineAction.UndoEdit;
    }

    if (action) {
      const redlineChange = new RedlineChange(
        this._field,
        this._fieldId ?? undefined,
        resolved,
        action,
        changes
      );
      newRedline._sessionHistory.push(redlineChange);
    }
    return newRedline;
  }

  private getInitialChanges(changes: TextChange[]) {
    changes = new Array<TextChange>();
    const previous = this._originalRedline._originalEntry?.toString() ?? "";
    const next = this._originalRedline._revisedEntry?.toString() ?? "";
    const diff = this._diffWords
      ? diffWords(previous, next)
      : diffLines(previous, next);

    diff.forEach((change: Change) => {
      changes.push(new TextChange(this._textChangeCollection, change));
    });
    return changes;
  }

  public clearSessionHistory() {
    this._sessionHistory = [];
  }

  public clone(originalRedline?: FieldRedline<T>): FieldRedline<T> {
    const clone = FieldRedline.fromObject<T>(
      this._prototype,
      this.toJSON(),
      this._originalRedline || this
    );
    clone._originalRedline = originalRedline ?? clone._originalRedline;
    return clone;
  }

  public toJSON() {
    return {
      _field: this._field,
      _fieldId: this._fieldId ? this._fieldId.toJSON() : this._fieldId,
      _originalEntry: this._originalEntry
        ? this._originalEntry.toJSON()
        : this._originalEntry,
      _revisedEntry: this._revisedEntry
        ? this._revisedEntry.toJSON()
        : this._revisedEntry,
      _currentEntry: this._currentEntry
        ? this._currentEntry.toJSON()
        : this._currentEntry,
      _sessionHistory: this._sessionHistory.map((change) => change.toJSON()),
      _diffWords: this._diffWords,
      _textChangeCollection: this._textChangeCollection.toJSON(),
    };
  }

  public static fromObject<T extends IRedlineableField>(
    prototype: T,
    obj: any,
    originalRedline?: FieldRedline<T>
  ): FieldRedline<T> {
    const field = obj._field;
    const fieldId = Guid.fromObject(obj._fieldId) ?? null;
    const originalEntry = prototype.fromObject(obj._originalEntry) as T | null;
    const revisedEntry = prototype.fromObject(obj._revisedEntry) as T | null;
    const currentEntry =
      obj._currentEntry === null || obj._currentEntry === undefined
        ? obj._currentEntry
        : (prototype.fromObject(obj._currentEntry) as T | null);
    const sessionHistory = new Array<RedlineChange>();
    if (obj._sessionHistory) {
      obj._sessionHistory.forEach((change: any) => {
        sessionHistory.push(RedlineChange.fromObject(change));
      });
    }

    const changes = obj._textChangeCollection?.changes
      ? TextChangeCollection.fromObjects(obj._textChangeCollection.changes)
      : undefined;
    const newFieldRedline = new FieldRedline<T>(
      prototype,
      field,
      fieldId,
      originalEntry,
      revisedEntry,
      changes,
      obj._diffWords,
      originalRedline
    );
    newFieldRedline._currentEntry = currentEntry;
    newFieldRedline._sessionHistory = sessionHistory;
    return newFieldRedline;
  }

  private resetCurrentEntry() {
    if (this._originalEntry === null && this.revisedEntry === null) {
      this._currentEntry = null;
    } else if (
      this._revisedEntry &&
      this._originalEntry &&
      this._revisedEntry.isEqualTo(this._originalEntry)
    ) {
      this._currentEntry = this._originalEntry;
    } else {
      this._currentEntry = undefined;
    }
  }

  private updateDiff() {
    this._textChangeCollection = new TextChangeCollection();
    if (
      this._originalEntry === null &&
      this._revisedEntry === null &&
      !this._currentEntry
    ) {
      return;
    }

    let previous: string;
    let next: string;
    if (this._currentEntry !== undefined) {
      previous = this._revisedEntry?.toString() ?? "";
      next = this._currentEntry?.toString() ?? "";
    } else {
      previous = this._originalEntry?.toString() ?? "";
      next = this._revisedEntry?.toString() ?? "";
    }

    const diff = this._diffWords
      ? diffWords(previous, next)
      : diffLines(previous, next);

    diff.forEach((change: Change) => {
      this._textChangeCollection.changes.push(
        new TextChange(this._textChangeCollection, change)
      );
    });

    this.updateSubFieldDiffs();
  }

  private updateSubFieldDiffs() {
    for (const [fieldName, subFieldChanges] of this
      ._subFieldTextChangeCollection) {
      const previous = this._revisedEntry?.[fieldName]?.toString() ?? "";
      const next = this._currentEntry?.[fieldName]?.toString() ?? "";
      const diff = subFieldChanges.diffWords
        ? diffWords(previous, next)
        : diffLines(previous, next);
      subFieldChanges.textChangeCollection = new TextChangeCollection();
      diff.forEach((change: Change) => {
        subFieldChanges.textChangeCollection.changes.push(
          new TextChange(subFieldChanges.textChangeCollection, change)
        );
      });
    }
  }
}

export class FieldRedlineArray<T extends IComparableFieldEntryWithId> {
  private _prototype: T;
  private _field: ProposalFieldName;
  private _sessionHistory: RedlineChange[] = [];
  private _subFieldsToRegister: { fieldName: keyof T; diffWords: boolean }[] =
    [];
  private _lastEntryUpdated: Guid | undefined;
  protected _redlines: FieldRedline<T>[] = [];

  constructor(
    prototype: T,
    proposalField: ProposalFieldName,
    originalEntries: T[],
    revisedEntries: T[]
  ) {
    this._prototype = prototype;
    this._field = proposalField;

    originalEntries.forEach((originalEntry) => {
      let fieldRedline: FieldRedline<T> | undefined;

      const modifiedEntry = revisedEntries.find((revisedEntry: T) =>
        revisedEntry.replacesId?.isEqualTo(originalEntry.id)
      );
      if (modifiedEntry !== undefined) {
        fieldRedline = new FieldRedline<T>(
          prototype,
          proposalField,
          originalEntry.id,
          originalEntry,
          modifiedEntry
        );
      }

      const remainingEntry = revisedEntries.find((revisedEntry: T) =>
        revisedEntry.id?.isEqualTo(originalEntry.id)
      );
      if (remainingEntry) {
        fieldRedline =
          fieldRedline ??
          new FieldRedline<T>(
            prototype,
            proposalField,
            originalEntry.id,
            originalEntry,
            originalEntry
          );
      }

      fieldRedline =
        fieldRedline ??
        new FieldRedline<T>(
          prototype,
          proposalField,
          originalEntry.id,
          originalEntry,
          null
        );
      this._redlines.push(fieldRedline);
    });

    const addedEntries = revisedEntries
      .filter((revisedEntry) => !revisedEntry.replacesId)
      .filter(
        (revisedEntry) =>
          !originalEntries.find((originalEntry) =>
            originalEntry.id?.isEqualTo(revisedEntry.id)
          )
      );
    addedEntries.forEach((addedEntry) => {
      this._redlines.push(
        new FieldRedline<T>(
          prototype,
          proposalField,
          addedEntry.id,
          null,
          addedEntry
        )
      );
    });
  }

  public get field(): ProposalFieldName {
    return this._field;
  }

  public get redlines(): FieldRedline<T>[] {
    return this._redlines;
  }

  public get sessionHistory(): RedlineChange[] {
    return [
      ...this._sessionHistory,
      ...this._redlines.flatMap((redline) => redline.sessionHistory),
    ];
  }

  public get isRevised(): boolean {
    return this._redlines.some((redline) => redline.isRevised);
  }

  public get wasRedlined(): boolean {
    return this._redlines.some((redline) => redline.wasRedlined);
  }

  public get isResolved(): boolean {
    return this._redlines.every((redline) => redline.isResolved);
  }
  public get canBeUndone(): boolean {
    return this._redlines.some((redline) => redline.canBeUndone);
  }

  public get isAccepted(): boolean {
    return this._redlines.every((redline) => redline.isAccepted);
  }

  public get isEmpty(): boolean {
    return this._redlines.every((redline) => redline.currentEntry === null);
  }

  public get currentIds(): Guid[] {
    return this._redlines
      .map((redline) => redline.currentEntry?.id)
      .filter((id) => id !== undefined) as Guid[];
  }

  public get lastEntryUpdated(): Guid | undefined {
    return this._lastEntryUpdated;
  }

  public clearSessionHistory(): FieldRedlineArray<T> {
    const newRedlineArray = this.clone();
    newRedlineArray._sessionHistory = [];
    newRedlineArray._redlines.map((redline) => redline.clearSessionHistory());
    return newRedlineArray;
  }

  public registerSubField(fieldName: keyof T, diffWords: boolean = false) {
    this._subFieldsToRegister.push({ fieldName, diffWords });
    this._redlines.forEach((redline) =>
      redline.registerSubField(fieldName, diffWords)
    );
  }

  public addEntry(entry: T): FieldRedlineArray<T> {
    const newRedlineArray = this.clone();

    const existingFieldRedline = newRedlineArray._redlines.find(
      (redline) =>
        redline.revisedEntry?.id.isEqualTo(entry.id) ||
        redline.originalEntry?.id.isEqualTo(entry.id)
    );

    if (existingFieldRedline?.currentEntry)
      throw new Error("Entry already exists in redline");

    if (
      existingFieldRedline?.revisedEntry &&
      existingFieldRedline?.originalEntry
    ) {
      // Entry was removed and added again
      newRedlineArray._redlines[
        newRedlineArray._redlines.indexOf(existingFieldRedline)
      ] = existingFieldRedline.undo();
      newRedlineArray._lastEntryUpdated = entry.id;
      return newRedlineArray;
    }
    if (existingFieldRedline?.revisedEntry) {
      // Entry addition was requested in redline
      newRedlineArray._redlines[
        newRedlineArray._redlines.indexOf(existingFieldRedline)
      ] = existingFieldRedline.accept();
      newRedlineArray._lastEntryUpdated = entry.id;
      return newRedlineArray;
    }
    if (existingFieldRedline?.originalEntry) {
      // Entry removal was requested in redline
      newRedlineArray._redlines[
        newRedlineArray._redlines.indexOf(existingFieldRedline)
      ] = existingFieldRedline.reject();
      newRedlineArray._lastEntryUpdated = entry.id;
      return newRedlineArray;
    }

    const fieldRedline = new FieldRedline<T>(
      this._prototype,
      this._field,
      entry.id,
      null,
      null
    );
    newRedlineArray._redlines.push(fieldRedline.add(entry));
    newRedlineArray._lastEntryUpdated = entry.id;

    return newRedlineArray;
  }

  public getFieldRedlineById(fieldId: Guid): FieldRedline<T> | undefined {
    return this._redlines.find((fieldRedline) => {
      return (
        fieldRedline.currentEntry?.id.isEqualTo(fieldId) ||
        fieldRedline.revisedEntry?.id.isEqualTo(fieldId) ||
        fieldRedline.originalEntry?.id.isEqualTo(fieldId) ||
        fieldRedline.fieldId?.isEqualTo(fieldId)
      );
    });
  }

  public replaceEntryById(fieldId: Guid, newEntry: T): FieldRedlineArray<T> {
    if (!fieldId) throw new Error("No id provided");
    const newRedlineArray = this.clone();
    const targetIndex = newRedlineArray._redlines.findIndex(
      (fieldRedline) =>
        fieldRedline.currentEntry?.id.isEqualTo(fieldId) ||
        fieldRedline.revisedEntry?.id.isEqualTo(fieldId) ||
        fieldRedline.originalEntry?.id.isEqualTo(fieldId)
    );
    if (targetIndex === -1) throw new Error("Entry not found");
    const targetRedline = newRedlineArray._redlines[targetIndex];
    const newRedline = targetRedline.edit(newEntry);
    newRedlineArray._redlines[targetIndex] = newRedline;
    newRedlineArray._lastEntryUpdated = fieldId;
    return newRedlineArray;
  }

  public removeAll(): FieldRedlineArray<T> {
    let newRedlineArray = this.clone();
    for (const redline of this._redlines) {
      if (redline.fieldId) {
        newRedlineArray = newRedlineArray.removeEntryByFieldId(redline.fieldId);
      }
    }
    return newRedlineArray;
  }
  public removeEntryByFieldId(fieldId?: Guid): FieldRedlineArray<T> {
    if (!fieldId) throw new Error("No id provided");
    const newRedlineArray = this.clone();
    const targetIndex = newRedlineArray._redlines.findIndex((fieldRedline) =>
      fieldRedline.fieldId?.isEqualTo(fieldId)
    );
    if (targetIndex === -1) throw new Error("Entry not found");
    const targetFieldRedline = newRedlineArray._redlines[targetIndex];

    if (targetFieldRedline.isNewlyAdded && !targetFieldRedline.originalEntry) {
      newRedlineArray._redlines.splice(targetIndex, 1);
      const redlineChange = new RedlineChange(
        this._field,
        fieldId,
        true,
        RedlineAction.Remove,
        targetFieldRedline.textChangeCollection.changes
      );
      this._sessionHistory.push(redlineChange);
    } else {
      newRedlineArray._redlines[targetIndex] = targetFieldRedline.remove();
      newRedlineArray._lastEntryUpdated = fieldId;
    }

    return newRedlineArray;
  }

  public acceptAll(): FieldRedlineArray<T> {
    let newRedlineArray = this.clone();
    for (const redline of this._redlines) {
      if (redline.fieldId) {
        newRedlineArray = newRedlineArray.acceptRedlineById(redline.fieldId);
      }
    }
    return newRedlineArray;
  }
  public acceptRedlineById(fieldId: Guid): FieldRedlineArray<T> {
    const newRedline = this.clone();
    const target = newRedline._redlines.find((redline) =>
      redline.fieldId?.isEqualTo(fieldId)
    );
    if (target) {
      const index = newRedline._redlines.indexOf(target);
      newRedline._redlines[index] = target.accept();
      newRedline._lastEntryUpdated = fieldId;
    }
    return newRedline;
  }

  public rejectAll(): FieldRedlineArray<T> {
    let newRedlineArray = this.clone();
    for (const redline of this._redlines) {
      if (redline.fieldId) {
        newRedlineArray = newRedlineArray.rejectRedlineById(redline.fieldId);
      }
    }
    return newRedlineArray;
  }
  public rejectRedlineById(fieldId: Guid): FieldRedlineArray<T> {
    const newRedlineArray = this.clone();
    const targetIndex = newRedlineArray._redlines.findIndex((redline) =>
      redline.fieldId?.isEqualTo(fieldId)
    );
    if (targetIndex === -1) throw new Error("Redline not found");
    const targetRedline = newRedlineArray._redlines[targetIndex];
    newRedlineArray._redlines[targetIndex] = targetRedline.reject();
    newRedlineArray._lastEntryUpdated = fieldId;
    return newRedlineArray;
  }

  public undoAll(): FieldRedlineArray<T> {
    let newRedlineArray = this.clone();
    for (const redline of this._redlines) {
      if (redline.fieldId) {
        newRedlineArray = newRedlineArray.undoRedlineById(redline.fieldId);
      }
    }
    return newRedlineArray;
  }
  public undoRedlineById(fieldId: Guid): FieldRedlineArray<T> {
    const newRedlineArray = this.clone();
    const target = newRedlineArray._redlines.find((redline) =>
      redline.fieldId?.isEqualTo(fieldId)
    );
    if (target?.isNewlyAdded && !target.originalEntry) {
      newRedlineArray._redlines.splice(
        newRedlineArray._redlines.indexOf(target),
        1
      );
      const redlineChange = new RedlineChange(
        this._field,
        fieldId,
        true,
        RedlineAction.Remove,
        target.textChangeCollection.changes
      );
      this._sessionHistory.push(redlineChange);
    } else if (target) {
      const index = newRedlineArray._redlines.indexOf(target);
      newRedlineArray._redlines[index] = target.undo();
    }
    return newRedlineArray;
  }

  public updateRedline(fieldRedline: FieldRedline<T>): FieldRedlineArray<T> {
    const newRedlineArray = this.clone();
    const targetIndex = newRedlineArray._redlines.findIndex((redline) =>
      redline.fieldId?.isEqualTo(fieldRedline.fieldId)
    );
    if (targetIndex === -1) throw new Error("Entry not found");
    newRedlineArray._redlines[targetIndex] = fieldRedline;
    newRedlineArray._lastEntryUpdated = fieldRedline.fieldId ?? undefined;
    return newRedlineArray;
  }

  public static fromArrayObject<T extends IComparableFieldEntryWithId>(
    prototype: T,
    arrayObject: any
  ): FieldRedlineArray<T> {
    const redlineArray = new FieldRedlineArray<T>(
      prototype,
      arrayObject._field,
      [],
      []
    );
    arrayObject._redlines.forEach((fieldRedline: any) => {
      const redlineInstance = FieldRedline.fromObject<T>(
        prototype,
        fieldRedline
      );
      if (redlineInstance) redlineArray._redlines.push(redlineInstance);
    });
    redlineArray._sessionHistory = new Array<RedlineChange>();
    arrayObject._sessionHistory.forEach((change: any) => {
      redlineArray._sessionHistory.push(RedlineChange.fromObject(change));
    });
    return redlineArray;
  }

  public clone(): FieldRedlineArray<T> {
    const newRedlineArray = FieldRedlineArray.fromArrayObject<T>(this._prototype, this.toJSON());
    for(const redline of newRedlineArray._redlines) {
      redline.originalRedline = this._redlines.find(
        (originalRedline) => 
          originalRedline.field === redline.field &&
          originalRedline.fieldId?.isEqualTo(redline.fieldId))?.originalRedline || redline.originalRedline;
    }
    return newRedlineArray;
  }

  public toJSON() {
    return {
      _field: this._field,
      _redlines: this._redlines.map((redline) => redline.toJSON()),
      _sessionHistory: this._sessionHistory.map((change) => change.toJSON()),
    };
  }
}
