import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CommentIcon from '@mui/icons-material/Comment';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SaveIcon from '@mui/icons-material/Save';
import {
  Badge, IconButton, Portal,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { ConfirmResponse, useConfirmDialog } from 'app/providers/confirm-dialog';
import { useDialog } from 'app/providers/dialog';
import { CanceledError } from 'axios';
import Loader from 'common/components/loader';
import LoadingButton from 'common/components/loading-button';
import SignalRConnection, { HubName, ProposalSignalType, SignalName } from 'common/helpers/signalr-connection';
import Guid from 'common/values/guid/guid';
import _ from 'lodash';
import Individual from 'marketplace/entities/individual/individual';
import ViewIndividualProfile from 'marketplace/values/individual-profile/view/view-individual-profile';
import MessagingAPIService from 'messaging/api/messaging-api-service';
import Forum from 'messaging/entities/forum/forum';
import Message from 'messaging/entities/message/message';
import Topic from 'messaging/values/topic';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import Session from 'users/session/session';
import { useSession } from 'users/session/session-context';
import ProposalAPIService from 'work/entities/proposal/api/proposal-api-service';
import Proposal from 'work/entities/proposal/proposal';
import { Audience, TopicContext } from 'work/entities/proposal/proposal-forum-topic-context';
import { ProposalTab } from 'work/entities/proposal/redlining/view/proposal-redline-dialog';
import { ForumContext, getForumForField } from 'work/entities/proposal/utils/comment-utils';
import Comments from 'work/entities/proposal/view/comments/comments';
import ConflictsTab from 'work/entities/proposal/view/tabs/conflicts-tab';
import DetailsTab from 'work/entities/proposal/view/tabs/details-tab';
import FeeScheduleTab from 'work/entities/proposal/view/tabs/fee-schedule-tab';
import PoliciesTab from 'work/entities/proposal/view/tabs/policies-tab';
import TeamTab from 'work/entities/proposal/view/tabs/team-tab';
import { HumanReadableProposalFieldName, ProposalFieldName } from 'work/values/constants';

const Header = styled('section')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(1),
  position: 'sticky',
  top: '0px',
  zIndex: 10
}));
const TabsContainer = styled(Tabs)(({ theme }) => ({
  '&.MuiTabs-root': {
    overflow: 'hidden'
  }
}));
const Content = styled('section')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row'
}));
const TabContent = styled('section')(({ theme }) => ({
  alignItems: 'stretch',
  flexDirection: 'column',
  display: 'flex',
  flex: 1,
  margin: theme.spacing(2, 0)
}));
const ActionsContainer = styled('section')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  backgroundColor: theme.palette.background.default,
  bottom: '0px',
  display: 'flex',
  flexDirection: 'row',
  paddingBottom: theme.spacing(2.5),
  position: 'sticky',
  width: '100%',
  zIndex: 10
}));
const ProposalActions = styled('section')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexWrap: 'nowrap'
  },
  alignContent: 'end',
  alignItems: 'start',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(15rem, 1fr))',
  gap: theme.spacing(1),
  minHeight: '64px',
  width: '100%'
}));
const NavTab = styled(Tab)(({ theme }) => ({
  minWidth: 'fit-content',
  paddingLeft: 0
}));
const ProposalActionButton = styled(LoadingButton)(({ theme }) => ({
  minWidth: theme.spacing(24),
  whiteSpace: 'nowrap',
  width: '100%',
  '&.Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.26) !important',
    backgroundColor: 'rgba(0, 0, 0, 0.12) !important'
  }
}));
const MessageButtons = styled('section')(({ theme }) => ({
  marginLeft: theme.spacing(2)
}));
const ButtonContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(2)
}));
const MessageButton = styled(IconButton)(({ theme }) => ({
  paddingBottom: 0
}));
const MessageButtonLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.6em'
}));
const SidePanel = styled(Drawer)(({ theme }) => ({
  position: 'fixed',
  zIndex: theme.zIndex.modal + 1
}));
const SidePanelContainer = styled('div')(({ theme }) => ({
  flex: 1,
  height: '100%'
}));
const SidePanelContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: theme.spacing(2),
  width: '400px'
}));
const TitleBar = styled('div')<{ view: "comments" | undefined }>(({ theme, view }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  paddingBottom: theme.spacing(1)
}));

type ProposalRedlineProps = {
  tab?: ProposalTab,
  proposalId: Guid
};

export default function ProposalRedlineDialog(props: Readonly<ProposalRedlineProps>) {
  const {
    tab,
    proposalId
  } = props;

  const [activeTab, setActiveTab] = React.useState<ProposalTab>(ProposalTab.Details);
  const [currentField, setCurrentField] = React.useState<ProposalFieldName | undefined>();
  const [currentFieldId, setCurrentFieldId] = React.useState<Guid | undefined>();
  const [currentFieldName, setCurrentFieldName] = React.useState<string | undefined>();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingComments, setIsLoadingComments] = React.useState<boolean>(false);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [isCommentSaving, setIsCommentSaving] = React.useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = React.useState<boolean>(false);
  const [isSidePanelOpen, setIsSidePanelOpen] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isFormValid, setIsFormValid] = React.useState<boolean>(true);
  const [currentProposal, setCurrentProposal] = React.useState<Proposal>();

  const [sidePanelView, setSidePanelView] = React.useState<'comments' | undefined>();
  const [commentForums, setCommentForums] = React.useState<Forum[]>([]);
  const [currentComments, setCurrentComments] = React.useState<Message[]>([]);
  const [pendingComments, setPendingComments] = React.useState<Message[]>([]);
  const [currentCommenters, setCurrentCommenters] = React.useState<Individual[]>([]);
  const [isStale, setIsStale] = React.useState<boolean>(false);

  const session = useSession();
  const { openDialog, closeAllDialogs } = useDialog();
  const confirm = useConfirmDialog();

  useEffect(() => {
    let abortController = new AbortController();
    initProposal(abortController);

    setActiveTab(tab ?? ProposalTab.Details);

    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, []);

  async function initProposal(abortController: AbortController) {
    try {
      setIsLoading(true);
      const proposalService = new ProposalAPIService(session);
      const proposalDetails = await proposalService.getProposalById(proposalId, abortController);

      setCurrentProposal(proposalDetails);
      setIsFormValid(true);
      await initForums(abortController);
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
    } finally {
      if (!abortController.signal.aborted) {
        setIsLoading(false);
      }
    }
  }

  async function initForums(abortController: AbortController) {
    try {
      const messagingService = new MessagingAPIService(session);
      const forums = await messagingService.getForums(
        'Work.Proposal',
        proposalId,
        "any",
        abortController
      );
      
      const commentForums = forums.filter(forum => {
        try {
          if (!forum.topic?.context) return false;
          const contextJson: ForumContext = JSON.parse(forum.topic?.context);
          return contextJson.audience.includes('Review');
        } catch (error: any) {
          return false;
        }
      });

      const forumMap = new Map();
      commentForums.forEach((forum) => forumMap.set(forum.id?.value, forum));

      setCommentForums(commentForums);
    } catch (error) {
      console.error(error);
    }
  }

  function handleTabChange(_event: React.ChangeEvent<{}> | null, newTab: ProposalTab) {
    setActiveTab(newTab);
  }

  function handlePrevNextClicked(direction: 'previous' | 'next') {
    const targetTab = direction === 'previous' ? activeTab - 1 : activeTab + 1;
    handleTabChange(null, targetTab ?? activeTab);
  }

  async function handleSaveCommentsClicked() {
    try {
      setIsSaving(true);
      await saveComments();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar('Failed to save comments', { variant: 'error' });
    } finally {
      setIsSaving(false);
    }
  }

  async function handleAcceptClicked() {
    if (!currentProposal) return;

    try {
      const response = await confirm({
        title: "Accept?",
        message: "Doing so will accept the proposal.",
        okButtonText: "Accept",
      });

      if (response === ConfirmResponse.Cancel) return;

      setIsSubmitting(true);

      await currentProposal.approve(session.user?.id);

      closeAllDialogs();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(`${error}`, { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  }

  async function handleToggleSidePanel(
    view?: 'comments',
    field?: ProposalFieldName,
    fieldId?: Guid,
    name?: string
  ) {
    setCurrentField(field);
    setCurrentFieldId(fieldId);
    setCurrentFieldName(name);
    setSidePanelView(view);

    if (
      isSidePanelOpen && (!field || (
        view === sidePanelView &&
        field === currentField &&
        ((!fieldId && !currentFieldId) || fieldId?.isEqualTo(currentFieldId))
      ))) {
      setIsSidePanelOpen(false);
      adjustDialogPosition(true);
      return;
    }

    setIsSidePanelOpen(true);
    adjustDialogPosition(false);
    await loadCommentsForField(field, fieldId);
  }

  async function loadCommentsForField(field?: ProposalFieldName, fieldId?: Guid) {
    setIsLoadingComments(true);

    const matchingForums: Forum[] = [];

    // Get comments from forums that match the field and, optionally, fieldId
    for (const forum of commentForums) {
      if (!forum.topic?.context) continue;

      try {
        const contextJSON: ForumContext = JSON.parse(forum.topic.context);

        if (
          contextJSON.field && contextJSON.field.toLowerCase() === field?.toLowerCase() && (
            (!fieldId && !contextJSON.fieldId) || contextJSON.fieldId?.value === fieldId?.value
          )
        ) {
          matchingForums.push(forum);
        }
      } catch (error: any) {
        continue;
      }
    }

    const forumComments = await loadCommentsForForums(matchingForums);
    const forumCommenters = await loadCommenterInfoForForums(matchingForums);

    for (const pendingComment of pendingComments) {
      if (!pendingComment.forum.topic?.context) continue;

      try {
        const contextJSON: ForumContext = JSON.parse(pendingComment.forum.topic.context);

        if (
          contextJSON.field && contextJSON.field.toLowerCase() === field?.toLowerCase() && (
            (!fieldId && !contextJSON.fieldId) || contextJSON.fieldId?.value === fieldId?.value)
        ) {
          forumComments.push(pendingComment);
        }
      } catch (error: any) {
        continue;
      }
    }

    setCurrentComments(forumComments);
    setCurrentCommenters(forumCommenters);
    setIsLoadingComments(false);
  }

  async function loadCommentsForForums(forums: Forum[]): Promise<Message[]> {
    const comments: Message[] = [];
    const messagingService = new MessagingAPIService(session);
    const abortController = new AbortController();
    for (const forum of forums) {
      try {
        comments.push(...await messagingService.getMessagesByForum(forum, abortController));
      } catch (error) {
        console.error(error);
        continue;
      }
    }

    return comments.sort((a, b) => {
      if (!a.publishedOn && !b.publishedOn) return 0;
      if (!a.publishedOn) return -1;
      if (!b.publishedOn) return 1;
      return a.publishedOn.diff(b.publishedOn);
    });
  }

  async function loadCommenterInfoForForums(forums: Forum[]): Promise<Individual[]> {
    const commenters: Individual[] = [];
    const messagingService = new MessagingAPIService(session);
    const abortController = new AbortController();
    for (const forum of forums) {
      try {
        commenters.push(...await messagingService.getForumSubscriberInfo(forum, abortController));
      } catch (error) {
        console.error(error);
        continue;
      }
    }

    return commenters;
  }

  function adjustDialogPosition(panelOpen: boolean) {
    for (const dialog of document.getElementsByClassName('MuiDialog-root')) {
      dialog.setAttribute(
        'style',
        `padding-right: ${panelOpen ? '0px' : '400px'}; transition: padding-right 225ms;`
      );
    }
  }

  function handleCommentPosted(commentText: string, isExternal: boolean) {
    if (!currentProposal?.id || !session.user) return;

    const { audience, subscriberIds } = getCommentAudienceAndSubscriberIds(
      isExternal,
      currentProposal,
      session
    );
    const context = new TopicContext(
      audience,
      currentField ?? ProposalFieldName.General,
      currentFieldId
    );
    let forum = getCommentForumFromContext(context);

    if (!forum) {
      forum = createForum(currentProposal.id, context, subscriberIds);
      if (!forum) {
        console.error("Failed to create forum");
        return;
      }
      setCommentForums([...commentForums, forum]);
    }

    const newComment = Message.draft(
      forum,
      session.user,
      commentText,
      undefined,
      undefined,
      Guid.generate()
    );
    newComment.publishedOn = moment();
    setPendingComments([...pendingComments, newComment]);
    setCurrentComments([...currentComments, newComment]);
    setIsFormDirty(true);
  }

  function handleCommentEdited(
    messageId: Guid,
    editedText: string,
    isExternal: boolean,
    publishedOn?: moment.Moment) {

    if (!currentProposal?.id || !session.user) return;

    const { audience, subscriberIds } = getCommentAudienceAndSubscriberIds(
      isExternal,
      currentProposal,
      session
    );
    const context = new TopicContext(
      audience,
      currentField ?? ProposalFieldName.General,
      currentFieldId
    );
    let forum = getCommentForumFromContext(context);

    if (!forum) {
      forum = createForum(currentProposal.id, context, subscriberIds);
      if (!forum) {
        console.error("Failed to create forum for edited comment");
        return;
      }
    }

    const editedComment = Message.draft(
      forum,
      session.user,
      editedText,
      undefined,
      undefined,
      messageId
    );
    editedComment.publishedOn = publishedOn ?? moment();
    editedComment.markedForEdit = true;

    // Add or replace the edited comment in the pending comments
    let updatedPendingComments = [...pendingComments];
    const existingCommentIndex = updatedPendingComments.findIndex((c) => c.id?.isEqualTo(messageId));
    if (existingCommentIndex >= 0) {
      updatedPendingComments[existingCommentIndex] = editedComment;
    } else {
      updatedPendingComments.push(editedComment);
    }
    setPendingComments(updatedPendingComments);

    //update the matching current comment with the edited comment
    let updatedCurrentComments = [...currentComments];
    const existingCurrentCommentIndex = updatedCurrentComments.findIndex((c) => c.id?.isEqualTo(messageId));
    if (existingCurrentCommentIndex >= 0) {
      updatedCurrentComments[existingCurrentCommentIndex] = editedComment;
      setCurrentComments(updatedCurrentComments);
    }
    
    setIsFormDirty(true);
  }

  async function handleCommentReadToggled(messageId: Guid) {
    if(!session.user) return;
    let updatedPendingComments = [...pendingComments];
    let updatedCurrentComments = [...currentComments];

    const commentCurrentIndex = updatedCurrentComments.findIndex((c) => c.id?.isEqualTo(messageId));
    if (commentCurrentIndex < 0) {
      console.error("Comment not found in current comments");
      return;
    }

    const comment = updatedCurrentComments[commentCurrentIndex];
    if(comment.senderId?.isEqualTo(session.user.id)) {
      console.warn("User cannot mark their own comment as read/unread");
      return;
    }

    if((!comment.markedForReadReceipt && !comment.isReadByUser(session.user?.id)) || comment.markedForUnread) {
      comment.setRead();
    } else {
      comment.setUnread();
    }

    updatedCurrentComments[commentCurrentIndex] = comment;
    setCurrentComments(updatedCurrentComments);

    const messagePendingIndex = pendingComments.findIndex((c) => c.id?.isEqualTo(messageId));
    if (messagePendingIndex >= 0) {
      updatedPendingComments = updatedPendingComments.filter((c) => !c.id?.isEqualTo(messageId));
    } else {
      updatedPendingComments.push(comment);
    }
    setPendingComments(updatedPendingComments);
  }

  async function handleCommentDeleted(messageId: Guid) {
    if (!currentProposal?.id || !session.user) return;

    let updatedPendingComments = [...pendingComments];
    let updatedCurrentComments = [...currentComments];

    const deletedCurrentIndex = updatedCurrentComments.findIndex((c) => c.id?.isEqualTo(messageId));
    if (deletedCurrentIndex < 0) return;

    const deletedComment = updatedCurrentComments[deletedCurrentIndex];

    if (deletedComment.isDeleted) {
      deletedComment.setUndeleted();
    } else {
      deletedComment.setDeleted();
    }

    const deletedPendingIndex = updatedPendingComments.findIndex((c) => c.id?.isEqualTo(messageId));
    if (deletedPendingIndex >= 0) {
      if (deletedComment.isDeleted) {
        updatedPendingComments[deletedPendingIndex] = deletedComment;
      } else if(!deletedComment.markedForCreation && !deletedComment.markedForEdit) {
        //remove the pending undone deleted comment because it wasn't edited or created during the session
        updatedPendingComments = updatedPendingComments.filter((c) => !c.id?.isEqualTo(messageId));
      }
    } else {
      //this is a new delete request, add to action to pending comments
      updatedPendingComments.push(deletedComment);
    }
    setPendingComments(updatedPendingComments);

    if (deletedCurrentIndex >= 0) {
      updatedCurrentComments[deletedCurrentIndex] = deletedComment;
      setCurrentComments(updatedCurrentComments);
    }
    
    setIsFormDirty(true);
  }

  async function saveComments(): Promise<void> {
    if (!session.user) return;

    try {
      setIsCommentSaving(true);
      const messagingService = new MessagingAPIService(session);
      const response = await messagingService.createBulkMessages(pendingComments);
      const numFailedComments = response.reduce((acc, res) => acc + (res.issues.length > 0 ? 1 : 0), 0);
      if (numFailedComments > 0) {
        enqueueSnackbar(`Failed to save ${numFailedComments} comment(s)`, { variant: 'error' });
      }

      // Update the comments and forums with the newly-created ones
      let updatedCommentForums = [...commentForums];
      let updatedCurrentComments = [...currentComments];
      for (const comment of response) {
        const commentForum = updatedCommentForums.find((f) => f.topic?.isEqualTo(comment.forum.topic));
        if (commentForum) {
          commentForum.id = comment.forum.id;
        }
        const currentCommentIndex = updatedCurrentComments.findIndex((c) => c.id?.isEqualTo(comment.id));
        if (currentCommentIndex >= 0) {
          updatedCurrentComments[currentCommentIndex] = comment;
        }
      }

      setCommentForums(updatedCommentForums);
      
      updatedCurrentComments = updatedCurrentComments.filter((c) => !c.markedForDeletion);
      setCurrentComments(updatedCurrentComments);

      // Remove pending comments that were successfully saved and those locally deleted
      let updatedPendingComments = [...pendingComments.filter((c) => !c.markedForDeletion && !c.isDeleted)];
      for (const comment of pendingComments) {
        const responseComment = response.find((res) => res.id?.isEqualTo(comment.id));
        if (responseComment?.issues.length === 0) {
          updatedPendingComments = updatedPendingComments.filter((c) => !c.id?.isEqualTo(comment.id));
        }
      }
      setPendingComments(updatedPendingComments);
    }
    catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to save comments', { variant: 'error' });
    }
    finally {
      setIsCommentSaving(false);
    }
  }

  function getCommentAudienceAndSubscriberIds(
    isExternal: boolean,
    proposal: Proposal,
    session: Session
  ): { audience: Audience; subscriberIds: Guid[]; } {
    let audience: Audience;
    const subscriberIds: Guid[] = [];
    if (session.user?.id) subscriberIds.push(session.user.id);

    if (isExternal) {
      audience = Audience.AllReviewers;

      if (proposal.creator?.userId) subscriberIds.push(proposal.creator.userId);

      if (proposal.client?.userId) subscriberIds.push(proposal.client.userId);
      subscriberIds.push(...proposal.clientReviewers.map((reviewer) => reviewer.userId));

      if (proposal.team?.leader?.userId) subscriberIds.push(proposal.team.leader.userId);
      subscriberIds.push(...proposal.vendorReviewers.map((reviewer) => reviewer.userId));

    } else if (session.context?.viewingAsVendor) {
      audience = Audience.VendorReviewers;

      if (proposal.team?.leader?.userId) subscriberIds.push(proposal.team.leader.userId);
      subscriberIds.push(...proposal.vendorReviewers.map((reviewer) => reviewer.userId));

    } else {
      audience = Audience.ClientReviewers;

      if (proposal.client?.userId) subscriberIds.push(proposal.client.userId);
      subscriberIds.push(...proposal.clientReviewers.map((reviewer) => reviewer.userId));
    }
    return { audience, subscriberIds: _.uniqBy(subscriberIds, (id) => id.value) };
  }

  function getCommentForumFromContext(context: TopicContext): Forum | undefined {
    for (const forum of commentForums) {
      if (!forum.topic?.context) continue;
      try {
        const forumJSON: ForumContext = JSON.parse(forum.topic.context);
        if (
          forumJSON.audience === context.audience &&
          forumJSON.field && forumJSON.field.toLowerCase() === context.field.toLowerCase()
        ) {
          if (!context.fieldId || context.fieldId?.isEqualTo(forumJSON.fieldId))
            return forum;
        }
      } catch (error) {
        continue;
      }
    }
  }

  function createForum(
    proposalId: Guid,
    context: TopicContext,
    subscriberIds: Guid[]
  ): Forum | undefined {
    if (!session.user) return;

    const topic = new Topic(
      "Work.Proposal",
      proposalId,
      JSON.stringify(context.toJSON())
    );
    return new Forum(
      `Proposal ${context.audience} ${context.field.toString()} comment thread`,
      topic,
      subscriberIds
    );
  }

  function handleViewCommenterProfile(individualId?: Guid) {
    if (!individualId) return;

    openDialog({
      component: (
        <ViewIndividualProfile
          individualId={individualId}
        />
      ),
      titleStyle: {
        position: 'absolute',
        right: 0,
        top: 0,
      },
      contentSxProps: {
        display: 'flex',
        overflowX: 'hidden'
      },
      MuiProps: {
        maxWidth: 'lg',
        fullWidth: true
      }
    });
    setTimeout(() => adjustDialogPosition(false), 500);
  }

  function getSidePanelTitle(): string {
    if (currentFieldName) return `${currentFieldName} Comments`;
    return `${HumanReadableProposalFieldName[currentField ?? ProposalFieldName.General]} Comments`;
  }

  function handleProposalChangedSignal(proposal: Proposal) {
    if (proposal?.id?.value === proposalId.value) {
      setIsStale(true);
    }
  }

  return (
    <>
      <SignalRConnection
        hubName={HubName.Proposals}
        signals={[
          { signal: SignalName.ProposalCreated, handler: handleProposalChangedSignal },
          { signal: SignalName.ProposalUpdated, handler: handleProposalChangedSignal },
          { signal: SignalName.ProposalDeleted, handler: handleProposalChangedSignal }
        ] as ProposalSignalType[]}
      />
      <Header>
        <TabsContainer
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          value={activeTab}
          onChange={handleTabChange}>
          <NavTab
            disabled={activeTab === ProposalTab.Details}
            icon={<NavigateBeforeIcon fontSize='large' />}
            onClick={async () => handlePrevNextClicked('previous')}
          />
          <Tab value={ProposalTab.Details} label="Details" />
          <Tab value={ProposalTab.Team} label="Team" />
          <Tab value={ProposalTab.FeeSchedule} label="Fee Schedule" />
          <Tab value={ProposalTab.Conflicts} label="Conflicts" />
          <Tab value={ProposalTab.Policies} label="Policies" />
          <NavTab
            disabled={activeTab === ProposalTab.Policies}
            icon={<NavigateNextIcon fontSize='large' />}
            onClick={async () => handlePrevNextClicked('next')}
          />
        </TabsContainer>
      </Header>
      <Content>
        <TabContent>
          {isLoading && <Loader />}
          {!isLoading && (
            <>
              <DetailsTab
                activeTab={activeTab}
                proposal={currentProposal}
              />
              <TeamTab
                activeTab={activeTab}
                proposal={currentProposal}
              />
              <FeeScheduleTab
                activeTab={activeTab}
                proposal={currentProposal}
              />
              <ConflictsTab
                activeTab={activeTab}
                proposal={currentProposal}
              />
              <PoliciesTab
                activeTab={activeTab}
                proposal={currentProposal}
              />
            </>
          )}
        </TabContent>
      </Content>
      <ActionsContainer>
        <ProposalActions>
          {(currentProposal?.userCanApprove(session.user) ||
            currentProposal?.userCanReject(session.user)) && (
              <ProposalActions>
                <ProposalActionButton
                  variant="contained"
                  color="success"
                  startIcon={<CheckIcon />}
                  loading={false}
                  disabled={
                    isSubmitting ||
                    isLoading ||
                    !currentProposal?.id ||
                    currentProposal.creator?.userId.isEqualTo(session.user?.id) ||
                    !currentProposal.userCanApprove(session.user)
                  }
                  onClick={handleAcceptClicked}
                >
                  Accept Proposal
                </ProposalActionButton>
                {!currentProposal?.creator?.userId.isEqualTo(session.user?.id) && (
                  <ProposalActionButton
                    variant="contained"
                    color="info"
                    startIcon={<CloseIcon />}
                    loading={false}
                    disabled={isSubmitting || isLoading || !currentProposal?.id}
                    onClick={closeAllDialogs}
                  >
                    Ignore Proposal
                  </ProposalActionButton>
                )}
              </ProposalActions>
            )}
          <ProposalActionButton
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            loading={isSaving}
            disabled={
              currentProposal?.isArchived ||
              isSubmitting ||
              isLoading ||
              isSaving ||
              !isFormValid ||
              !isFormDirty
            }
            onClick={handleSaveCommentsClicked}>
            Save Comments
          </ProposalActionButton>
        </ProposalActions>
        <MessageButtons>
          <ButtonContainer>
            <MessageButton
              size='medium'
              color='primary'
              onClick={() => handleToggleSidePanel('comments', ProposalFieldName.General)}>
              <Badge
                variant="dot"
                color="secondary"
                overlap="circular"
                invisible={
                  !getForumForField(
                    ProposalFieldName.General,
                    undefined,
                    commentForums
                  )
                }
              >
                <CommentIcon fontSize='medium' />
              </Badge>
            </MessageButton>
            <MessageButtonLabel
              variant='button'
              color='primary'>
              Comments
            </MessageButtonLabel>
          </ButtonContainer>
        </MessageButtons>
        <Portal>
          <SidePanel
            open={isSidePanelOpen}
            anchor='right'
            variant='persistent'
          >
            <SidePanelContainer>
              <SidePanelContent>
                <TitleBar view={sidePanelView}>
                  <Typography variant='h5'>
                    {getSidePanelTitle()}
                  </Typography>
                  <IconButton
                    onClick={() => handleToggleSidePanel()}>
                    <CloseIcon />
                  </IconButton>
                </TitleBar>
                {sidePanelView === 'comments' && (
                  <>
                    {isLoadingComments ? <Loader /> : (
                      <Comments
                        proposal={currentProposal}
                        activeComments={currentComments}
                        pendingComments={pendingComments}
                        commenters={currentCommenters}
                        isSaving={isCommentSaving}
                        onCommentPosted={handleCommentPosted}
                        onCommentEdited={handleCommentEdited}
                        onCommentReadToggled={handleCommentReadToggled}
                        onCommentDeleted={handleCommentDeleted}
                        onViewProfile={handleViewCommenterProfile}
                      />
                    )}
                  </>
                )}
              </SidePanelContent>
            </SidePanelContainer>
          </SidePanel>
        </Portal>
      </ActionsContainer>
    </>
  );
}
