import Team from "work/values/team/team";

import { AccountType } from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import TeamName from "work/values/team-name/team-name";
import VendorRepresentativeAPIRequest from "work/entities/entity-vendor-representative/api/request-contracts/vendor-representative-api-request";

export default class WorkTeamTemplateAPIRequest {
  entityId?: string;
  name?: string;
  leader?: VendorRepresentativeAPIRequest;
  memberUserIds?: string[];
  creatorType?: string;
  marketplaceId?: string;

  constructor(
    entityId: Guid,
    team: Team,
    name: TeamName,
    creatorType: AccountType,
    marketplaceId?: Guid
  ) {
    this.entityId = entityId.value;
    this.name = name.value;
    this.leader = new VendorRepresentativeAPIRequest(team.leader);
    this.memberUserIds = team.memberUserIds.map((id) => id.value);
    this.creatorType = creatorType;
    this.marketplaceId = marketplaceId?.value;
  }
}
