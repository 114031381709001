import _ from 'lodash';
import { enqueueSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { PageTab } from './tabs';
import BookmarkAPIService from 'marketplace/entities/bookmark/api/bookmark-api-service';
import Guid from 'common/values/guid/guid';
import ViewIndividualProfile from 'marketplace/values/individual-profile/view/view-individual-profile';
import { ConfirmResponse, useConfirmDialog } from 'app/providers/confirm-dialog';
import { useDialog } from 'app/providers/dialog';
import { useSession } from 'users/session/session-context';
import {
  createMRTColumnHelper,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { styled } from "@mui/material/styles";
import BookmarkedIndividual from 'marketplace/entities/bookmarked-individual/bookmarked-individual';
import { CanceledError } from 'axios';

const TableTopToolbarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  widtgh: "100%",
}));
const LowerTopToolbarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
}));
const TableTitle = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));
const RowTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.1em",
  fontWeight: "600",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

type IndividualBookmarksProps = {
  activeTab: PageTab;
};

export default function IndividualBookmarks(props: Readonly<IndividualBookmarksProps>) {
  const {
    activeTab
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [errorLoading, setErrorLoading] = React.useState(false);
  const [data, setData] = React.useState<BookmarkedIndividual[]>([]);
  const [rowCount, setRowCount] = React.useState(0);

  const navigate = useNavigate();
  const confirm = useConfirmDialog();
  const { openDialog, closeAllDialogs } = useDialog();
  const session = useSession();

  // Close any open dialogs when the URL changes
  useEffect(() => {
    closeAllDialogs();
  }, [location]);

  // Handle URL changes
  useEffect(() => {
    let abortController = new AbortController();

    fetchData(abortController);

    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, [activeTab]);

  const fetchData = async (abortController?: AbortController) => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      let bookmarkService = new BookmarkAPIService(session);
      const bookmarks = await bookmarkService.getAllIndividualBookmarksForUser(abortController);
      setData(bookmarks);
      setRowCount(bookmarks.length);
    } catch (error) {
      if (error instanceof CanceledError) return;
      setErrorLoading(true);
      console.error(error);
      return;
    }
    setErrorLoading(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const columnHelper = createMRTColumnHelper<BookmarkedIndividual>();

  const renderNameColumn = (bookmark: BookmarkedIndividual) => {
    return (
      <RowTitle>
        {`${bookmark.vendor.profile?.firstName} ${bookmark.vendor.profile?.lastName}`}
      </RowTitle>
    );
  };

  const columns = [
    columnHelper.accessor(renderNameColumn, {
      header: "Name",
      id: "name",
    }),
    columnHelper.accessor((bookmark) => bookmark.createdDate.format("MM/DD/YY"), {
      header: "Bookmarked On",
      id: "created",
    }),
  ];

  async function handleDeleteBookmark(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    bookmark: BookmarkedIndividual
  ) {
    event.stopPropagation();

    const response = await confirm({
      title: 'Delete Bookmark?',
      message: 'Do you want to delete?',
      okButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    });

    if (response === ConfirmResponse.Cancel) return;

    const bookmarkService = new BookmarkAPIService(session);
    try {
      await bookmarkService.deleteIndividualBookmark(bookmark.bookmarkId);
      enqueueSnackbar("Deleted bookmark", { variant: 'success' });
      fetchData();
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Couldn't delete bookmark. Please try again", { variant: 'error' });
    }
  }

  function handleViewProfile(profileId?: Guid): void {
    if (!profileId) return;
    openDialog({
      component: (
        <ViewIndividualProfile
          individualId={profileId}
        />
      ),
      titleStyle: {
        position: 'absolute',
        right: 0,
        top: 0,
      },
      contentSxProps: {
        display: 'flex',
        overflowX: 'hidden'
      },
      MuiProps: {
        maxWidth: 'lg',
        fullWidth: false
      }
    });
  }

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableTableHead: true,
    enableColumnPinning: true,
    enableRowActions: true,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => handleViewProfile(row.original.id),
      sx: { cursor: "pointer" },
    }),
    renderTopToolbarCustomActions: () => {
      return (
        <TableTopToolbarContainer>
          <TableTitle variant="h4">
            Bookmarks
          </TableTitle>
          <LowerTopToolbarContainer>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_event, newValue) => navigate(`/marketplace/bookmarks/${newValue}`)}>
              <Tab value={PageTab.Individual} label="Individual" />
              <Tab value={PageTab.Team} label="Team" />
              <Tab value={PageTab.Company} label="Company" />
            </Tabs>
          </LowerTopToolbarContainer>
        </TableTopToolbarContainer>
      );
    },
    getRowId: (row) => row.id?.toString() ?? Guid.generate().toString(),
    initialState: {
      columnPinning: { right: ['mrt-row-actions'] }
    },
    renderRowActions: ({ row, table }) => (
      <Tooltip title="Delete Bookmark">
        <IconButton
          onClick={(event) => handleDeleteBookmark(event, row.original)}
        >
          <DeleteIcon color="error" />
        </IconButton>
      </Tooltip>
    ),
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    manualPagination: true,
    manualSorting: true,
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
      },
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%",
      },
    },
    muiToolbarAlertBannerProps: errorLoading
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    rowCount,
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      // pagination,
      showAlertBanner: errorLoading,
      showProgressBars: isRefetching,
      // sorting,
    },
  });

  return (
    <MaterialReactTable table={table} />
  );
}
