import BoldIcon from "@mui/icons-material/FormatBold";
import FormatClearIcon from '@mui/icons-material/FormatClear';
import ItalicIcon from "@mui/icons-material/FormatItalic";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import UnderlineIcon from "@mui/icons-material/FormatUnderlined";
import SegmentIcon from '@mui/icons-material/Segment';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import { Divider, Paper, Theme, ToggleButton, ToggleButtonGroup, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback, useEffect, useState } from "react";

import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import {
  $createQuoteNode,
  $isHeadingNode,
  $isQuoteNode
} from '@lexical/rich-text';
import {
  $setBlocksType
} from '@lexical/selection';
import { $isTableSelection } from '@lexical/table';
import {
  $findMatchingParent,
  $getNearestBlockElementAncestorOrThrow,
  $getNearestNodeOfType,
  mergeRegister
} from '@lexical/utils';
import {
  $createParagraphNode, $getSelection, $isRangeSelection,
  $isRootOrShadowRoot,
  $isTextNode, COMMAND_PRIORITY_CRITICAL, FORMAT_TEXT_COMMAND, LexicalNode, SELECTION_CHANGE_COMMAND, TextNode
} from 'lexical';

const ToolbarContainer = styled(Paper)(({ theme }) => ({
  border: '1px solid #aaa',
  borderTop: 0,
  borderRadius: 0,
  display: 'flex',
  flexWrap: 'wrap'
}));
const ToolbarButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0.25)
    },
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    }
  }
}));

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  check: 'Check List',
  code: 'Code Block',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote',
};

export default function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] = useState<keyof typeof blockTypeToBlockName>('paragraph');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());

  const isSmallWindowSize = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isMediumWindowSize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element = anchorNode.getKey() === 'root'
        ? anchorNode
        : $findMatchingParent(anchorNode, (e) => {
          const parent = e.getParent();
          return parent !== null && $isRootOrShadowRoot(parent);
        });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));

      if (elementDOM === null) return;

      if ($isListNode(element)) {
        const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
        const type = parentList
          ? parentList.getListType()
          : element.getListType();
        setBlockType(type);
      } else {
        const type = $isHeadingNode(element)
          ? element.getTag()
          : element.getType();
        if (type in blockTypeToBlockName) {
          setBlockType(type as keyof typeof blockTypeToBlockName);
        }
      }
    }
  }, [activeEditor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        $updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL,
    );
  }, [editor, $updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable);
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      })
    );
  }, [$updateToolbar, activeEditor, editor]);

  const clearFormatting = useCallback(() => {
    activeEditor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const anchor = selection.anchor;
        const focus = selection.focus;
        const nodes = selection.getNodes();

        if (anchor.key === focus.key && anchor.offset === focus.offset) {
          return;
        }

        nodes.forEach((node: LexicalNode, idx: number) => {
          // We split the first and last node by the selection
          // So that we don't format unselected text inside those nodes
          if ($isTextNode(node)) {
            let textNode = node;
            if (idx === 0 && anchor.offset !== 0) {
              textNode = textNode.splitText(anchor.offset)[1] || textNode;
            }
            if (idx === nodes.length - 1) {
              textNode = textNode.splitText(focus.offset)[0] || textNode;
            }

            if (textNode.__style !== '') {
              textNode.setStyle('');
            }
            if (textNode.__format !== 0) {
              textNode.setFormat(0);
              $getNearestBlockElementAncestorOrThrow(textNode).setFormat('');
            }
          } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
            node.replace($createParagraphNode(), true);
          } else if ($isDecoratorBlockNode(node)) {
            node.setFormat('');
          }
        });
      }
    });
  }, [activeEditor]);

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if (
        $isRangeSelection(selection) ||
        $isTableSelection(selection)
      ) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      activeEditor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      activeEditor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      activeEditor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      activeEditor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatQuote = () => {
    if (blockType !== 'quote') {
      activeEditor.update(() => {
        const selection = $getSelection();
        if (
          $isRangeSelection(selection) ||
          $isTableSelection(selection)
        ) {
          $setBlocksType(selection, () => $createQuoteNode());
        }
      });
    }
  };

  return (
    <div>
      <ToolbarContainer elevation={0}>
        <ToolbarButtonGroup 
          size={isMediumWindowSize ? "small" : "medium"} 
          disabled={!isEditable}>
          <ToggleButton
            value="bold"
            selected={isBold}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
            }}>
            <BoldIcon fontSize={isSmallWindowSize ? "small" : "medium"} />
          </ToggleButton>
          <ToggleButton
            value="italic"
            selected={isItalic}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
            }}>
            <ItalicIcon fontSize={isSmallWindowSize ? "small" : "medium"} />
          </ToggleButton>
          <ToggleButton
            value="underline"
            selected={isUnderline}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
            }}>
            <UnderlineIcon fontSize={isSmallWindowSize ? "small" : "medium"} />
          </ToggleButton>
          <ToggleButton
            value="strikethrough"
            selected={isStrikethrough}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
            }}>
            <StrikethroughSIcon fontSize={isSmallWindowSize ? "small" : "medium"} />
          </ToggleButton>
        </ToolbarButtonGroup>
        <Divider flexItem orientation="vertical" variant="middle" />
        {blockType in blockTypeToBlockName && activeEditor === editor && (
          <>
            <ToolbarButtonGroup 
              exclusive 
              disabled={!isEditable} 
              size={isMediumWindowSize ? "small" : "medium"}>
              <ToggleButton
                value="paragraph"
                selected={blockType === 'paragraph'}
                onClick={formatParagraph}>
                  <SegmentIcon fontSize={isSmallWindowSize ? "small" : "medium"} />
                </ToggleButton>
              <ToggleButton
                value="bulleted"
                selected={blockType === 'bullet'}
                onClick={formatBulletList}>
                <FormatListBulletedIcon fontSize={isSmallWindowSize ? "small" : "medium"} />
              </ToggleButton>
              <ToggleButton
                value="numbered"
                selected={blockType === 'number'}
                onClick={formatNumberedList}>
                <FormatListNumberedIcon fontSize={isSmallWindowSize ? "small" : "medium"} />
              </ToggleButton>
              <ToggleButton
                value="quote"
                selected={blockType === 'quote'}
                onClick={formatQuote}>
                <FormatQuoteIcon fontSize={isSmallWindowSize ? "small" : "medium"} />
              </ToggleButton>
            </ToolbarButtonGroup>
            <Divider flexItem orientation="vertical" variant="middle" />
            <ToolbarButtonGroup 
              disabled={!isEditable} 
              size={isMediumWindowSize ? "small" : "medium"}>
              <ToggleButton 
                value="clear" 
                selected={false} 
                onClick={clearFormatting}>
                <FormatClearIcon fontSize={isSmallWindowSize ? "small" : "medium"} />
              </ToggleButton>
            </ToolbarButtonGroup>
          </>
        )}
      </ToolbarContainer>
    </div>
  )
}
