import StarBorderIcon from "@mui/icons-material/StarBorder";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import CloseIcon from "@mui/icons-material/Close";
import { Button, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";
import { ConfirmDialogType, ConfirmResponse, useConfirmDialog } from "app/providers/confirm-dialog";
import { MenuButton, MenuButtonRef } from "common/components/menu-button";
import Guid from "common/values/guid/guid";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect } from "react";
import UserNetworkConnectionAPIService from "users/entities/user-network-connection/api/user-network-connection-api-service";
import UserNetworkConnection from "users/entities/user-network-connection/user-network-connection";
import UserNetworkConnectionProfile from "users/entities/user-network-connection/user-network-connection-profile";
import NetworkInvitationAPIService from "users/entities/user-network-invitation/api/network-invitation-api-service";
import NetworkInvitationAPIRequest from "users/entities/user-network-invitation/api/request-contracts/network-invitation-api-request";
import NetworkInvitationInfo from "users/entities/user-network-invitation/network-invitation-info";
import { useSession } from "users/session/session-context";

const InvitationContainer = styled('div')(({ theme }) => ({
  border: "1px solid #111",
  padding: theme.spacing(1)
}));
const MessageTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  marginTop: theme.spacing(1.5)
}));

export enum NetworkActionType {
  Invite = "invite",
  Accept = "accept",
  Reject = "reject",
  Cancel = "cancel"
}
export enum NetworkStatus {
  None = "none",
  Sent = "sent",
  Received = "received",
  InNetwork = "in-network",
  Self = "self"
}
export type NetworkAction = {
  invitation?: NetworkInvitationInfo,
  connection?: UserNetworkConnection,
  userId: Guid,
  action: NetworkActionType
};

type NetworkButtonProps = {
  className?: string;
  variant?: 'contained' | 'outlined' | 'text';
  userId: Guid;
  onNetworkInvitationResolved?: () => void;
}

export default function NetworkButton(props: Readonly<NetworkButtonProps>) {
  const { className, userId, onNetworkInvitationResolved } = props;

  const menuButtonRef = React.useRef<MenuButtonRef>(null);
  const [message, setMessage] = React.useState<string>("");
  const [saving, setSaving] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<NetworkStatus>(NetworkStatus.None);
  const [invitationId, setInvitationId] = React.useState<Guid | null>();
  const [connectionId, setConnectionId] = React.useState<Guid | null>();
  const confirm = useConfirmDialog();
  const buttonVariant = props.variant ?? 'outlined';

  const { enqueueSnackbar } = useSnackbar();
  const session = useSession();

  const getStatusFromSession = useCallback(() => {
    if (!session.networkInvitations) return;

    const connection = session.networkConnections.find(
      (connection: UserNetworkConnectionProfile) => {
        return connection.user.id.value === userId?.value;
      });

    const sentNetworkInvitation = session.networkInvitations.find(
      (invite: NetworkInvitationInfo) => invite.inviter && invite.user.id.value === userId?.value
    );
    const receivedNetworkInvitation = session.networkInvitations.find(
      (invite: NetworkInvitationInfo) => !invite.inviter && invite.user.id.value === userId?.value
    );

    if (!userId) return;

    if (userId.value === session.user?.id?.value) {
      handleUserIsSelf();
      return;
    }
    if (session.isNetworkedWith(userId)) {
      handleInNetwork(connection);
      return;
    }
    if (sentNetworkInvitation) {
      handleInviteSent(sentNetworkInvitation);
      return;
    }
    if (receivedNetworkInvitation) {
      handleInviteReceived(receivedNetworkInvitation.id);
      return;
    }

    handleNotNetworked();
  }, [session, userId]);

  const getExistingStatus = useCallback(async () => {

      getStatusFromSession();
    
  }, [getStatusFromSession]);

  useEffect(() => {
    getExistingStatus();
  }, [userId, getExistingStatus]);

  function handleNotNetworked() {
    setStatus(NetworkStatus.None);
    setConnectionId(null);
    setInvitationId(null);
    if (!userId) {
      console.warn("Unable to update session network status: no networked user id");
      return;
    }
    session.removeNetworkConnection(userId);
  }

  function handleInNetwork(connection?: UserNetworkConnection | UserNetworkConnectionProfile) {
    if(!connection) return;
    setStatus(NetworkStatus.InNetwork);
    setConnectionId(connection.id);
    setInvitationId(null);
    if (connection instanceof UserNetworkConnection) {
      const connectionProfile = connection.toProfileConnection(session.user?.id);
      session.addNetworkConnection(connectionProfile);
    }
    else {
      session.addNetworkConnection(connection);
    }
  }

  function handleInviteSent(invitation: NetworkInvitationInfo) {
    setStatus(NetworkStatus.Sent);
    setConnectionId(null);
    setInvitationId(invitation.id);
    session.addNetworkInvitation(invitation);
  }

  function handleInviteReceived(invitationId: Guid) {
    setStatus(NetworkStatus.Received);
    setConnectionId(null);
    setInvitationId(invitationId);
  }

  function handleUserIsSelf() {
    setStatus(NetworkStatus.Self);
    setConnectionId(null);
    setInvitationId(null);
  }

  async function handleNetworkButtonClicked(itemClicked: string) {
    if (status === NetworkStatus.Self) return;
    if (status === NetworkStatus.None) await sendInvitation();
    if (status === NetworkStatus.InNetwork) await removeNetworkConnection();
    if (status === NetworkStatus.Sent) await cancelInvitation();
    if (status === NetworkStatus.Received) {
      if (itemClicked === "Accept Invitation") await acceptInvitation();
      if (itemClicked === "Reject Invitation") await rejectInvitation();
    }
  }

  async function sendInvitation() {
    try {
      setSaving(true);
      const invitationData: NetworkInvitationAPIRequest = new NetworkInvitationAPIRequest();
      invitationData.recipientId = userId?.toString();
      invitationData.message = message;
      const createdInvitation = await new NetworkInvitationAPIService(session)
        .createNetworkInvitation(invitationData);

      handleInviteSent(createdInvitation);
      enqueueSnackbar("Sent network invitation", { variant: "success" });
      menuButtonRef.current?.closeMenu();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't send network request. Please try again.", {
        variant: "error",
      });
    } finally {
      setSaving(false);
    }
  }

  async function removeNetworkConnection() {
    try {
      const response = await confirm({
        title: "Remove from network?",
        message: "Are you sure you want to remove this user from your network?",
        okButtonText: "Remove",
        cancelButtonText: "Cancel",
      });

      if (response == ConfirmResponse.Cancel) return;

      setSaving(true);

      if (!connectionId) throw new Error("Network connection id not found");

      await new UserNetworkConnectionAPIService(session)
        .removeNetworkConnection(connectionId);
      handleNotNetworked();
      enqueueSnackbar("Removed from network", { variant: "info" });
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't remove from network. Please try again.", {
        variant: "error",
      });
    } finally {
      setSaving(false);
    }
  }

  async function cancelInvitation() {
    try {
      setSaving(true);
      if (!invitationId) return;
      await new NetworkInvitationAPIService(session).cancelNetworkInvitation(invitationId);
      handleNotNetworked();
      enqueueSnackbar("Canceled network invitation", { variant: "info" });
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't cancel network request. Please try again.", {
        variant: "error",
      });
    } finally {
      setSaving(false);
    }
  }

  async function acceptInvitation() {
    try {
      setSaving(true);
      if (!invitationId) return;
      const connectionInfo = await new NetworkInvitationAPIService(session)
        .acceptNetworkInvitation(invitationId);
      handleInNetwork(connectionInfo);
      enqueueSnackbar("Accepted network invitation", { variant: "info" });
      session.removeNetworkInvitation(userId);
      onNetworkInvitationResolved?.();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(error?.message?.length > 0 ? error.message :
        "Couldn't accept network request. Please try again.", {
        variant: "error",
      });
    } finally {
      setSaving(false);
    }
  }

  async function rejectInvitation() {
    try {
      setSaving(true);
      if (!invitationId) return;
      await new NetworkInvitationAPIService(session)
        .rejectNetworkInvitation(invitationId);
      handleNotNetworked();
      enqueueSnackbar("Rejected network invitation", { variant: "info" });
      session.removeNetworkInvitation(userId);
      onNetworkInvitationResolved?.();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't reject network request. Please try again.", {
        variant: "error",
      });
    } finally {
      setSaving(false);
    }
  }

  function getStartIcon() {
    switch (status) {
      case NetworkStatus.InNetwork:
        return <CloseIcon />;
      case NetworkStatus.Sent:
        return <NotInterestedIcon />;
      default:
        return <StarBorderIcon />;
    }
  }

  function getMenuOptions(): string[] {
    switch (status) {
      case NetworkStatus.None:
        return ["Add To Network"];
      case NetworkStatus.InNetwork:
        return ["Remove From Network"];
      case NetworkStatus.Sent:
        return ["Cancel Invitation"];
      case NetworkStatus.Received:
        return ["Accept Invitation", "Reject Invitation"];
      case NetworkStatus.Self:
        return ["You"];
      default:
        return ["Add To Network"];
    }
  }

  function getPopoverContent(): React.ReactNode | undefined {
    if (status !== NetworkStatus.None) return undefined;

    return (
      <InvitationContainer>
        <Grid container direction="column">
          <Grid>
            <MessageTextField
              multiline
              variant="outlined"
              minRows={3}
              maxRows={6}
              fullWidth
              label="Message (optional)"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
          </Grid>
          <Grid>
            <Button onClick={() => menuButtonRef.current?.closeMenu()}>Cancel</Button>
            <Button color="primary" onClick={sendInvitation}>
              Send Invitation
            </Button>
          </Grid>
        </Grid>
      </InvitationContainer>
    )
  }

  return (
    <React.Fragment>
      <MenuButton
        ref={menuButtonRef}
        className={className}
        variant={buttonVariant}
        color="primary"
        disabled={
          saving ||
          status === NetworkStatus.Self
        }
        saving={saving}
        startIcon={getStartIcon()}
        menuOptions={getMenuOptions()}
        popperContent={getPopoverContent()}
        onClick={handleNetworkButtonClicked}
      />
    </React.Fragment>
  );
}
