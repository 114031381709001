import Guid from "common/values/guid/guid";
import EntityClientRepresentative from "work/entities/entity-client-representative/entity-client-representative";
import Proposal, { CompleteProposalSpec } from "work/entities/proposal/proposal";
import WorkAgreement from "work/entities/work-agreement/work-agreement";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import ProjectDescription from "work/values/project-description/project-description";
import ProjectName from "work/values/project-name/project-name";
import ProposalReviewer from "work/values/proposal-reviewer";
import Team from "work/values/team/team";
import ProposalFactory from "./proposal-factory";
import _ from "lodash";
import Session from "users/session/session";
import WorkDocument from "work/values/work-document/work-document";
import Percent from "common/values/percent/percent";
import Date from "common/values/date/date";
import AHBoolean from "common/values/boolean/boolean";
import { ProposalFieldName } from "work/values/constants";

export class ProposalSpec {
  client?: EntityClientRepresentative;
  name?: ProjectName;
  description?: ProjectDescription;
  workAgreement?: WorkAgreement;
  negotiable?: boolean;
  responseDueBy?: Date;
  startDate?: Date;
  endDate?: Date;
  supersededBy?: Proposal;
  supersedesId?: Guid;
  clientReviewers?: ProposalReviewer[];
  vendorReviewers?: ProposalReviewer[];

  clone(): ProposalSpec {
    const clone = new ProposalSpec();
    clone.client = this.client?.clone();
    clone.name = this.name?.clone();
    clone.description = this.description?.clone();
    clone.workAgreement = this.workAgreement?.clone();
    clone.negotiable = this.negotiable;
    clone.responseDueBy = this.responseDueBy?.clone();
    clone.startDate = this.startDate?.clone();
    clone.endDate = this.endDate?.clone();
    clone.supersededBy = this.supersededBy;
    clone.supersedesId = this.supersedesId?.clone();
    clone.clientReviewers = this.clientReviewers?.concat();
    clone.vendorReviewers = this.vendorReviewers?.concat();
    return clone;
  }
}

export class ProposalSpecChange {
  field: ProposalFieldName | null;
  fieldId: Guid | null;
  action: ProposalChangeAction;
  value: string;

  constructor(
    field: ProposalFieldName, 
    fieldId: Guid | null, 
    action: ProposalChangeAction,
    value: string
  ) {
    this.field = field ?? null;
    this.fieldId = fieldId ?? null;
    this.action = action ?? ProposalChangeAction.Edit;
    this.value = value ?? "";
  }

  static fromObject(object: any): ProposalSpecChange {
    const field = object.field;
    const fieldId = object.fieldId ? Guid.fromObject(object.fieldId) ?? null : null;
    const action = ProposalChangeAction.fromObject(object.action);
    const value = object.value;
    return new ProposalSpecChange(field, fieldId, action, value);
  }

  public toJSON(): object {
    return {
      field: this.field,
      fieldId: this.fieldId?.toJSON(),
      action: this.action.toString(),
      value: this.value
    }
  }
}

export class ProposalChangeAction {
  // The present tense of the action MUST match the readonly property name
  static readonly Edit: ProposalChangeAction = new ProposalChangeAction('Edit', 'Edited');
  static readonly ReEdit: ProposalChangeAction = new ProposalChangeAction('ReEdit', 'Re-edited');
  static readonly Add: ProposalChangeAction = new ProposalChangeAction('Add', 'Added');
  static readonly Remove: ProposalChangeAction = new ProposalChangeAction('Remove', 'Removed');

  private constructor(private readonly presentTense: string, public readonly actionDescription: string) { }

  toString() {
    return this.presentTense;
  }

  public static fromObject(obj: keyof typeof ProposalChangeAction): ProposalChangeAction {
    const newAction = ProposalChangeAction[obj] as ProposalChangeAction;
    return newAction;
  }
}

export default class ProposalBuilder {
  private _specStack: ProposalSpec[] = [new ProposalSpec()];
  private _currentIndex: number = 0;
  private _sessionHistory: ProposalSpecChange[] = [];

  constructor(startingSpec?: CompleteProposalSpec) {
    if (startingSpec) {
      const spec = new ProposalSpec();
      spec.client = startingSpec.client;
      spec.name = startingSpec.name;
      spec.description = startingSpec.description;
      spec.workAgreement = startingSpec.workAgreement;
      spec.negotiable = startingSpec.negotiable;
      spec.responseDueBy = startingSpec.responseDueBy;
      spec.startDate = startingSpec.workAgreement?.startDate;
      spec.endDate = startingSpec.workAgreement?.endDate;
      spec.clientReviewers = startingSpec.clientReviewers;
      spec.vendorReviewers = startingSpec.vendorReviewers;
      this._specStack = [spec];
      this._currentIndex = 0;
    }
  }

  public get currentSpec(): ProposalSpec {
    if (this._currentIndex >= this._specStack.length || this._currentIndex < 0) throw new Error("Invalid index")
    return this._specStack[this._currentIndex];
  }

  public get sessionHistory(): ProposalSpecChange[] {
    return this._sessionHistory;
  }

  public get canUndo(): boolean {
    return this._currentIndex > 0;
  }

  public get canRedo(): boolean {
    return this._currentIndex < (this._specStack.length - 1);
  }

  public get canBuild(): boolean {
    return !!this.currentSpec.client &&
      !!this.currentSpec.name?.value.length &&
      !!this.currentSpec.description?.value.length
  }

  public get canSubmit(): boolean {
    return this.canBuild && !!this.currentSpec.workAgreement?.team;
  }

  private resetStack() {
    if (this._currentIndex < (this._specStack.length - 1)) {
      this._specStack.push(this._specStack[this._currentIndex]);
    }
    this._currentIndex = this._specStack.length - 1;
  }

  public clearSessionHistory() {
    this._sessionHistory = [];
  }

  public undo() {
    if (!this.canUndo) return;
    this._currentIndex--;
  }

  public redo() {
    if (!this.canRedo) return;
    this._currentIndex++;
  }

  public loadSpec(spec: ProposalSpec) {
    this._specStack = [spec];
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setClient(client?: EntityClientRepresentative) {
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.client = client;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setName(name: ProjectName) {
    if (this.currentSpec.name?.value === name.value) {
      return this;
    }

    let action = ProposalChangeAction.Edit;
    const existingAction = this._sessionHistory.find(change => change.field === ProposalFieldName.Name);
    if (existingAction) {
      action = ProposalChangeAction.ReEdit;
    }
    const specChange = new ProposalSpecChange(
      ProposalFieldName.Name,
      null,
      action,
      this.currentSpec.name?.value ?? ""
    );
    this._sessionHistory.push(specChange);
    
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.name = name;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;

    return this;
  }

  public setDescription(description: ProjectDescription) {
    if (this.currentSpec.description?.value === description.value) {
      return this;
    }

    let action = ProposalChangeAction.Edit;
    const existingAction = this._sessionHistory.find(change => change.field === ProposalFieldName.Description);
    if (existingAction) {
      action = ProposalChangeAction.ReEdit;
    }
    const specChange = new ProposalSpecChange(
      ProposalFieldName.Description,
      null,
      action,
      this.currentSpec.description?.value ?? ""
    );
    this._sessionHistory.push(specChange);

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.description = description;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setNegotiable(negotiable: boolean) {
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.negotiable = negotiable;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setResponseDueBy(responseDueBy: Date | undefined) {
    if (this.currentSpec.responseDueBy?.value.isSame(responseDueBy?.value)) {
      return this;
    }

    let action = ProposalChangeAction.Edit;
    const existingAction = this._sessionHistory.find(change => change.field === ProposalFieldName.ResponseDueBy);
    if (existingAction) {
      action = ProposalChangeAction.ReEdit;
    }
    const specChange = new ProposalSpecChange(
      ProposalFieldName.ResponseDueBy,
      null,
      action,
      this.currentSpec.responseDueBy?.value.format("MM/DD/YY") ?? ""
    );
    this._sessionHistory.push(specChange);

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    newSpec.responseDueBy = responseDueBy;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setStartDate(startDate: Date | undefined) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }
    if (this.currentSpec.startDate?.value.isSame(startDate?.value)) {
      return this;
    }

    let action = ProposalChangeAction.Edit;
    const existingAction = this._sessionHistory.find(change => change.field === ProposalFieldName.StartDate);
    if (existingAction) {
      action = ProposalChangeAction.ReEdit;
    }
    const specChange = new ProposalSpecChange(
      ProposalFieldName.StartDate,
      null,
      action,
      this.currentSpec.startDate?.value.format("MM/DD/YY") ?? ""
    );
    this._sessionHistory.push(specChange);
    
    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.startDate = startDate;
    newSpec.startDate = startDate;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setEndDate(endDate: Date | undefined) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }
    if (this.currentSpec.startDate?.value.isSame(endDate?.value)) {
      return this;
    }

    let action = ProposalChangeAction.Edit;
    const existingAction = this._sessionHistory.find(change => change.field === ProposalFieldName.EndDate);
    if (existingAction) {
      action = ProposalChangeAction.ReEdit;
    }
    const specChange = new ProposalSpecChange(
      ProposalFieldName.EndDate,
      null,
      action,
      this.currentSpec.endDate?.value.format("MM/DD/YY") ?? ""
    );
    this._sessionHistory.push(specChange);

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.endDate = endDate;
    newSpec.endDate = endDate;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setTeam(team: Team | undefined) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    const addedLeader = team?.leader?.userId.value && !this.currentSpec.workAgreement?.team?.leader?.userId.value;
    const removedLeader = !team?.leader?.userId.value && this.currentSpec.workAgreement?.team?.leader?.userId.value;

    if (addedLeader) {
      const specChange = new ProposalSpecChange(
        ProposalFieldName.Team,
        team?.leader?.userId,
        ProposalChangeAction.Add,
        team?.leader?.userId.value.toString()
      );
      this._sessionHistory.push(specChange);
    }
    if (removedLeader) {
      const specChange = new ProposalSpecChange(
        ProposalFieldName.Team,
        this.currentSpec.workAgreement?.team?.leader?.userId ?? null,
        ProposalChangeAction.Remove,
        this.currentSpec.workAgreement?.team?.leader.userId.value.toString() ?? ""
      );
      this._sessionHistory.push(specChange);
    }
    for (const memberUserId of team?.memberUserIds ?? []) {
      const existingMember = this.currentSpec.workAgreement?.team?.memberUserIds.find(id => id.isEqualTo(id));
      if (!existingMember) {
        const specChange = new ProposalSpecChange(
          ProposalFieldName.Team,
          memberUserId,
          ProposalChangeAction.Add,
          memberUserId.value.toString()
        );
        this._sessionHistory.push(specChange);
      }
    }
    for (const memberUserId of this.currentSpec.workAgreement?.team?.memberUserIds ?? []) {
      const removedMember = !team?.memberUserIds.find(id => id.isEqualTo(memberUserId));
      if (removedMember) {
        const specChange = new ProposalSpecChange(
          ProposalFieldName.Team,
          memberUserId,
          ProposalChangeAction.Remove,
          memberUserId.value.toString()
        );
        this._sessionHistory.push(specChange);
      }
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.team = team;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setFeeSchedule(categories: FeeScheduleCategory[] | undefined) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    for (const category of categories ?? []) {
      const existingCategory = this.currentSpec.workAgreement?.feeSchedule?.find(cat => cat.id.isEqualTo(category.id));
      if (!existingCategory) {
        const specChange = new ProposalSpecChange(
          ProposalFieldName.FeeSchedule,
          category.id,
          ProposalChangeAction.Add,
          category.name.value
        );
        this._sessionHistory.push(specChange);
      }
    }
    for (const category of this.currentSpec.workAgreement?.feeSchedule ?? []) {
      const removedCategory = !categories?.find(cat => cat.id.isEqualTo(category.id));
      if (removedCategory) {
        const specChange = new ProposalSpecChange(
          ProposalFieldName.FeeSchedule,
          category.id,
          ProposalChangeAction.Remove,
          category.name.value
        );
        this._sessionHistory.push(specChange);
      }
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.feeSchedule = categories;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setDiscount(percentage: Percent) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    if (this.currentSpec.workAgreement?.discount?.value === percentage.value) {
      return this;
    }

    let action = ProposalChangeAction.Edit;
    const existingAction = this._sessionHistory.find(change => change.field === ProposalFieldName.Discount);
    if (existingAction) {
      action = ProposalChangeAction.ReEdit;
    }
    const specChange = new ProposalSpecChange(
      ProposalFieldName.Discount,
      null,
      action,
      `${Number((this.currentSpec.workAgreement?.discount?.value ?? 0) * 100).toFixed(2)}%`
    );
    this._sessionHistory.push(specChange);

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.discount = percentage ?? 0;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setConflictsDocuments(documents: WorkDocument[]) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    for (const document of documents) {
      const existingDocument = this.currentSpec.workAgreement?.conflictsDocuments.find(doc => doc.id.isEqualTo(document.id));
      if (!existingDocument) {
        const specChange = new ProposalSpecChange(
          ProposalFieldName.Conflicts,
          document.id,
          ProposalChangeAction.Add,
          document.name?.value ?? ""
        );
        this._sessionHistory.push(specChange);
      }
    }
    for (const document of this.currentSpec.workAgreement?.conflictsDocuments ?? []) {
      const removedDocument = !documents.find(doc => doc.id.isEqualTo(document.id));
      if (removedDocument) {
        const specChange = new ProposalSpecChange(
          ProposalFieldName.Conflicts,
          document.id,
          ProposalChangeAction.Remove,
          document.name?.value ?? ""
        );
        this._sessionHistory.push(specChange);
      }
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.conflictsDocuments = _.uniqBy(documents, (document) => document.id.value);
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setClientPolicyDocuments(documents: WorkDocument[]) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    for (const document of documents) {
      const existingDocument = this.currentSpec.workAgreement?.clientPolicyDocuments.find(doc => doc.id.isEqualTo(document.id));
      if (!existingDocument) {
        const specChange = new ProposalSpecChange(
          ProposalFieldName.ClientPolicies,
          document.id,
          ProposalChangeAction.Add,
          document.name?.value ?? ""
        );
        this._sessionHistory.push(specChange);
      }
    }
    for (const document of this.currentSpec.workAgreement?.clientPolicyDocuments ?? []) {
      const removedDocument = !documents.find(doc => doc.id.isEqualTo(document.id));
      if (removedDocument) {
        const specChange = new ProposalSpecChange(
          ProposalFieldName.ClientPolicies,
          document.id,
          ProposalChangeAction.Remove,
          document.name?.value ?? ""
        );
        this._sessionHistory.push(specChange);
      }
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.clientPolicyDocuments = _.uniqBy(documents, (document) => document.id.value);
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setVendorPolicyDocuments(documents: WorkDocument[]) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    for (const document of documents) {
      const existingDocument = this.currentSpec.workAgreement?.vendorPolicyDocuments.find(doc => doc.id.isEqualTo(document.id));
      if (!existingDocument) {
        const specChange = new ProposalSpecChange(
          ProposalFieldName.VendorPolicies,
          document.id,
          ProposalChangeAction.Add,
          document.name?.value ?? ""
        );
        this._sessionHistory.push(specChange);
      }
    }
    for (const document of this.currentSpec.workAgreement?.vendorPolicyDocuments ?? []) {
      const removedDocument = !documents.find(doc => doc.id.isEqualTo(document.id));
      if (removedDocument) {
        const specChange = new ProposalSpecChange(
          ProposalFieldName.VendorPolicies,
          document.id,
          ProposalChangeAction.Remove,
          document.name?.value ?? ""
        );
        this._sessionHistory.push(specChange);
      }
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.vendorPolicyDocuments = _.uniqBy(documents, (document) => document.id.value);
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setClientTeamTemplateIds(templateIds: Guid[]) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.clientTeamTemplateIds = _.uniqBy(templateIds, (id) => id.value);
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }
  public setVendorTeamTemplateIds(templateIds: Guid[]) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.vendorTeamTemplateIds = _.uniqBy(templateIds, (id) => id.value);
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setClientFeeScheduleTemplateIds(templateIds: Guid[]) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.clientFeeScheduleTemplateIds = _.uniqBy(templateIds, (id) => id.value);
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }
  public setVendorFeeScheduleTemplateIds(templateIds: Guid[]) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.vendorFeeScheduleTemplateIds = _.uniqBy(templateIds, (id) => id.value);
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setConflictsCheckWaived(waived: AHBoolean) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }
    if (this.currentSpec?.workAgreement?.conflictsCheckWaived?.value === waived?.value) {
      return this;
    }

    let action = ProposalChangeAction.Edit;
    const existingAction = this._sessionHistory.find(change => change.field === ProposalFieldName.WaiveConflictsCheck);
    if (existingAction) {
      action = ProposalChangeAction.ReEdit;
    }
    const specChange = new ProposalSpecChange(
      ProposalFieldName.WaiveConflictsCheck,
      null,
      action,
      this.currentSpec.workAgreement?.conflictsCheckWaived?.value ? "Conflicts Check Waived" : "Conflicts Check Not Waived"
    );
    this._sessionHistory.push(specChange);

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.conflictsCheckWaived = waived;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setTeamRestricted(teamRestricted: AHBoolean) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }
    if (this.currentSpec?.workAgreement?.teamRestricted?.value === teamRestricted?.value) {
      return this;
    }

    let action = ProposalChangeAction.Edit;
    const existingAction = this._sessionHistory.find(change => change.field === ProposalFieldName.TeamRestriction);
    if (existingAction) {
      action = ProposalChangeAction.ReEdit;
    }
    const specChange = new ProposalSpecChange(
      ProposalFieldName.Team,
      null,
      action,
      this.currentSpec.workAgreement?.teamRestricted?.value ? "Team Restricted" : "Team Not Restricted"
    );
    this._sessionHistory.push(specChange);

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.workAgreement.teamRestricted = new AHBoolean(teamRestricted.value);
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setClientReviewers(reviewers: ProposalReviewer[]) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.clientReviewers = reviewers;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public setVendorReviewers(reviewers: ProposalReviewer[]) {
    if (!this.currentSpec.name || !this.currentSpec.client) {
      throw new Error("Name and client are required to create a work agreement");
    }

    this.resetStack();
    const newSpec = this.currentSpec.clone();
    if (!newSpec.workAgreement) {
      newSpec.workAgreement = new WorkAgreement(
        this.currentSpec.name,
        this.currentSpec.client
      );
    }
    newSpec.vendorReviewers = reviewers;
    this._specStack.push(newSpec);
    this._currentIndex = this._specStack.length - 1;
    return this;
  }

  public buildDraft(session: Readonly<Session>): Proposal {
    return ProposalFactory.createProposal(this.completedSpec, session);
  }

  public updateProposal(oldProposal: Proposal, session: Readonly<Session>): Proposal {
    return ProposalFactory.updateProposal(oldProposal, this.completedSpec, session);
  }

  public clone(): ProposalBuilder {
    const clone = new ProposalBuilder();
    clone._specStack = this._specStack.map(spec => spec.clone());
    clone._currentIndex = this._currentIndex;
    return clone;
  }

  private get completedSpec(): CompleteProposalSpec {
    if (!this.currentSpec.client) throw new Error("Client is required to build a proposal");
    if (!this.currentSpec.name) throw new Error("Name is required to build a proposal");
    if (!this.currentSpec.description) throw new Error("Description is required to build a proposal");

    const completedSpec: CompleteProposalSpec = {
      name: this.currentSpec.name,
      client: this.currentSpec.client,
      description: this.currentSpec.description,
      workAgreement: this.currentSpec.workAgreement,
      negotiable: this.currentSpec.negotiable ?? true,
      responseDueBy: this.currentSpec.responseDueBy,
      startDate: this.currentSpec.startDate,
      endDate: this.currentSpec.endDate,
      clientReviewers: this.currentSpec.clientReviewers ?? [],
      vendorReviewers: this.currentSpec.vendorReviewers ?? []
    }

    return completedSpec;
  }
}
