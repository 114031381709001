import CommentIcon from "@mui/icons-material/Comment";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  Badge,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as Constants from "common/helpers/constants";
import Percent from "common/values/percent/percent";
import Forum from "messaging/entities/forum/forum";
import React, { useEffect } from "react";
import {
  NumberFormatValues,
  NumericFormat,
  SourceInfo,
} from "react-number-format";
import { useSession } from "users/session/session-context";
import Proposal from "work/entities/proposal/proposal";
import { ProposalTab } from "work/entities/proposal/redlining/view/proposal-redline-dialog";
import { getForumForField } from "work/entities/proposal/utils/comment-utils";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";
import { ProposalFieldName } from "work/values/constants";

const ComponentContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(2, 0, 4, 0),
}));
const ControlContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));
const HelperText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.text.secondary,
}));
const CheckboxLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
}));

const discountDescription =
  "You can share the AttorneyHub fees you incur from a project by establishing " +
  'an agreed upon "discount" from your vendor. 2.912% is the value to cover the cost.';

type DiscountTabProps = {
  activeTab: ProposalTab;
  proposalBuilder: ProposalBuilder;
  proposal?: Proposal;
  disableCommenting?: boolean;
  disableEditing?: boolean;
  commentForums: Forum[];
  onCommentsClicked: () => void;
  onChange: () => void;
};

export default function DiscountTab(props: Readonly<DiscountTabProps>) {
  const {
    activeTab,
    proposalBuilder,
    proposal,
    disableCommenting,
    disableEditing,
    commentForums,
    onCommentsClicked,
    onChange,
  } = props;

  const [isEnabled, setIsEnabled] = React.useState(false);
  const [discount, setDiscount] = React.useState(0);
  const [isEditing, setIsEditing] = React.useState(false);

  const session = useSession();

  useEffect(() => {
    if (activeTab !== ProposalTab.Discount) return;
    initializeDiscount();
  }, [proposal, proposalBuilder, activeTab]);

  function initializeDiscount() {
    const discount =
      proposalBuilder.currentSpec.workAgreement?.discount.numeral ??
      proposal?.discount.numeral ??
      0;
    setDiscount(discount);
    setIsEnabled(discount > 0);
  }

  /**
   * Handles when the user toggles the checkbox
   * @param {React.ChangeEvent<HTMLInputElement>} event The event that triggered the change
   */
  async function handleToggle(event: React.ChangeEvent<HTMLInputElement>) {
    let defaultValue: number;

    if (event.target.checked) {
      defaultValue = Number(Constants.defaultDiscount);
      setIsEnabled(true);
    } else {
      defaultValue = 0;
      setIsEnabled(false);
    }

    setDiscount(defaultValue);

    proposalBuilder.setDiscount(new Percent(defaultValue));

    onChange();
  }

  /**
   * Handles the change event for the discount textfield
   * @param {NumberFormatValues} values The new values
   * @param {SourceInfo} sourceInfo The source of the change
   */
  async function handleDiscountChange(
    values: NumberFormatValues,
    sourceInfo: SourceInfo
  ) {
    if (sourceInfo.source === "prop" || !values.floatValue) return;

    let updatedDiscount = values.floatValue / 100;
    // Clamp to between 0 and 100%
    if (updatedDiscount < 0) updatedDiscount = 0;
    if (updatedDiscount > 1) updatedDiscount = 1;

    setDiscount(updatedDiscount);
  }

  function renderCommentButton() {
    if (disableCommenting) return null;
    return (
      <Tooltip
        title={
          disableCommenting
            ? "Save proposal to enable commenting"
            : "Comments"
        }
      >
        <span>
          <IconButton
            disabled={disableCommenting}
            onClick={(event) => {
              event.stopPropagation();
              onCommentsClicked();
            }}
          >
            <Badge
              variant="dot"
              color="secondary"
              overlap="circular"
              invisible={
                !getForumForField(ProposalFieldName.Discount, undefined, commentForums)
              }
            >
              <CommentIcon fontSize="medium" />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  function renderTextFieldButtons(
    isEditing: boolean,
    setter: (value: React.SetStateAction<boolean>) => void,
    callback: () => void
  ) {
    return (
      <>
        {(!disableEditing && !isEditing) && (
          <Tooltip title="Edit">
            <span>
              <IconButton onClick={() => setter(true)}>
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {isEditing && (
          <>
            <Tooltip title="Submit">
              <span>
                <IconButton onClick={callback}>
                  <CheckIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Cancel">
              <span>
                <IconButton onClick={() => setter(false)}>
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
      </>
    );
  }

  if (activeTab !== ProposalTab.Discount) return null;

  return (
    <ComponentContainer>
      <>
        <ControlContainer>
          <FormControlLabel
            label={
              !isEnabled ? (
                <CheckboxLabel>
                  Apply a discount? {renderCommentButton()}
                </CheckboxLabel>
              ) : null
            }
            control={
              <Checkbox
                color="primary"
                checked={isEnabled}
                disabled={disableEditing}
                onChange={handleToggle}
              />
            }
          />
          {isEnabled && (
            <NumericFormat
              name="discount"
              label="Discount"
              customInput={TextField}
              defaultValue={Constants.defaultDiscount * 100}
              allowNegative={false}
              value={Number(discount * 100)}
              suffix="%"
              type="text"
              thousandSeparator={false}
              decimalScale={2}
              disabled={disableEditing || !isEditing}
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  floatValue == null || (floatValue >= 0 && floatValue <= 100)
                );
              }}
              slotProps={{
                input: {
                  endAdornment: (
                    <>
                      {renderTextFieldButtons(
                        isEditing, 
                        setIsEditing, 
                        () => {
                          setIsEditing(false);
                          proposalBuilder.setDiscount(new Percent(discount));
                          onChange();
                        }
                      )}
                      {renderCommentButton()}
                    </>
                  )
                }
              }}
              onValueChange={handleDiscountChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setIsEditing(false);
                  proposalBuilder.setDiscount(new Percent(discount));
                  onChange();
                }
                else if (event.key === "Escape") setIsEditing(false);
              }}
            />
          )}
        </ControlContainer>
        {session.context?.viewingAsVendor && (
          <HelperText>
            A discount of <b>3%</b> will cover subscription costs.
            <Tooltip
              disableFocusListener
              title={discountDescription}
              placement="right"
              arrow
            >
              <IconButton size="small">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </HelperText>
        )}
      </>
    </ComponentContainer>
  );
}
