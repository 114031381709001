import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import UndoIcon from "@mui/icons-material/Undo";
import {
  alpha,
  FormControlLabel,
  IconButton,
  Switch,
  SwitchProps,
  Theme,
  Tooltip,
} from "@mui/material";
import { lightBlue, green } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import {
  ConfirmResponse,
  useConfirmDialog,
} from "app/providers/confirm-dialog";
import AHBoolean from "common/values/boolean/boolean";
import { Change, diffLines } from "diff";
import React, { forwardRef } from "react";
import { ProposalField } from "work/entities/proposal/proposal";
import FieldRedline from "work/entities/proposal/redlining/field-redline";

function getSwitchColor(
  theme: Theme,
  resolved: boolean,
  canbeundone: boolean,
  added: boolean,
  removed: boolean
) {
  if (resolved) {
    if (canbeundone) return lightBlue[700];
    else return theme.palette.primary.main;
  } else if (added) {
    return theme.palette.success.main;
  } else if (removed) {
    return theme.palette.error.main;
  } else {
    return theme.palette.action.disabled;
  }
}

const ActionButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
}));
const RedlineSwitch = styled(Switch, {
  shouldForwardProp: (prop) =>
    !["resolved", "canbeundone", "added", "removed"].includes(prop.toString()),
})<{
  resolved: boolean;
  canbeundone: boolean;
  added: boolean;
  removed: boolean;
}>(({ theme, resolved, canbeundone, added, removed }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    cursor: resolved ? "pointer" : "default",
    color: getSwitchColor(theme, resolved, canbeundone, added, removed),
    "&:hover": {
      backgroundColor: resolved
        ? alpha(
            getSwitchColor(theme, resolved, canbeundone, added, removed),
            theme.palette.action.hoverOpacity
          )
        : "initial",
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: getSwitchColor(
      theme,
      resolved,
      canbeundone,
      added,
      removed
    ),
  },
}));
const LabelContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));
const DiffContainer = styled("div")<{ resolved?: boolean }>(
  ({ theme, resolved }) => ({
    alignItems: "flex-start",
    cursor: resolved ? "pointer" : "default",
    display: "flex",
    flexDirection: "column",
    marginRight: theme.spacing(1),
  })
);
const DiffResult = styled("span")<{
  change?: Change;
  resolved?: boolean;
}>(({ change, resolved, theme }) => ({
  backgroundColor: (function () {
    if (resolved) return "unset";
    if (change?.added) return green[300];
  })(),
  color: (function () {
    if (change?.added && resolved) return lightBlue[700];
    return theme.palette.text.primary;
  })(),
  textDecoration: (function () {
    if (change?.removed) return "line-through";
  })(),
  textDecorationColor: (function () {
    if (change?.removed && resolved) return lightBlue[700];
    if (change?.removed) return theme.palette.error.main;
    return "initial";
  })(),
}));

export interface RedlineSwitchProps extends SwitchProps {
  disabled?: boolean;
  titleText: string;
  messageText: string;
  okButtonText: string;
  unsetLabel: string;
  setLabel: string;
  boolRedline: FieldRedline<AHBoolean>;
  readOnly?: boolean;
  disableUndo?: boolean;
  onWaiveFieldFocus?: () => void;
  onBoolRedlineChange?: (
    newRedline: FieldRedline<AHBoolean>,
    traversalFieldOverride?: ProposalField | null
  ) => void;
}

const SwitchRedline = forwardRef<HTMLInputElement, RedlineSwitchProps>(
  (
    props: Readonly<RedlineSwitchProps>,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const {
      disabled,
      titleText,
      messageText,
      okButtonText,
      unsetLabel,
      setLabel,
      boolRedline,
      readOnly,
      disableUndo,
      onBoolRedlineChange,
      onWaiveFieldFocus,
      ...otherProps
    } = props;

    const [currentValue, setCurrentValue] = React.useState<boolean>(
      boolRedline?.currentEntry?.value ??
        boolRedline?.revisedEntry?.value ??
        false
    );
    const confirm = useConfirmDialog();

    React.useEffect(() => {
      setCurrentValue(
        boolRedline?.currentEntry?.value ??
          boolRedline?.revisedEntry?.value ??
          false
      );
    }, [boolRedline?.currentEntry, boolRedline?.revisedEntry]);

    function handleAcceptChange(event: React.MouseEvent<HTMLButtonElement>) {
      event.stopPropagation();
      event.preventDefault();
      onBoolRedlineChange?.(boolRedline?.accept());
    }

    function handleRejectChange(event: React.MouseEvent<HTMLButtonElement>) {
      event.stopPropagation();
      event.preventDefault();
      onBoolRedlineChange?.(boolRedline?.reject());
    }

    function handleUndoChange(event: React.MouseEvent<HTMLButtonElement>) {
      event.stopPropagation();
      event.preventDefault();
      onBoolRedlineChange?.(boolRedline.undo(), {
        name: boolRedline.field
      });
    }

    function renderAcceptRejectButtons() {
      if (boolRedline.isResolved || readOnly) return null;
      return (
        <>
          <Tooltip title="Accept Change">
            <span>
              <ActionButton onClick={handleAcceptChange}>
                <CheckIcon color="success" />
              </ActionButton>
            </span>
          </Tooltip>
          <Tooltip title="Reject Change">
            <span>
              <ActionButton onClick={handleRejectChange}>
                <CloseIcon color="error" />
              </ActionButton>
            </span>
          </Tooltip>
        </>
      );
    }

    function renderUndoButton() {
      if (!boolRedline.canBeUndone || readOnly || disableUndo) return null;
      return (
        <Tooltip title="Undo Change">
          <span>
            <ActionButton onClick={handleUndoChange}>
              <UndoIcon />
            </ActionButton>
          </span>
        </Tooltip>
      );
    }

    function renderActionButtons() {
      if (disabled) return null;
      return (
        <span>
          {renderAcceptRejectButtons()}
          {renderUndoButton()}
        </span>
      );
    }

    function getDiffedLabel() {
      let diff: Change[] = [];

      if (!boolRedline.isResolved) {
        diff = diffLines(
          boolRedline.originalEntry?.value ? setLabel : unsetLabel,
          boolRedline.revisedEntry?.value ? setLabel : unsetLabel
        );
      } else {
        diff = diffLines(
          boolRedline.revisedEntry?.value ? setLabel : unsetLabel,
          boolRedline.currentEntry?.value ? setLabel : unsetLabel
        );
      }

      return (
        <DiffContainer resolved={boolRedline.isResolved}>
          {diff.map((change: Change) => {
            return (
              <DiffResult
                key={JSON.stringify(change)}
                change={change}
                resolved={boolRedline.isResolved}
              >
                {change.value}
              </DiffResult>
            );
          })}
        </DiffContainer>
      );
    }

    async function handleOnChange(
      _event: React.SyntheticEvent,
      checked: boolean
    ) {
      if (checked) {
        const response = await confirm({
          title: titleText,
          message: messageText,
          okButtonText: okButtonText,
        });

        if (response === ConfirmResponse.Cancel) return;

        onBoolRedlineChange?.(
          boolRedline.edit(new AHBoolean(true)),
          {
            name: boolRedline.field
          }
        );
      } else {
        onBoolRedlineChange?.(
          boolRedline.edit(new AHBoolean(false)),
          {
            name: boolRedline.field
          }
        );
      }
    }

    return (
      <FormControlLabel
        disabled={disabled}
        checked={currentValue}
        control={
          <RedlineSwitch
            {...otherProps}
            inputRef={ref}
            disableRipple={readOnly || !boolRedline.isResolved}
            resolved={boolRedline.isResolved}
            added={boolRedline.isAdded}
            removed={boolRedline.isRemoved}
            canbeundone={boolRedline.canBeUndone ?? false}
            onFocus={onWaiveFieldFocus}
          />
        }
        label={
          <LabelContainer>
            {getDiffedLabel()}
            {renderActionButtons()}
          </LabelContainer>
        }
        onChange={handleOnChange}
      />
    );
  }
);

export default SwitchRedline;
