import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import {
  Avatar,
  Button,
  IconButton, List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
  debounce, styled
} from "@mui/material";
import { useDialog } from 'app/providers/dialog';
import { CanceledError } from 'axios';
import Loader from "common/components/loader";
import LoadingButton from 'common/components/loading-button';
import Guid from 'common/values/guid/guid';
import IndividualAPIService from "marketplace/entities/individual/api/individual-api-service";
import Individual from "marketplace/entities/individual/individual";
import IndividualProfile from "marketplace/values/individual-profile/individual-profile";
import IndividualAvatar from 'marketplace/view/individual-avatar';
import MessagingAPIService from 'messaging/api/messaging-api-service';
import Forum from "messaging/entities/forum/forum";
import ChatDialog from 'messaging/entities/forum/view/components/chat-dialog';
import Topic from 'messaging/values/topic';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import UserNetworkConnectionAPIService from "users/entities/user-network-connection/api/user-network-connection-api-service";
import NetworkedUserInfo from "users/entities/user-network-connection/networked-user-info";
import { useSession } from "users/session/session-context";

const ForumContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    flexDirection: "column"
  },
  display: 'flex',
  marginBottom: theme.spacing(2),
  width: '100%'
}));
const AddSubscriberContainer = styled('div')(({ theme }) => ({
  minWidth: '22rem',
  padding: theme.spacing(2),
  textAlign: 'center',
}));
const ForumNameCreate = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: '100%',
    "&:first-of-type": {
      marginBottom: theme.spacing(1.5),
      marginRight: 0
    }
  },
  "&:first-of-type": {
    marginRight: theme.spacing(2)
  },
  minWidth: theme.spacing(35)
}));
const LoaderContainer = styled("div")(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: theme.spacing(4),
  paddingTop: 0,
  width: '100%'
}));
const SearchField = styled(TextField)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1)
})) as typeof TextField;
const ListContainer = styled(List)(({ theme }) => ({
  maxHeight: '20rem',
  overflowX: 'hidden',
  overflowY: 'auto'
}));
const SelectedIcon = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText
}));
const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1)
}));
const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(1),
  gridTemplateColumns: '1fr 1fr',
  width: '100%'
}));

type CreateForumDialogProps = {
  onForumCreated: (createdForum: Forum) => void;
};

export default function CreateForumDialog(props: Readonly<CreateForumDialogProps>) {

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [forumName, setForumName] = useState<string>("");
  const [forumCreator, setForumCreator] = useState<Individual | undefined>();
  const [forumSubscribers, setForumSubscribers] = useState<Individual[]>([]);
  const [networkConnections, setNetworkConnections] = useState<Individual[]>([]);

  const session = useSession();
  const { openDialog, closeAllDialogs } = useDialog();

  useEffect(() => {
    let abortController = new AbortController();
    getNetworkedIndividuals(abortController);
    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, []);

  async function getNetworkedIndividuals(abortController: AbortController) {
    try {
      setIsLoading(true);
      getCreator(abortController);
      const networkConnections: NetworkedUserInfo[] =
        await new UserNetworkConnectionAPIService(session)
          .getUserConnectionsInfo(abortController);
      session.updateNetworkConnections(networkConnections);
      const individualConnections: Individual[] = [];
      for (const connection of networkConnections) {
        const profile = new IndividualProfile(
          connection.userId,
          connection.name?.firstName ?? "",
          connection.name?.lastName ?? "");
        profile.avatarId = connection.avatarId;
        const individual = new Individual(connection.individualId!, connection.userId, profile);
        individualConnections.push(individual);
      }
      setNetworkConnections(individualConnections);
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
    }
    setIsLoading(false);
  }

  async function getCreator(abortController: AbortController) {
    if (!session?.user?.individualId)
      return;
    const service = new IndividualAPIService(session);
    setForumCreator(await service.getIndividualById(session.user.individualId, abortController));
  }

  function search(value: string) {
    setSearchTerm(value);
    if (value === '') {
      return;
    }
    debouncedSearch(value);
  }

  async function saveChanges(): Promise<void> {
    if (!forumCreator)
      return;

    setIsSaving(true);
    try {

      const subscriberIds: Guid[] = forumSubscribers.map(s => s.userId!);
      const topic = new Topic(forumName, forumCreator.userId!);
      const newForum = new Forum(forumName, topic, subscriberIds);
      const messagingService = new MessagingAPIService(session);
      const createdForum = await messagingService.createForum(newForum);
      props.onForumCreated(createdForum);
      openDialog({
        component: <ChatDialog loadedForum={createdForum} />,
        title: 'Message',
        contentSxProps: {
          display: 'flex',
          overflowX: 'hidden',
          paddingBottom: 0
        },
        MuiProps: {
          fullWidth: true,
          maxWidth: 'md'
        }
      });
    } catch (error: any) {
      if (error.response?.data) {
        enqueueSnackbar("Unable to create Forum: " + error.response.data, { variant: 'warning' });
      }
      else {
        console.error(error);
        enqueueSnackbar("Unable to create Forum", { variant: 'error' });
      }
    } finally {
      setIsSaving(false);
    }
  }

  function handleSubscriberSelected(event: React.MouseEvent<HTMLDivElement, MouseEvent>, subscriber: Individual): void {
    const selectedSubscribers = [...forumSubscribers] as Individual[];
    const index = selectedSubscribers.findIndex(s => s.userId?.value === subscriber.userId?.value);
    if (index === -1) {
      selectedSubscribers.push(subscriber);
    } else {
      selectedSubscribers.splice(index, 1);
    }
    setForumSubscribers(selectedSubscribers);
  }

  function isSelected(subscriber: Individual): boolean {
    if (!subscriber.userId)
      return false;
    return forumSubscribers.some(s => s.userId?.value === subscriber.userId?.value);
  }

  const saveDisabled = forumSubscribers.length < 1 || isSaving || !forumName || !forumCreator
  const debouncedSearch = debounce((value) => { }, 100);
  const canCreateForum = networkConnections.length > 0;

  return (
    <React.Fragment>
      {isLoading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      {!isLoading && !canCreateForum && (
        <LoaderContainer>
          <Typography>You are not networked with anyone</Typography>
        </LoaderContainer>
      )}
      {!isLoading && canCreateForum && (
        <ForumContainer>
          <ForumNameCreate
            label="Enter the forum name"
            onChange={(e) => { setForumName(e.target.value) }}
            value={forumName}
          />
          <AddSubscriberContainer>
            {forumSubscribers.length > 8 &&
              <SearchField
                placeholder='Search Connections'
                slotProps={{
                  input: {
                    startAdornment: <SearchIcon />,
                    endAdornment: (
                      <IconButton size='small' onClick={async () => {
                        setSearchTerm('');
                        search('');
                      }}>
                        <CloseIcon />
                      </IconButton>
                    )
                  }
                }}
                value={searchTerm}
                onChange={(event) => search(event.target.value)}
              />}
            <ListContainer dense>
              {networkConnections.map((connection: Individual) => {
                return (
                  <StyledListItemButton
                    disableGutters
                    key={connection.userId?.toString()}
                    selected={isSelected(connection)}
                    onClick={(event) => handleSubscriberSelected(event, connection)}
                  >
                    <ListItemAvatar>
                      {isSelected(connection) ?
                        <SelectedIcon><DoneIcon /></SelectedIcon> :
                        renderSubscriberAvatar()
                      }
                    </ListItemAvatar>
                    <ListItemText
                      primary={connection.profile?.getFullName()}
                      secondary={connection.profile?.email?.toString() ?? ''}
                    />
                    <ListItemSecondaryAction>
                    </ListItemSecondaryAction>
                  </StyledListItemButton>
                )

                function renderSubscriberAvatar(): React.ReactNode {
                  return connection.profile?.avatarId ?
                    <IndividualAvatar
                      avatarId={connection.profile.avatarId}
                      individualId={connection.id}
                      session={session}
                    />
                    : <Avatar>{connection.profile?.firstName?.charAt(0)}</Avatar>;
                }
              })}
            </ListContainer>
            <ButtonContainer>
              <LoadingButton
                variant='contained'
                color='primary'
                startIcon={<SaveIcon />}
                loading={isSaving}
                disabled={saveDisabled}
                onClick={saveChanges}>
                Save
              </LoadingButton>
              <Button
                variant='contained'
                color='primary'
                onClick={closeAllDialogs}>
                Cancel
              </Button>
            </ButtonContainer>
          </AddSubscriberContainer>
        </ForumContainer>)}
    </React.Fragment>
  );
}

