import AddIcon from "@mui/icons-material/Add";
import CommentIcon from "@mui/icons-material/Comment";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ConfirmResponse,
  useConfirmDialog,
} from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import { AccountType } from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import EntityMember from "legal-entities/entities/entity-member/entity-member";
import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";
import ViewIndividualProfile from "marketplace/values/individual-profile/view/view-individual-profile";
import IndividualAvatar from "marketplace/view/individual-avatar";
import Forum from "messaging/entities/forum/forum";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { NavigateFunction } from "react-router";
import { useSession } from "users/session/session-context";
import { getForumForField } from "work/entities/proposal/utils/comment-utils";
import { ProposalFieldName } from "work/values/constants";
import { TeamMember } from "work/values/team/team-member";
import TeamMemberSelection from "work/values/team/view/team-member-selection";
import TeamSelection from "work/values/team/view/team-selection";
import TeamLeaderSelector from "work/view/components/team-leader-selector";

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));
const FromMarketplaceButton = styled(Button)(({ theme }) => ({
  width: "fit-content",
}));
const TeamContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const TeamList = styled(List)(({ theme }) => ({
  margin: 0,
  marginTop: 0,
  minWidth: theme.spacing(40),
  paddingBottom: theme.spacing(3),
  "&:first-of-type": {
    marginRight: theme.spacing(3),
  },
  [theme.breakpoints.down("lg")]: {
    "&:first-of-type": {
      marginRight: 0,
    },
  },
}));
const TeamListSubheader = styled(ListSubheader)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  display: "flex",
  fontSize: "1.4em",
  justifyContent: "space-between",
  padding: 0,
  "& > button": {
    height: "fit-content",
    width: "fit-content",
  },
}));
const NoRowsPlaceholder = styled(Typography)(({ theme }) => ({
  fontSize: "1.3em",
  paddingTop: theme.spacing(1),
}));
const TeamMemberLink = styled(Link)(({ theme }) => ({
  color: theme.palette.common.black,
  cursor: "pointer",
  textDecorationColor: theme.palette.common.black,
}));
const RemoveIcon = styled(DeleteIcon)(({ theme }) => ({
  color: theme.palette.error.main,
}));
const TeamLeaderInput = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    minWidth: "unset",
    width: "100%",
  },
  margin: theme.spacing(1, 0),
  minWidth: theme.spacing(25),
}));

type EditTeamProps = {
  className?: string;
  leader: TeamMember | null;
  members: TeamMember[];
  commentForums?: Forum[];
  disableEditing?: boolean;
  disableCommenting?: boolean;
  hideCommentButton?: boolean;
  allowEmptyLeader?: boolean;
  navigate?: NavigateFunction;
  onCommentsClicked?: (memberId?: Guid, name?: string) => void;
  onLeaderUpdated?: (leader: TeamMember | null) => void;
  onMemberAdded?: (member: TeamMember) => void;
  onMemberRemoved?: (userId?: Guid) => void;
  onMarketplaceTeamSelected?: (team: MarketplaceTeam) => void;
};

export default function EditTeam(props: Readonly<EditTeamProps>) {
  const {
    className,
    leader,
    members,
    commentForums,
    disableEditing,
    disableCommenting,
    hideCommentButton,
    allowEmptyLeader,
    navigate,
    onCommentsClicked,
    onLeaderUpdated,
    onMemberAdded,
    onMemberRemoved,
    onMarketplaceTeamSelected,
  } = props;

  const [selectedLeader, setSelectedLeader] = React.useState<TeamMember | null>(
    null
  );
  const [selectedMembers, setSelectedMembers] = React.useState<TeamMember[]>(
    []
  );
  const [teamLeaderSelectorAnchor, setTeamLeaderSelectorAnchor] =
    React.useState<HTMLDivElement>();

  const confirm = useConfirmDialog();
  const { openDialog, closeDialog } = useDialog();
  const session = useSession();

  useEffect(() => {
    setSelectedLeader(leader);
    setSelectedMembers(members);
  }, [leader, members]);

  /**
   * Checks if the user with the given id is already selected or invited to the team
   *
   * @param userId Id of the user to check
   * @returns True if the user is already selected or invited to the team, false otherwise
   */
  function getIsMemberAlreadyOnTeam(userId?: Guid): boolean {
    if (!userId) return false;

    const memberUserIds = selectedMembers?.map((member) => member.userId);

    return (
      (memberUserIds?.some((member) => member?.isEqualTo(userId)) ?? false) ||
      (selectedLeader?.userId?.isEqualTo(userId) ?? false)
    );
  }

  function getSelectedTeamMemberUserIds(): Guid[] {
    const userIds: Guid[] = selectedMembers?.map((member) => member.userId) ?? [];
    if (selectedLeader?.userId) {
      userIds.push(selectedLeader.userId);
    }
    return userIds;
  }

  /**
   * Handles what happens when add leader is clicked
   */
  function beginLeaderSelection() {
    openDialog({
      title: "Select Leader",
      component: (
        <TeamMemberSelection
          onNewSelection={handleNewLeaderSelected}
          selectLeader={true}
          selectMember={false}
          selectedTeamMemberUserIds={getSelectedTeamMemberUserIds()}
          closeDialog={closeDialog}
        />
      ),
      contentSxProps: {
        display: "flex",
        minHeight: "60vh",
      },
      MuiProps: {
        fullWidth: true,
        maxWidth: "lg",
      },
    });
  }

  /**
   * Handles when a new leader has been selected
   *
   * @param leader The new leader to replace the current leader with
   */
  async function handleNewLeaderSelected(leader: TeamMember) {
    if (selectedLeader) {
      const response = await confirm({
        title: "Replace Leader?",
        message: `Do you want to replace the current team leader with ${leader.firstName} ${leader.lastName}?`,
        okButtonText: "Replace",
      });

      if (response === ConfirmResponse.Cancel) return;
    }

    setSelectedLeader(leader);

    if (
      selectedMembers?.find((member) => member.userId?.isEqualTo(leader.userId))
    ) {
      setSelectedMembers(
        selectedMembers?.filter(
          (member) => !member.userId?.isEqualTo(leader.userId)
        )
      );
      onLeaderUpdated?.(leader);
      return;
    }

    onLeaderUpdated?.(leader);
  }

  function handleCoworkerSelectedAsLeader(leader?: EntityMember) {
    if (!leader) {
      setSelectedLeader(null);
      return;
    }
    if (!leader.name.firstName || !leader.name.lastName) {
      throw new Error("Leader name is undefined");
    }
    const newLeader = {
      individualId: leader.individualId,
      userId: leader.userId,
      entityId: leader.entityId,
      firstName: leader.name.firstName,
      lastName: leader.name.lastName,
      avatarId: leader.avatarId,
    };
    setSelectedLeader(newLeader);
    onLeaderUpdated?.(newLeader);
  }

  function handleSelectTeamLeaderClicked(
    event: React.MouseEvent<HTMLDivElement>
  ) {
    const inputElements = event.currentTarget.getElementsByTagName("input");
    setTeamLeaderSelectorAnchor(
      inputElements.length > 0 ? inputElements[0] : event.currentTarget
    );
  }

  /**
   * Handles what happens when add member is clicked
   */
  function beginMemberSelection() {
    openDialog({
      title: "Select Member",
      component: (
        <TeamMemberSelection
          navigate={navigate}
          onNewSelection={handleNewMemberSelected}
          selectLeader={false}
          selectMember={true}
          selectedTeamMemberUserIds={getSelectedTeamMemberUserIds()}
          closeDialog={closeDialog}
        />
      ),
      contentSxProps: {
        display: "flex",
        minHeight: "60vh",
      },
      MuiProps: {
        fullWidth: true,
        maxWidth: "lg",
      },
    });
  }

  /**
   * Handles when a new member has been selected
   *
   * @param teamMember The new member to add to the team
   */
  function handleNewMemberSelected(teamMember: TeamMember) {
    if (getIsMemberAlreadyOnTeam(teamMember.userId)) {
      closeDialog();
      enqueueSnackbar("Selected user is already on team", {
        variant: "info",
        preventDuplicate: true,
        autoHideDuration: 7500,
      });
      return;
    }

    onMemberAdded?.(teamMember);

    closeDialog();
  }

  /**
   * Handles what happens when a member is removed from the team
   *
   * @param userId The userId of the member to remove
   */
  async function handleLeaderRemoved() {
    const response = await confirm({
      title: "Remove Leader?",
      message: `Do you want to remove the leader from the team?`,
      okButtonText: "Remove",
    });

    if (response === ConfirmResponse.Cancel) return;

    setSelectedLeader(null);
    onLeaderUpdated?.(null);
  }

  /**
   * Handles what happens when a member is removed from the team
   *
   * @param userId The userId of the member to remove
   */
  async function handleMemberRemoved(userId?: Guid) {
    if (!userId) return;

    const response = await confirm({
      title: "Remove Member?",
      message: `Do you want to remove the member from the team?`,
      okButtonText: "Remove",
    });

    if (response === ConfirmResponse.Cancel) return;

    const newMembers = selectedMembers?.filter(
      (member) => !member.userId?.isEqualTo(userId)
    );
    setSelectedMembers(newMembers);
    onMemberRemoved?.(userId);
  }

  async function handleRemoveAllMembers() {
    const response = await confirm({
      title: "Remove All Members?",
      message: "Do you want to remove all members from the team?",
      okButtonText: "Remove All",
    });

    if (response === ConfirmResponse.Cancel) return;

    setSelectedMembers([]);
    onMemberRemoved?.();
  }

  /**
   * Opens the user profile dialog for the given individual
   *
   * @param individualId The id of the individual to open the dialog for
   */
  function openUserProfileDialog(individualId: Guid) {
    openDialog({
      component: (
        <ViewIndividualProfile
          individualId={individualId}
        />
      ),
      titleStyle: {
        position: "absolute",
        right: 0,
        top: 0,
      },
      contentSxProps: {
        display: "flex",
        overflowX: "hidden",
      },
      MuiProps: {
        maxWidth: "lg",
        fullWidth: true,
      },
    });
  }

  function beginMarketplaceTeamSelection() {
    openDialog({
      title: "Select Marketplace Team",
      component: (
        <TeamSelection
          onNewSelection={handleMarketplaceTeamSelected}
          closeDialog={closeDialog}
        />
      ),
      contentSxProps: {
        display: "flex",
        minHeight: "60vh",
      },
      MuiProps: {
        fullWidth: true,
        maxWidth: "lg",
      },
    });
  }

  function renderLeader(leader: TeamMember, allowDelete?: boolean) {
    return (
      <ListItem
        key={leader.userId.value ?? Guid.generate().value}
        disableGutters
      >
        {leader.avatarId && (
          <ListItemAvatar>
            <IndividualAvatar
              avatarId={leader.avatarId}
              individualId={leader.individualId}
              session={session}
            />
          </ListItemAvatar>
        )}
        <ListItemText
          primary={
            <Typography>
              <TeamMemberLink
                onClick={() => openUserProfileDialog(leader.individualId)}
              >
                {leader.firstName} {leader.lastName}
              </TeamMemberLink>
            </Typography>
          }
        ></ListItemText>
        <ListItemSecondaryAction>
          {allowDelete && (
            <Tooltip title="Remove Leader">
              <span>
                <IconButton onClick={() => handleLeaderRemoved()} size="medium">
                  <RemoveIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {renderCommentButton(leader)}
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
  /**
   * Renders a single team member's info as a list item
   *
   * @param member The member's info to render
   * @param allowDelete Whether or not to allow the member to be deleted
   */
  function renderMember(member: TeamMember, allowDelete?: boolean) {
    return (
      <ListItem
        key={member.userId.value ?? Guid.generate().value}
        disableGutters
      >
        <ListItemAvatar>
          <IndividualAvatar
            avatarId={member.avatarId}
            individualId={member.individualId}
            session={session}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography>
              <TeamMemberLink
                onClick={() => openUserProfileDialog(member.individualId)}
              >
                {member.firstName} {member.lastName}
              </TeamMemberLink>
            </Typography>
          }
        ></ListItemText>
        <ListItemSecondaryAction>
          {allowDelete && (
            <Tooltip title="Remove Member">
              <span>
                <IconButton
                  onClick={() => handleMemberRemoved(member.userId)}
                  size="medium"
                >
                  <RemoveIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {renderCommentButton(member)}
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  function handleMarketplaceTeamSelected(team: MarketplaceTeam) {
    if (!team.leader.profile)
      throw new Error("Team leader profile is undefined");

    onMarketplaceTeamSelected?.(team);
    closeDialog();
  }

  function renderCommentButton(member?: TeamMember) {
    if (hideCommentButton) return null;
    return (
      <Tooltip
        title={
          disableCommenting ? "Save proposal to enable commenting" : "Comments"
        }
      >
        <span>
          <IconButton
            disabled={disableCommenting}
            onClick={(event) => {
              event.stopPropagation();
              if (!member) {
                onCommentsClicked?.();
                return;
              }
              onCommentsClicked?.(
                member.userId,
                `${member.firstName} ${member.lastName}`
              );
            }}
          >
            <Badge
              variant="dot"
              color="secondary"
              overlap="circular"
              invisible={
                !getForumForField(
                  ProposalFieldName.Team,
                  member?.userId,
                  commentForums
                )
              }
            >
              <CommentIcon fontSize="medium" />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  return (
    <MainContainer className={className}>
      {!disableEditing && session.accountType === AccountType.Client && (
        <FromMarketplaceButton
          variant="outlined"
          color="primary"
          onClick={beginMarketplaceTeamSelection}
        >
          From Marketplace Team
        </FromMarketplaceButton>
      )}
      <TeamContainer>
        <TeamList>
          <TeamListSubheader>
            <span>Team Leader</span>
            <div>{renderCommentButton()}</div>
          </TeamListSubheader>
          {!selectedLeader && !session.context?.viewingAsVendor && (
            <>
              {!disableEditing && !session.context?.viewingAsVendor && (
                <ListItemButton
                  disableGutters
                  disabled={!onLeaderUpdated}
                  onClick={beginLeaderSelection}
                >
                  <ListItemAvatar>
                    <AddIcon color="primary" />
                  </ListItemAvatar>
                  <ListItemText>
                    <Typography color="primary">Select Leader</Typography>
                  </ListItemText>
                </ListItemButton>
              )}
              <ListItem disableGutters>
                {allowEmptyLeader && (
                  <NoRowsPlaceholder>None</NoRowsPlaceholder>
                )}
                {!allowEmptyLeader && (
                  <NoRowsPlaceholder color="error">
                    Required to submit proposal
                  </NoRowsPlaceholder>
                )}
              </ListItem>
            </>
          )}
          {!session.context?.viewingAsVendor &&
            selectedLeader &&
            renderLeader(selectedLeader, true)}
          {session.context?.viewingAsVendor && (
            <>
              <TeamLeaderInput
                disabled={
                  teamLeaderSelectorAnchor !== undefined || disableEditing
                }
                label={selectedLeader ? "Team Leader" : "Select Team Leader"}
                helperText="required"
                required
                error={!selectedLeader}
                onClick={handleSelectTeamLeaderClicked}
                value={
                  selectedLeader
                    ? `${selectedLeader?.firstName} ${selectedLeader?.lastName}`
                    : ""
                }
                slotProps={{
                  inputLabel: {
                    shrink: selectedLeader !== undefined,
                  },
                }}
              />
              <TeamLeaderSelector
                popoverAnchor={teamLeaderSelectorAnchor}
                teamLeaderUserId={selectedLeader?.userId}
                onTeamLeaderUpdated={handleCoworkerSelectedAsLeader}
                onPopoverClose={() => {
                  setTeamLeaderSelectorAnchor(undefined);
                }}
              />
            </>
          )}
        </TeamList>
        <TeamList>
          <TeamListSubheader>
            <span>Team Members</span>
            <div>
              {selectedMembers &&
                selectedMembers.length > 0 &&
                !disableEditing && (
                  <Tooltip title="Remove All Members">
                    <span>
                      <IconButton onClick={handleRemoveAllMembers}>
                        <RemoveIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              {renderCommentButton()}
            </div>
          </TeamListSubheader>
          {!disableEditing && (
            <ListItemButton disableGutters onClick={beginMemberSelection}>
              <ListItemAvatar>
                <AddIcon color="primary" />
              </ListItemAvatar>
              <ListItemText>
                <Typography color="primary">Add Member</Typography>
              </ListItemText>
            </ListItemButton>
          )}
          {(!selectedMembers || selectedMembers.length < 1) && (
            <ListItem disableGutters>
              <NoRowsPlaceholder>None</NoRowsPlaceholder>
            </ListItem>
          )}
          {selectedMembers?.map((member: TeamMember) =>
            renderMember(member, !disableEditing)
          )}
        </TeamList>
      </TeamContainer>
    </MainContainer>
  );
}
